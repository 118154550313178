import React, { useCallback, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useFileAPI } from "../../api/file_api";
import { useGenerativeModelAPI } from "../../api/generative_model_api";
import { useLicensablePropertyAPI } from "../../api/licensable_property_api";
import { useTrainingSetAPI } from "../../api/training_set_api";
import { useUserProductAPI } from "../../api/user_product_api";
import RadioCardGroup from "../../common/components/radio-card-group";
import Toggle from "../../common/components/toggle";
import { Button } from "../../common/components/ui/button";
import { useToast } from "../../common/components/ui/use_toast";
import { useAppSelector } from "../../hooks";
import { ModelEntity } from "../../model/licensable_property";
import { UserProduct } from "../../model/user_product";
import { TrainingSet } from "../../model/vault";
import { GenerativeModel } from "../image-generator/models/image_generator";
import TrainingProgressBar from "./components/training_progress_bar";
import { DialogModal } from "../../common/components/ui/dialog_modal";
import { TrashIcon } from "../../common/icons/icons";
import DeleteModal from "../../common/components/ui/delete-modal";
import { ThemeProvider } from "../../contexts/theme_context";

const VaultEdit: React.FC = () => {
  const params = useParams<{ id: string }>();
  const { id } = params;
  const { profile } = useAppSelector((state) => state.user) || {};
  const { register, handleSubmit, setValue, reset, formState, watch, control } = useForm<GenerativeModel>();
  const generativeModelAPI = useGenerativeModelAPI();
  const trainingSetAPI = useTrainingSetAPI();
  const lpAPI = useLicensablePropertyAPI();
  const productAPI = useUserProductAPI();
  const { toast } = useToast();
  const [myLicensableProperties, setMyLicensableProperties] = React.useState<ModelEntity[]>([]);
  const [myProducts, setMyProducts] = React.useState<UserProduct[]>([]);
  const [trainingSets, setTrainingSets] = React.useState<TrainingSet[]>([]);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const { uploadFile } = useFileAPI();
  // State for previewing the image URL and storing the selected file.
  const [previewImage, setPreviewImage] = React.useState<string | null>(null);
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const navigate = useNavigate();
  // A ref for the file input so we can trigger it on click.
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const entity_id = watch("entity_id");
  const entity_type = watch("entity_type");
  const status = watch("status");
  const [taskQueueId, setTaskQueueId] = useState<string | undefined>(undefined);
  const isCreate = window.location.pathname.includes("create");
  const hasSaved = id && status;
  const currentTrainingSet = trainingSets.find((ts) => ts.id === watch("training_set_id"));
  const hasFilesInTrainingSet = currentTrainingSet?.files && currentTrainingSet.files?.length > 0;
  const isCreator = profile.user_type === "creator";

  React.useEffect(() => {
    if (!entity_id) {
      setValue("entity_type", isCreator ? "USER_PRODUCT" : "LICENSABLE_PROPERTY");
    }
  }, [profile.user_type]);

  // Load an existing vault model (if editing) and set the preview image if present.
  React.useEffect(() => {
    if (id) {
      generativeModelAPI.getModelById(id).then((vault) => {
        reset(vault);
        if (vault.thumbnail_image) {
          setPreviewImage(vault.thumbnail_image);
        }
        setTaskQueueId(vault.task_queue_id);
      });
    }
  }, [id]);

  React.useEffect(() => {
    lpAPI.fetchMyLicensablePropertiesForSelect().then((data) => {
      setMyLicensableProperties(data);
    });
    productAPI.fetchMyProducts().then((data) => {
      setMyProducts(data);
    });
  }, []);

  React.useEffect(() => {
    // console.log("Entity type or id changed", entity_type, entity_id);
    if (entity_id) {
      trainingSetAPI.fetchTrainingSetForEntity(entity_type, entity_id).then((data) => {
        setTrainingSets(data);
      });
    }
  }, [entity_id]);

  const availableEntities = useMemo(() => {
    const allEntities = myLicensableProperties
      .map((lp) => {
        return {
          entity_id: lp.id,
          name: lp.name || "",
          entity_type: "LICENSABLE_PROPERTY",
          entity_type_description: "Talent",
        };
      })
      .concat(
        myProducts.map((product) => {
          return {
            entity_id: product.id,
            name: product.name || "",
            entity_type: "USER_PRODUCT",
            entity_type_description: "Product",
          };
        })
      );
    allEntities.sort((a, b) => a.name.localeCompare(b.name));
    return allEntities;
  }, [myLicensableProperties, myProducts]);
  const talentEntities = availableEntities.filter((entity) => entity.entity_type === "LICENSABLE_PROPERTY");
  const productEntities = availableEntities.filter((entity) => entity.entity_type === "USER_PRODUCT");

  React.useEffect(() => {
    if (entity_id) {
      const selectedEntity = availableEntities.find((entity) => entity.entity_id === entity_id);

      if (selectedEntity) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setValue("entity_type", selectedEntity.entity_type, {
          shouldValidate: true, // Trigger any validation rules
          shouldDirty: true, // Mark the field as dirty
        });
      }
    } else {
      // commented out, because it eats a click on the entity_type radio button
      // Reset entity_type when entity_id is cleared
      // // Reset entity_type when entity_id is cleared
      // setValue("entity_type", isCreator ? "USER_PRODUCT" : "LICENSABLE_PROPERTY", {
      //   shouldValidate: true,
      //   shouldDirty: true,
      // });
    }
  }, [entity_id, availableEntities, setValue]);

  const onSubmit = async (data: GenerativeModel) => {
    if (!data.name) {
      showToast("Please enter a name for your vault");
      return;
    }

    setSaving(true);
    // If a new file was selected, upload it using the file API.
    if (selectedFile) {
      try {
        const uploadRequest = {
          files: [selectedFile],
          is_public: true,
          object_id: data.entity_id,
          object_type: data.entity_type,
          generate_thumbnail: true,
          resize: "medium",
        };
        setUploading(true);
        const uploadedFiles = await uploadFile(uploadRequest, (progress) => {
          console.log("Upload progress:", progress);
        });

        // Assuming the API returns an array of UploadedFile objects,
        // use the thumbnail URL from the first uploaded file.
        if (uploadedFiles.length > 0) {
          data.thumbnail_image = uploadedFiles[0].thumbnail || uploadedFiles[0].permalink || "";
        }
        setUploading(false);
      } catch (error) {
        console.error("File upload failed", error);
      }
    }
    const entity = availableEntities.find((entity) => entity.entity_id === data.entity_id);
    if (entity) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      data.entity_type = entity.entity_type;
    }
    if (data.training_set_id === "-1") {
      data.training_set_id = undefined;
    }
    await generativeModelAPI.saveModel(data).then((updatedData) => {
      showToast("Vault saved successfully");
      reset(updatedData);
    });
    setSaving(false);
    if (isCreate) {
      navigate(`/vault/${watch("id")}`);
    }
  };

  // Handle changes from the file input.
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setPreviewImage(url);
      setSelectedFile(file);
      setValue("thumbnail_image", file.name);
    }
  };

  // Handle a file dropped into the drop zone.
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setPreviewImage(url);
      setSelectedFile(file);
    }
  };

  // Prevent default behavior on drag over.
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const showToast = (message: string) => {
    toast({
      title: message,
      variant: "default",
    });
  };
  const editTrainingSet = useCallback(() => {
    if (!id) {
      //You have to save first before you can edit a training set
      showToast("Please save the vault first before creating a training set");
    } else {
      if (watch("training_set_id")) {
        navigate("/training-set/" + watch("training_set_id"));
      } else {
        navigate("/training-set/add/" + id);
      }
    }
  }, [watch, id]);

  const handleVaultDelete = async () => {
    if (id) {
      try {
        await generativeModelAPI.deleteModel(id);
        setShowModal(false);
        navigate("/vaults");
      } catch (error) {
        console.error("Failed to delete vault:", error);
        showToast("Failed to delete vault. Please try again.");
      }
    }
  };

  return (
    <div className="page_content px-[60px] pt-[20px] pb-24 space-y-10">
      <DialogModal
        isOpen={showModal}
        onOpenChange={() => setShowModal(false)}
        variant="default"
        customClass
        removeFooter
        onClose={() => {
          setShowModal(false);
        }}
      >
        <ThemeProvider defaultTheme="light">
          <DeleteModal modalType={"vault"} closeModal={() => setShowModal(false)} handleDelete={handleVaultDelete} />
        </ThemeProvider>
      </DialogModal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-3">
          <div className="flex flex-row justify-between items-center">
            <h1 className="text-5xl text-white">{!isCreate ? "Edit vault" : "Create vault"}</h1>
            {(!isCreate || hasSaved) && (
              <Button
                onClick={() => setShowModal(true)}
                className="h-full fill-white gap-2 px-6 py-3 hover:fill-black"
                variant="outline"
                type="button"
              >
                <>
                  <TrashIcon />
                  Delete vault
                </>
              </Button>
            )}
          </div>

          <div className="flex flex-row items-center gap-2 text-white text-base font-serif italic">
            <span>Status:</span>
            {!status && <span>Not saved</span>}
            {status && <span>{status}</span>}
            {taskQueueId && <TrainingProgressBar taskQueueId={taskQueueId} status={status} />}
          </div>
          {!isCreator && (
            <Controller
              name={"is_public"}
              control={control}
              render={({ field }) => (
                <Toggle
                  darkMode
                  onToggle={(value) => field.onChange(value)}
                  checked={watch("is_public") ?? false}
                  labelLeft="Unlocked"
                  labelRight="Locked"
                  // label="Public vault access"
                />
              )}
            />
          )}
        </div>

        <div className="flex flex-row gap-2">
          <div className="flex flex-col gap-6 w-1/2">
            <div className="flex flex-col gap-4">
              <label className="text-white text-base font-serif italic pt-10" htmlFor="name">
                Name
              </label>
              <input
                className="bg-transparent not-italic pb-2 border-b-2 border-b-white font-sans focus:outline-none focus:ring-0 focus:border-b-brand-yellow text-white"
                type="text"
                placeholder="Enter vault name here..."
                id="name"
                required={true}
                {...register("name")}
              />
            </div>
            <div className="text-white flex flex-col gap-4 w-full">
              <textarea
                className="bg-gray-900 text-white rounded-md font-body h-40 border-none p-4 w-full resize-none overflow-y-auto focus:ring-0 outline-none focus:shadow-none"
                placeholder="Enter vault description here..."
                id="description"
                {...register("description")}
              />

              {!id || !hasSaved ? (
                <div className="text-white flex flex-col gap-4 w-[276px]">
                  <label className="text-white text-base font-serif italic" htmlFor="entity_type">
                    Select Vault Type
                  </label>
                  <Controller
                    name="entity_type"
                    control={control}
                    render={({ field }) => (
                      <RadioCardGroup
                        options={[
                          {
                            key: "product",
                            value: "USER_PRODUCT",
                            label: "Product",
                            description: "Brand",
                          },
                          {
                            key: "person",
                            value: "LICENSABLE_PROPERTY",
                            label: "Person",
                            description: "Talent",
                          },
                        ]}
                        selected={entity_type}
                        onSelect={(value) => {
                          // Clear entity_id when entity_type changes
                          field.onChange(value);
                          setValue("entity_id", "");
                        }}
                      />
                    )}
                  />
                </div>
              ) : null}

              {/* !hasSaved && watch("entity_type") === "LICENSABLE_PROPERTY" && talentEntities.length > 0 && (
                <div className="flex flex-col gap-4">
                  <label className="text-white text-base font-serif italic" htmlFor="entity_id">
                    Talent associated with vault
                  </label>
                  <Controller
                    name="entity_id"
                    control={control}
                    render={({ field }) => (
                      <select
                        {...field}
                        className="bg-transparent not-italic pb-2 border-b-2 border-b-white font-sans focus:outline-none focus:ring-0  text-white"
                      >
                        <option key={"no-entity"} value={""}>
                          -- Create New --
                        </option>
                        {talentEntities.map((entity) => (
                          <option key={entity.entity_id} value={entity.entity_id}>
                            {entity.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                </div>
              ) */}
              {/*!isCreator && !talentEntities.length && (
                <div className="flex flex-col gap-4">
                  <label className="text-white text-base font-serif italic" htmlFor="entity_id">
                    No existing talent found. Please create a talent first.
                  </label>
                </div>
              )*/}

              {/*!hasSaved && watch("entity_type") === "USER_PRODUCT" && productEntities.length > 0 && (
                <div className="flex flex-col gap-4">
                  <label className="text-white text-base font-serif italic" htmlFor="entity_id">
                    Product associated with vault
                  </label>
                  <Controller
                    name="entity_id"
                    control={control}
                    render={({ field }) => (
                      <select
                        {...field}
                        className="bg-transparent not-italic pb-2 border-b-2 border-b-white font-sans focus:outline-none focus:ring-0  text-white"
                      >
                        <option key={"no-entity"} value={""}>
                          -- Create New --
                        </option>
                        {productEntities.map((entity) => (
                          <option key={entity.entity_id} value={entity.entity_id}>
                            {entity.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                </div>
              )*/}
              {/*hasSaved && entity_id && (
                <div className="flex flex-col gap-4">
                  <label className="text-white text-base font-serif italic" htmlFor="entity_id">
                    {entity_type === "LICENSABLE_PROPERTY" ? "Talent" : "Product"} associated with vault
                  </label>
                  <span className="text-white text-base font-serif">
                    {availableEntities.find((entity) => entity.entity_id === entity_id)?.name || watch("name")}
                  </span>
                </div>
              )*/}
              <div className="flex flex-col gap-4" id="thumbnail_image_container">
                <label className="text-white text-base font-serif italic" htmlFor="thumbnail_image">
                  Vault Cover Image
                </label>
                <div className="flex flex-row gap-10 items-center">
                  {previewImage && (
                    <div
                      className="relative rounded-[20px] cursor-pointer h-40 w-40 flex items-center justify-center"
                      style={{
                        backgroundImage: `url(${previewImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                  )}
                  <div className="relative">
                    <Controller
                      name="thumbnail_image"
                      control={control}
                      render={({ field }) => (
                        <div>
                          <input
                            type="file"
                            id="thumbnail_image"
                            accept="image/*"
                            onChange={(val) => {
                              field.onChange(val?.target?.files == null ? "" : val?.target?.files[0]?.name);
                              handleFileChange(val);
                            }}
                            ref={fileInputRef}
                            className="hidden"
                          />
                          <Button
                            type="button"
                            className="self-start w-auto h-12 flex items-center"
                            onClick={() => fileInputRef.current?.click()}
                          >
                            {previewImage ? "Replace" : "Select"}
                          </Button>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className="fixed bottom-3 -left-3 w-full z-[1000] bg-blackish bg-opacity-50 px-5 py-2 flex justify-between">
        <Button
          onClick={() => navigate("/vaults")}
          className="outline-none bg-transparent text-white hover:bg-transparent"
        >
          Cancel
        </Button>
        <div className="space-x-3">
          {id && watch("training_set_id") && (
            <Button
              variant="outline"
              onClick={() => {
                if (!hasFilesInTrainingSet) {
                  showToast("Please add files to the training set first");
                  return;
                }
                generativeModelAPI
                  .trainModel(id)
                  .then((data) => {
                    showToast("Training has started.  Please allow up to 1 hour for it to complete.");
                    setValue("status", "TRAINING");
                  })
                  .then(() => {
                    setTaskQueueId(undefined);
                    setTimeout(() => {
                      generativeModelAPI.getModelById(id).then((vault) => {
                        // reset task queue id
                        setTaskQueueId(vault.task_queue_id);
                      });
                    }, 3000);
                  });
              }}
            >
              Start training
            </Button>
          )}
          {watch("entity_id") && (
            <Button
              variant="outline"
              label={watch("training_set_id") ? "Edit" : "Create" + " training Set"}
              onClick={() => {
                if (!entity_id) {
                  showToast("Please select a talent or product first");
                  return;
                } else {
                  editTrainingSet();
                }
              }}
            >
              <span>{watch("training_set_id") ? "Edit" : "Create"} training set</span>
            </Button>
          )}

          <Button
            className="border-none self-start"
            variant="primary"
            size="sm"
            disabled={!formState.isDirty || saving || uploading}
            label="Save"
            onClick={handleSubmit(onSubmit)}
          >
            <span>{saving && !uploading ? "Saving ..." : uploading ? "Uploading Profile Image" : "Save"}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VaultEdit;
