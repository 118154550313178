import React from "react";
import { Button } from "../../../common/components/ui/button";

interface UploadCardProps {
  onBrowse: () => void;
  isCompact?: boolean;
}

const UploadCard: React.FC<UploadCardProps> = ({ isCompact, onBrowse }) => {
  return (
    <div
      className={`flex flex-col items-center justify-center gap-2
      ${
        isCompact
          ? "rounded-lg p-4 bg-blackish @container w-[168px] h-[168px] @[400px]:w-[200px] @[400px]:h-[200px] @[800px]:w-[288px] @[800px]:h-[288px] overflow-hidden"
          : "h-full"
      }`}
    >
      <img
        src="/images/upload-image-cards.png"
        alt="Upload Images"
        className={`${isCompact ? "hidden w-full h-auto max-w-[80%]" : ""}`}
      />
      <p className={`text-white italic font-serif text-center ${!isCompact ? "text-xl" : ""}`}>{`Upload ${
        isCompact ? "more images" : "training data"
      }`}</p>
      {!isCompact && <p className="text-white font-body">Drag and drop your files</p>}
      <div className="relative w-full flex justify-center">
        <Button type="button" className="self-center w-auto h-10 flex items-center mt-2" onClick={() => onBrowse()}>
          Browse files
        </Button>
      </div>
    </div>
  );
};

export default UploadCard;
