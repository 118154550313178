import React, {useEffect, useRef, useState} from "react";
import {VersionHistoryWithMedia} from "../models/image_generator";
import {Video as VideoIcon} from "../../../common/icons/icons";

// allow selection clicks to fall through without hitting the controls
const ONE_SECOND_DELAY = 1000;

interface VideoThumbnailProps {
    variation: VersionHistoryWithMedia;
    isSelected: boolean;
    onSelect?: () => void;
    cardStyle: (isSelected: boolean) => string;
    hideControls?: boolean;
    loop?: boolean;
    cornerOverlay?: boolean;
}

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({
                                                           variation,
                                                           isSelected,
                                                           onSelect,
                                                           cardStyle,
                                                           hideControls = false,
                                                           loop = false,
                                                           cornerOverlay = false,
                                                       }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const controlsTimeoutRef = useRef<NodeJS.Timeout>();
    const [isPlaying, setIsPlaying] = useState(false);
    const [isReady, setIsReady] = useState(false);

    const handlePlay = () => {
        setIsPlaying(true);
        if (videoRef.current && isReady) {
            // Clear any existing timeout
            if (controlsTimeoutRef.current) {
                clearTimeout(controlsTimeoutRef.current);
            }
            // Set a new timeout to show controls after 300ms
            controlsTimeoutRef.current = setTimeout(() => {
                if (videoRef.current) {
                    videoRef.current.controls = !hideControls;
                }
            }, ONE_SECOND_DELAY);
        }
    };

    const handlePause = () => {
        setIsPlaying(false);
        if (videoRef.current) {
            // Clear the timeout when paused
            if (controlsTimeoutRef.current) {
                clearTimeout(controlsTimeoutRef.current);
                controlsTimeoutRef.current = undefined;
            }
            videoRef.current.controls = false;
        }
    };

    const handleMouseEnter = (e: React.MouseEvent<HTMLVideoElement>) => {
        if (isReady) {
            e.currentTarget.play().catch(() => {
                // Ignore play errors
            });
        }
    };

    const handleMouseLeave = (e: React.MouseEvent<HTMLVideoElement>) => {
        e.currentTarget.pause();
        // eslint-disable-next-line no-param-reassign
        e.currentTarget.currentTime = 0;
    };

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener("play", handlePlay);
            videoRef.current.addEventListener("pause", handlePause);
            videoRef.current.addEventListener("loadeddata", () => setIsReady(true));
        }
        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener("play", handlePlay);
                videoRef.current.removeEventListener("pause", handlePause);
                videoRef.current.removeEventListener("loadeddata", () => setIsReady(true));
            }
            // Clean up timeout on unmount
            if (controlsTimeoutRef.current) {
                clearTimeout(controlsTimeoutRef.current);
            }
        };
    }, [hideControls]);

    return (
        <div className="relative w-full h-full">
            <video
                crossOrigin="anonymous"
                ref={videoRef}
                src={variation.permalink}
                className={cardStyle(isSelected)}
                playsInline
                loop={loop}
                muted
                controls={false}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={onSelect && (() => onSelect())}
            />
            <div
                className={`absolute top-0 left-0 fill-white w-full h-full pointer-events-none transition-opacity duration-300 ${
                    isPlaying ? "opacity-0" : "opacity-100"
                }`}
            >
                <div
                    className={`bg-gradient-to-br from-black/50 to-transparent ${
                        cornerOverlay

                            ? "p-3 w-[82px] h-[82px] clip-path-triangle rounded-tl-lg"
                            : "p-[min(1rem,2vw)] h-full w-full flex items-center justify-center rounded-lg"
                    }`}
                >
                    <div className={`${cornerOverlay ? "w-[24px] h-[24px] absolute top-1.5 left-1.5" : ""}`}>
                        <VideoIcon/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoThumbnail;
