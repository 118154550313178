import React, {useState} from "react";
import Panel from "./panel";
import {useGenerativeAPI} from "../../../../api/generative_ai_api";
import {GenerateMediaResult, GenerativeModel, GenerativeModelWeight} from "../../models/image_generator";
import {Button} from "../../../../common/components/ui/button";
import {MIN_HEIGHT_IMAGE_PROMPT, Textarea} from "../../../../common/components/ui/textarea";
import RewriteButton from "../../../../common/components/rewrite_button";

const VideoPanel = ({
                        generativeData,
                        modelList,
                        expanded,
                        helperText,
                        fastGeneration,
                        setFastGeneration,
                        updatePrompt,
                        handleToggleExpand,
                        closePanel
                    }: {
    expanded: boolean | undefined,
    generativeData?: GenerateMediaResult | null,
    modelList: GenerativeModel[],
    helperText?: string,
    fastGeneration: boolean,
    setFastGeneration: (fastGen: boolean) => void,
    updatePrompt: (newPrompt: string, modelWeights: GenerativeModelWeight[]) => void,
    handleToggleExpand: () => void,
    closePanel: () => void
}) => {
    const [prompt, setPrompt] = useState<string>(generativeData?.prompt || "");
    const [isLoading, setIsLoading] = useState(false);

    const {enhancePrompt} = useGenerativeAPI();

    const enhanceTextPrompt = async () => {
        setIsLoading(true);
        const response = await enhancePrompt(prompt, []);
        if (response && response.enhanced) {
            setPrompt(response.enhanced);
        }
        setIsLoading(false);
    }

    const handleSave = () => {
        updatePrompt(prompt, [])
    };

    return (
        <Panel isExpanded={expanded} toggleExpanded={handleToggleExpand} helperText={helperText} title="Animate Image"
               closePanel={closePanel}>
            <div className="h-full space-y-6">
                <div className="relative">
                    <Textarea
                        variant="image_prompt"
                        className="bg-gray-700"
                        autoSizeMinHeight={MIN_HEIGHT_IMAGE_PROMPT}
                        insetControlHeight={48}
                        value={prompt} onChange={(e) => setPrompt(e.target.value)}
                    />
                    <div className="absolute bottom-5 right-5 z-10">
                        <RewriteButton onClick={enhanceTextPrompt} isLoading={isLoading} />
                    </div>
                </div>
                <Button onClick={handleSave} className="w-full border-none" variant="primary">Animate Image</Button>
            </div>
        </Panel>
    )
}

export default VideoPanel;