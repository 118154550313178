import { useAuth0 } from "@auth0/auth0-react";
import { File } from "../model/file";
import { TrainingSet, TrainingSetFile, CropImageRequest } from "../model/vault";

export function useTrainingSetAPI() {
    const { getAccessTokenSilently } = useAuth0();

    const fetchMyTrainingSets = async (): Promise<TrainingSet[]> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<TrainingSet[]>((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/training-set`, {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data: TrainingSet[]) => {
                        resolve(data);
                    });
                } else {
                    reject(new Error("User is not authenticated"));
                }
            });
        });
    };
    const getTrainingSet = async (id: string): Promise<TrainingSet> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<TrainingSet>((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/training-set/${id}`, {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data: TrainingSet) => {
                        resolve(data);
                    });
                } else {
                    reject(new Error("User is not authenticated"));
                }
            });
        });
    };
    const fetchTrainingSetForEntity = async (
        entityType: "LICENSABLE_PROPERTY" | "USER_PRODUCT" | "BRAND",
        entityId: string,
    ): Promise<TrainingSet[]> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<TrainingSet[]>((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/training-set/${entityType}/${entityId}`, {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data: TrainingSet[]) => {
                        resolve(data);
                    });
                } else {
                    reject(new Error("User is not authenticated"));
                }
            });
        });
    };
    const fetchTrainingFilesForEntity = async (
        entityType: "LICENSABLE_PROPERTY" | "USER_PRODUCT" | "BRAND",
        entityId: string,
    ): Promise<File[]> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<File[]>((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/training-set/training-files/${entityType}/${entityId}`, {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data: File[]) => {
                        resolve(data);
                    });
                } else {
                    reject(new Error("User is not authenticated"));
                }
            });
        });
    };
    const addFileToTrainingSet = async (trainingSetId: string, file: TrainingSetFile) => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<TrainingSetFile>((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/training-set/${trainingSetId}/file`, {
                method: "POST",
                headers: {
                    authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(file),
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data: TrainingSetFile) => {
                        resolve(data);
                    });
                } else {
                    reject(new Error("User is not authenticated"));
                }
            });
        });
    }
    const deleteTrainingSet = async (trainingSetId: string) => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<void>((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/training-set/${trainingSetId}`, {
                method: "DELETE",
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    resolve();
                } else {
                    reject(new Error("User is not authenticated"));
                }
            });
        });
    }
    const deleteFileFromTrainingSet = async (trainingSetId: string, fileId: string) => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<void>((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/training-set/${trainingSetId}/file/${fileId}`, {
                method: "DELETE",
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    resolve();
                } else {
                    reject(new Error("User is not authenticated"));
                }
            });
        });
    }
    const saveTrainingSet = async (trainingSet: TrainingSet) => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<TrainingSet>((resolve, reject) => {
            let url = `${process.env.REACT_APP_API_URL}/training-set`;
            if (trainingSet.id) {
                url = `${process.env.REACT_APP_API_URL}/training-set/${trainingSet.id}`;
            }
            fetch(url, {
                method: trainingSet.id ? "PUT" : "POST",
                headers: {
                    authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(trainingSet),
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data: TrainingSet) => {
                        resolve(data);
                    });
                } else {
                    reject(new Error("User is not authenticated"));
                }
            });
        });
    }

    const cropImage = async (cropImageRequest: CropImageRequest): Promise<File> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<File>((resolve, reject) => {
            const url = `${process.env.REACT_APP_API_URL}/image/crop`;
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(cropImageRequest),

            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data: File) => {
                        resolve(data);
                    });
                } else {
                    if (response.status === 401) {
                        reject(new Error("User is not authenticated"));
                    } else {
                        reject(new Error("Failed to crop image: " + response.statusText));
                    }
                }
            }).catch((error) => reject(error));
        });
    };

    const updateImageInTrainingSet = async (trainingSetId: string, existingTrainingSetFileId: string, trainingSetFile: TrainingSetFile): Promise<TrainingSet> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<TrainingSet>((resolve, reject) => {
            const url = `${process.env.REACT_APP_API_URL}/training-set/${trainingSetId}/file/${existingTrainingSetFileId}`;
            fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(trainingSetFile),

            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data: TrainingSet) => {
                        resolve(data);
                    });
                } else {
                    if (response.status === 401) {
                        reject(new Error("User is not authenticated"));
                    } else {
                        reject(new Error("Failed to crop image: " + response.statusText));
                    }
                }
            }).catch((error) => reject(error));
        });
    };


    return {
        getTrainingSet,
        fetchTrainingFilesForEntity,
        fetchMyTrainingSets,
        fetchTrainingSetForEntity,
        addFileToTrainingSet,
        deleteTrainingSet,
        deleteFileFromTrainingSet,
        saveTrainingSet,
        cropImage,
        updateImageInTrainingSet
    }
}