import React, { useState } from "react";
import { TrainingSet, TrainingSetFile } from "../../../model/vault";
import { useTrainingSetAPI } from "../../../api/training_set_api";
import AspectRatioCardGroup from "../../../common/components/radio-card-group";
import { Button } from "../../../common/components/ui/button";
import { EditorMode, EditorTool, usePinturaEditor } from "../../../pages/image-generator/pintura/editor";
import { useFileAPI } from "../../../api/file_api";
import "./crop_modal.css";
import AR_OPTIONS from "../../../lib/aspect-ratio";
import { Rect } from "@pqina/pintura";

// todo: move this to the image-generator/models/constants.ts file
const imageSizeToDefaultAspectRatio = (width: number, height: number) => {
  if (width === height) {
    return "SQUARE";
  } else if (Math.round((width * 10000) / height) === 5625) {
    return "VERTICAL";
  } else {
    return "FREE";
  }
};

const CaptionAndCropModal = ({
  trainingSetFile,
  trainingSet,
  handleSave,
  close,
  closeWithError,
}: {
  trainingSetFile?: TrainingSetFile;
  trainingSet: TrainingSet;
  handleSave: (updatedTrainingSet: TrainingSet) => void;
  close?: () => void;
  closeWithError: (message: string) => void;
}) => {
  const [description, setDescription] = useState<string>(trainingSetFile?.description || "");
  const [defaultAspectRatio] = useState<string>(
    imageSizeToDefaultAspectRatio(trainingSetFile?.image.width || 0, trainingSetFile?.image.height || 0)
  );
  const [selectedAspectRatio, setSelectedAspectRatio] = useState<string>(defaultAspectRatio);
  const [hasBeenCropped, setHasBeenCropped] = useState<boolean>(false);
  const [cropState, setCropState] = useState<Rect | null>(null);
  const { uploadFile } = useFileAPI();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { updateImageInTrainingSet, saveTrainingSet } = useTrainingSetAPI();
  const isDirty = hasBeenCropped || description !== trainingSetFile?.description;

  const handleSaveFile = async (file?: File) => {
    // if no file is provided, the image has not been cropped
    if (!trainingSetFile) return;
    if (!isDirty) {
      close && close();
      return;
    }

    setIsLoading(true);
    const trainingSetId = trainingSet.id;
    const currentFileId = trainingSetFile?.file_id || "";
    // all we are doing here is updating the description if the original has not been cropped
    let updatedFile = { ...trainingSetFile, description } as TrainingSetFile | any;

    if (hasBeenCropped && file) {
      const uploadRequest = {
        files: [file],
        is_public: true,
        // object_id: trainingSet.entity_id, // uncomment if we want to add the cropped image to the talent entity
        object_type: trainingSet.entity_type || "LICENSABLE_PROPERTY",
        generate_thumbnail: true,
        resize: "medium",
        usage: "training",
      };

      const uploadedFiles = await uploadFile(uploadRequest, (progress) => {
        //console.log((statusText: string) => statusText + "." + progress.progress + "%");
      });
      const uploadedFile = uploadedFiles[0];
      updatedFile = {
        ...trainingSetFile,
        file_id: uploadedFile.id,
        file_permalink: uploadedFile.permalink,
        file_thumbnail: uploadedFile.thumbnail,
        description,
      };
    }

    if (!trainingSetId) {
      try {
        const updatedFiles = trainingSet.files.map((file) => (file.file_id === currentFileId ? updatedFile : file));

        const updatedTrainingSet = { ...trainingSet, files: updatedFiles };

        const response = await saveTrainingSet(updatedTrainingSet);
        handleSave(response);
      } catch {
        closeWithError("Failed to create new training set");
        console.log("Failed to save changes");
      }
    } else {
      try {
        const response = await updateImageInTrainingSet(trainingSetId, currentFileId, updatedFile);
        handleSave(response);
      } catch {
        closeWithError("Failed to update training set");
        console.log("Failed to save changes");
        return;
      }
    }
  };

  const {
    // imageState: pinturaImageState,
    // undo: pinturaUndo,
    editorElement: pinturaEditor,
    loadError: pinturaError,
    process,
    setAspectRatio,
  } = usePinturaEditor({
    src: trainingSetFile?.image,
    util: EditorTool.PanAndZoom,
    mode: EditorMode.CropModal,
    onProcess: (files: FileList) => {
      // Only save if the image has been cropped or the description has changed
      if (files[0] && isDirty) {
        handleSaveFile(files[0]);
      } else {
        // If no changes, just close the modal
        close && close();
      }
    },
    onUpdate: (state) => {
      if (state.crop && !cropState) {
        // set initial crop state
        setCropState(state.crop);
      } else if (cropState && state.crop) {
        // if the crop state has changed, set the hasBeenCropped to true
        if (
          cropState.width !== state.crop.width ||
          cropState.height !== state.crop.height ||
          cropState.x !== state.crop.x ||
          cropState.y !== state.crop.y
        ) {
          setHasBeenCropped(true);
        }
      }
    },
    onRevert: () => {
      handleAspectRatioChange(defaultAspectRatio);
      setHasBeenCropped(false);
    },
  });

  const handleAspectRatioChange = (aspectRatioKey: string) => {
    setSelectedAspectRatio(aspectRatioKey);
    setAspectRatio(aspectRatioKey);
    setHasBeenCropped(true);
  };

  // use css and container query to resize .pintura-frame
  return (
    <div className=" flex flex-row w-full p-4 gap-10 ">
      <div
        className={`pintura-container min-h-[100%] grow-[1] border border-blackish p-0 ${
          pinturaError ? "bg-blackish" : ""
        }`}
      >
        {pinturaEditor}
      </div>
      <div className="flex flex-col gap-8 lg:min-w-[240px] max-w-[480px]">
        <h1 className="text-3xl">Caption and crop</h1>
        <div className="flex flex-col">
          <p className="font-semibold mb-2">Description</p>
          <textarea
            className="bg-gray-100 font-body resize-none rounded-xl h-44 p-3 outline-none"
            placeholder="Enter description..."
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          ></textarea>
        </div>
        <div>
          <p className="font-semibold mb-2">Aspect ratio</p>
          <AspectRatioCardGroup
            options={AR_OPTIONS}
            selected={selectedAspectRatio}
            onSelect={handleAspectRatioChange}
          />
        </div>
        <div className="flex gap-4 w-full">
          <Button id="close" onClick={() => close && close()}>
            Cancel
          </Button>
          <Button
            loading={isLoading}
            variant="primary-negative"
            className="grow-[1] hover:bg-brand-yellow hover:border-brand-yellow hover:text-black"
            onClick={hasBeenCropped ? process : () => handleSaveFile()}
          >
            {isLoading ? "Saving image..." : "Save"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CaptionAndCropModal;
