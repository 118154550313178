import React, {useCallback, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import cn from "clsx";
import {useFileAPI} from "../../api/file_api";
import {useGenerativeAPI} from "../../api/generative_ai_api";
import {useImageBoardAPI} from "../../api/image_board_api";
import {useUserProductAPI} from "../../api/user_product_api";
import {DialogModal} from "../../common/components/ui/dialog_modal";
import { useToast } from "../../common/components/ui/use_toast";
import cachedImageElement from "../../lib/cached-image-element";
import ImageSideNavPanel from "./components/image_side_nav_panel";
import SaveImageContent from "./components/modal-components/save_image";
import {PANELS} from "./components/panelConfig";
import AspectRatioPanel from "./components/panels/aspect_ratio_panel";
import DescriptionPanel from "./components/panels/description_panel";
import DragAndDropPanel from "./components/panels/drag_and_drop_panel";
import EditDirectlyPanel from "./components/panels/edit_directly_panel";
import VersionHistoryListPanel from "./components/panels/version_history_list_panel";
import VersionHistoryPanel from "./components/panels/version_history_panel";
import VideoPanel from "./components/panels/video_panel";
import {
    BoardDTO,
    GenerateMediaResult,
    GenerativeModel,
    GenerativeModelWeight,
    Panel,
    PanelId,
    TextureRequest,
    UpscaleRequest,
    VersionHistoryWithMedia
} from "./models/image_generator";
import {EditorMode, EditorTool, usePinturaEditor} from "./pintura/editor";

const isDisabledAction = (panelId: string, selectedVersionHistory: VersionHistoryWithMedia | null) => {
    if (selectedVersionHistory?.asset_type !== "video") return false;
    return [
        PanelId.EditDirectly,
        PanelId.DragAndDrop,
        PanelId.Upscale,
        PanelId.NaturalLook,
        PanelId.AspectRatio,
        PanelId.Video,
    ].includes(panelId as PanelId);
};

const ImageEditor = () => {
    const {taskQueueId: initialTaskQueueId} = useParams<{ taskQueueId: string; }>();
    const [taskQueueId, setTaskQueueId] = useState<string | null>(initialTaskQueueId || null);
    const [generativeData, setGenerativeData] = useState<GenerateMediaResult | null>(null);
    const [modelList, setModelList] = useState<GenerativeModel[]>([]);
    const [smartAssetList, setSmartAssetList] = useState<GenerativeModel[]>([]);
    const [versionHistory, setVersionHistory] = useState<VersionHistoryWithMedia[]>([]);
    const [isInpaint, setIsInpaint] = useState(false);
    const [isDragAndDropActive, setIsDragAndDropActive] = useState(false);
    const [isSmartAsset, setIsSmartAsset] = useState(false);
    const [selectedVersionHistory, setSelectedVersionHistory] = useState<VersionHistoryWithMedia | null>(null);
    const [showLoadingImage, setShowLoadingImage] = useState(false);
    const [headerText, setHeaderText] = useState<string>("");
    const [emptyPanelState, setEmptyPanelState] = useState(false);
    const [openSaveModal, setOpenSaveModal] = useState(false);
    const [boards, setBoards] = useState<BoardDTO[]>([]);
    const [fastGeneration, setFastGeneration] = useState(false);
    const [pinturaImageState, setPinturaImageState] = useState<any>(null);
    const [statusResponse, setStatusResponse] = useState<Array<{
        percent_complete: number;
        progress_message: string;
    }>>([]);
    const [panels, setPanels] = useState<Panel[]>(() =>
        PANELS.map(panel => ({
            ...panel,
            active: false,
            isExpanded: panel.isDivider ? false : true,
        }))
    );
    const { toast } = useToast();

    let src = "" as any;
    if (selectedVersionHistory && selectedVersionHistory?.asset_type !== "video") {
        src = selectedVersionHistory.media;
    }

    const {undo: pinturaUndo, reset: pinturaReset, editorElement: pinturaEditor} =
        usePinturaEditor({
            src,
            mode: EditorMode.Editor,
            util: isInpaint ? EditorTool.Brush :
                isDragAndDropActive ? EditorTool.DragAndDrop :
                    EditorTool.PanAndZoom,
            onUpdate: setPinturaImageState,
        });

    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const headerTexts = [
        "Ta-da! Your image is ready.",
        "Done and done! Your image is ready.",
        "Boom! Your image is here."
    ];

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * headerTexts.length);
        setHeaderText(headerTexts[randomIndex]);
    }, []);

    useEffect(() => {
        return () => {
            clearTimers();
        };
    }, []);

    const {
        getGenerativeOutput,
        generateImageMedia,
        checkTaskQueueStatus,
        getVersionHistory,
        getModels,
        upscaleImage,
        saveFeedback,
        textureImage
    } = useGenerativeAPI();

    const {getImageBoards, addOrRemoveFavorite} = useImageBoardAPI();
    const {fetchFilesByUsage} = useUserProductAPI();
    const {downloadFile} = useFileAPI();

    useEffect(() => {
        const fetchGenerativeData = async () => {
            if (!taskQueueId) return;

            try {
                const [generativeData, childVersionHistory] = await Promise.all([
                    getGenerativeOutput(taskQueueId),
                    getVersionHistory(taskQueueId)
                ]);

                const parentVersionHistory: VersionHistoryWithMedia[] = generativeData.outputs.map(output => ({
                    generative_output_id: output.generative_output_id,
                    file_id: output.file_id,
                    permalink: output.permalink,
                    asset_type: output.asset_type,
                    created_at: output.created_at,
                    action: generativeData.action,
                    parentTaskId: generativeData.parentTaskId,
                    seed: generativeData.seed,
                    task_queue_id: taskQueueId,
                    aspect_ratio: generativeData.aspect_ratio,
                    height: generativeData.height,
                    width: generativeData.width,
                    prompt: generativeData.prompt,
                    inpaint_prompt: generativeData.inpaint_prompt,
                }));

                const combinedVersionHistory = [...parentVersionHistory, ...childVersionHistory]
                    .map((v: VersionHistoryWithMedia) => ({...v, media: cachedImageElement(v.permalink)}));
                combinedVersionHistory.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

                setVersionHistory(combinedVersionHistory);

                setPanels(currentPanels =>
                    currentPanels.map(panel => ({
                        ...panel,
                        active: panel.id === PanelId.Description || panel.id === PanelId.Versions ? true : panel.active
                    }))
                );
                setSelectedVersionHistory(combinedVersionHistory[0]);
                setGenerativeData(generativeData);

            } catch (error) {
                console.error("Error fetching task queue data", error);
            }
        };

        fetchGenerativeData();
    }, [taskQueueId]);

    useEffect(() => {
        const fetchModelList = async () => {
            try {
                const modelTypes = ["USER_PRODUCT", "BRAND", "LICENSABLE_PROPERTY"] as const;
                const responses = await Promise.all(
                    modelTypes.map(type => getModels(type))
                );

                const combinedModels = responses.flatMap((response, index) =>
                    response.map(model => ({
                        ...model,
                        model_type: modelTypes[index].toLowerCase()
                    }))
                );

                setModelList(combinedModels);
                fetchAndSetSmartAssets(combinedModels);
            } catch (error) {
                console.error("Error fetching models", error);
            }
        };

        fetchModelList();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const myBoards = await getImageBoards();
                setBoards(myBoards);
            } catch (error) {
                console.error("Error fetching generative output", error);
            }
        };
        fetchData();
    }, []);

    const fetchAndSetSmartAssets = async (models: GenerativeModel[]) => {
        const smartAssets = models.filter(model => model.entity_type === "USER_PRODUCT");
        setSmartAssetList(smartAssets);
    };

    const generateVariations = async () => {
        const prompt_text = generativeData?.prompt || "Generate variations";
        return regenerateImage(
            prompt_text,
            generativeData?.aspect_ratio,
            generativeData?.model_weights,
            selectedVersionHistory?.file_id,
            generativeData?.task_queue_id,
            "generate_media");
    };

    useEffect(() => {
        const activePanels = panels.map(panel => ({
            ...panel,
            active: isDisabledAction(panel.id, selectedVersionHistory) ? false : panel.active,
            isDisabled: isDisabledAction(panel.id, selectedVersionHistory)
        }));
        setPanels(activePanels);
    }, [selectedVersionHistory]);

    useEffect(() => {
        const isEditDirectlyActive = panels.find(p => p.id === PanelId.EditDirectly);
        setIsInpaint(isEditDirectlyActive?.active || false);
    }, [panels]);

    useEffect(() => {
        const isDragAndDropActive = panels.find(p => p.id === PanelId.DragAndDrop);
        setIsDragAndDropActive(isDragAndDropActive?.active || false);
    }, [panels]);

    useEffect(() => {
        const activePanels = panels.some(
            panel => !panel.isShort && panel.active
        );
        setEmptyPanelState(!activePanels);
    }, [panels]);

    const handlePanelClick = (clickedPanelId: string, keepOthersExpanded = false) => {
        // disable image-only actions for videos
        if (isDisabledAction(clickedPanelId, selectedVersionHistory)) {
            return;
        }

        switch (clickedPanelId) {
            case PanelId.Upscale:
                fetchUpscaleImage();
                break;
            case PanelId.Save:
                setOpenSaveModal(true);
                break;
            case PanelId.FavoriteImage:
                handleFavoriteImage();
                break;
            case PanelId.DownloadImage:
                downloadImage();
                break;
            case PanelId.NaturalLook:
                fetchTextureImage();
                break;
        }

        setPanels(currentPanels => {
            const clickedPanel = currentPanels.find(p => p.id === clickedPanelId);
            const isActivating = !clickedPanel?.active;
            const isShortPanel = clickedPanel?.isShort;

            return currentPanels.map(panel => ({
                ...panel,
                active: panel.id === clickedPanelId
                    ? !panel.active :
                    (panel.id === PanelId.EditDirectly && clickedPanelId === PanelId.DragAndDrop && isActivating) ||
                    (panel.id === PanelId.DragAndDrop && clickedPanelId === PanelId.EditDirectly && isActivating)
                        ? false
                        : panel.active,
                isExpanded: isShortPanel
                    ? panel.isExpanded
                    : (isActivating
                        ? (panel.id === clickedPanelId || (keepOthersExpanded && panel.isExpanded))
                        : panel.isExpanded)
            }));
        });
    };

    const handleToggleExpand = (panelId: string) => {
        setPanels(currentPanels =>
            currentPanels.map(panel => ({
                ...panel,
                isExpanded: panel.id === panelId ? !panel.isExpanded : panel.isExpanded
            }))
        );
    };

    const renderPanelContent = (panelId: string) => {
        switch (panelId) {
            case PanelId.Description:
                return (
                    <DescriptionPanel
                        generativeData={generativeData}
                        modelList={modelList}
                        handleToggleExpand={() => handleToggleExpand(PanelId.Description)}
                        expanded={panels.find(panel => panel.id === PanelId.Description)?.isExpanded}
                        updatePrompt={generateNewImage}
                        fastGeneration={fastGeneration}
                        setFastGeneration={setFastGeneration}
                        closePanel={() => handlePanelClick(PanelId.Description)}
                        helperText={panels.find(panel => panel.id === PanelId.Description)?.details}
                    />
                );
            case PanelId.Video:
                return (
                    <VideoPanel
                        generativeData={generativeData}
                        modelList={modelList}
                        handleToggleExpand={() => handleToggleExpand(PanelId.Video)}
                        expanded={panels.find(panel => panel.id === PanelId.Video)?.isExpanded}
                        updatePrompt={generateVideo}
                        fastGeneration={fastGeneration}
                        setFastGeneration={setFastGeneration}
                        closePanel={() => handlePanelClick(PanelId.Video)}
                        helperText={panels.find(panel => panel.id === PanelId.Video)?.details}
                    />
                );
            case PanelId.Versions:
                return (
                    <VersionHistoryPanel
                        versionHistory={versionHistory}
                        selectedVariant={selectedVersionHistory}
                        handleToggleExpand={() => handleToggleExpand(PanelId.Versions)}
                        expanded={panels.find(panel => panel.id === PanelId.Versions)?.isExpanded}
                        setSelectedVariant={setSelectedVersionHistory}
                        showLoadingImage={showLoadingImage}
                        generateVariation={generateVariations}
                        closePanel={() => handlePanelClick(PanelId.Versions)}
                        helperText={panels.find(panel => panel.id === PanelId.Versions)?.details}
                        statusResponse={statusResponse}
                    />
                );
            case PanelId.AspectRatio:
                return (
                    <AspectRatioPanel
                        generativeData={generativeData}
                        handleAspectRatioChange={editAspectRatio}
                        handleToggleExpand={() => handleToggleExpand(PanelId.AspectRatio)}
                        expanded={panels.find(panel => panel.id === PanelId.AspectRatio)?.isExpanded}
                        closePanel={() => handlePanelClick(PanelId.AspectRatio)}
                        helperText={panels.find(panel => panel.id === PanelId.AspectRatio)?.details}
                    />
                );
            case PanelId.History:
                return (
                    <VersionHistoryListPanel
                        versionHistory={versionHistory}
                        selectedVersionHistory={selectedVersionHistory}
                        handleToggleExpand={() => handleToggleExpand(PanelId.History)}
                        expanded={panels.find(panel => panel.id === PanelId.History)?.isExpanded}
                        closePanel={() => handlePanelClick(PanelId.History)}
                        helperText={panels.find(panel => panel.id === PanelId.History)?.details}
                    />
                );
            case PanelId.EditDirectly:
                return (
                    <EditDirectlyPanel
                        modelList={modelList}
                        generateInpaint={generateInpaint}
                        handleToggleExpand={() => handleToggleExpand(PanelId.EditDirectly)}
                        expanded={panels.find(panel => panel.id === PanelId.EditDirectly)?.isExpanded}
                        closePanel={() => handlePanelClick(PanelId.EditDirectly)}
                        helperText={panels.find(panel => panel.id === PanelId.EditDirectly)?.details}
                        pinturaImageState={pinturaImageState}
                        naturalHeight={selectedVersionHistory?.height || selectedVersionHistory?.media?.height || 0}
                        naturalWidth={selectedVersionHistory?.width || selectedVersionHistory?.media?.width || 0}
                    />
                );
            case PanelId.DragAndDrop:
                return (
                    <DragAndDropPanel
                        modelList={smartAssetList}
                        isSmartAsset={isSmartAsset}
                        setIsSmartAsset={setIsSmartAsset}
                        generateInpaint={generateInpaint}
                        handleToggleExpand={() => handleToggleExpand(PanelId.DragAndDrop)}
                        expanded={panels.find(panel => panel.id === PanelId.DragAndDrop)?.isExpanded}
                        closePanel={() => handlePanelClick(PanelId.DragAndDrop)}
                        helperText={panels.find(panel => panel.id === PanelId.DragAndDrop)?.details}
                        pinturaImageState={pinturaImageState}
                        naturalHeight={selectedVersionHistory?.height || selectedVersionHistory?.media?.height || 0}
                        naturalWidth={selectedVersionHistory?.width || selectedVersionHistory?.media?.width || 0}
                    />
                );
            case PanelId.Upscale:
                return null;
            case PanelId.Save:
                return null;
            case PanelId.FavoriteImage:
                return null;
            case PanelId.DownloadImage:
                return null;
            default:
                return null;
        }
    };

    const generateInpaint = async (inpaintPrompt: string, modelWeights: {
        id: string;
        weight: number;
    }[], mask: string | null, inpaintFileId?: string | null) => {
        if (!mask) {
            showToast("Please select the area to inpaint");
            return;
        }
        setShowLoadingImage(true);
        clearTimers();
        setPanels(currentPanels =>
            currentPanels.map(panel => ({
                ...panel,
                active: panel.id === PanelId.Versions ? true : panel.active,
                isExpanded: panel.id === PanelId.Versions ? true : panel.isExpanded
            }))
        );

        try {
            const response = await generateImageMedia({
                prompt: selectedVersionHistory?.prompt ? selectedVersionHistory.prompt : generativeData ? generativeData.prompt : "",
                aspect_ratio: generativeData?.aspect_ratio || "SQUARE",
                image_file_id: selectedVersionHistory?.file_id || generativeData?.outputs[0].file_id || "",
                model_weights: modelWeights || [],
                seed: generativeData?.seed,
                num_images_per_prompt: 1,
                mask: mask,
                parent_task_id: generativeData?.task_queue_id || undefined,
                inpaint_prompt: inpaintPrompt,
                inpaint_image_file_id: inpaintFileId || undefined,
            });

            intervalRef.current = setInterval(() => checkStatusMethod(response.id), 1000);
            timeoutRef.current = setTimeout(() => handleTimeout(), 2 * 60 * 1000);
        } catch (error) {
            setShowLoadingImage(false);
            showToast("Error generating image");
            console.error("Error generating image", error);
        }
    };

    const editAspectRatio = useCallback((newAspectRatio: string) => {
        setGenerativeData((prevData) => {
            if (!prevData) return null;
            return {...prevData, aspect_ratio: newAspectRatio};
        });
        regenerateImage("", newAspectRatio, generativeData?.model_weights, selectedVersionHistory?.file_id, generativeData?.task_queue_id, "outpainting");
    }, [generativeData, selectedVersionHistory]);

    const handleFavoriteImage = async () => {
        if (!selectedVersionHistory) {
            showToast("Error saving image");
            return;
        }

        try {
            await addOrRemoveFavorite(selectedVersionHistory.generative_output_id);
            showToast("Image saved to Favorites");
            setTimeout(() => {
                setPanels(currentPanels =>
                    currentPanels.map(panel => ({
                        ...panel,
                        active: panel.id === PanelId.FavoriteImage ? false : panel.active
                    }))
                );
            }, 1000);
        } catch (error) {
            showToast("Error saving favorite image");
            console.error("Error saving image", error);
        }
    };

    const closeSavePanel = (board?: BoardDTO) => {
        setOpenSaveModal(false);
        setPanels(currentPanels =>
            currentPanels.map(panel =>
                panel.id === PanelId.Save ? {...panel, active: false} : panel
            )
        );

        if (board) {
            const boardName = board.name;
            setBoards(prevBoards => [board, ...prevBoards]);
            if (boardName) {
                showToast(`Image saved to ${boardName}`);
            }
        }
    };

    const downloadImage = async () => {
        if (!generativeData) return;
        try {
            const fileId = selectedVersionHistory ? selectedVersionHistory.file_id : generativeData?.outputs[0].file_id;
            const response = await downloadFile(fileId);
            const blob = await response.blob();
            const fileName = response.headers.get("Content-Disposition")?.split("filename=")[1] || "image.png";
            const contentType = response.headers.get("Content-Type") || "image/png";
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            let fileTypeName = contentType.split("/")[0];
            if (fileTypeName == "video") fileTypeName = "Video";
            else fileTypeName = "Image";

            showToast(`${fileTypeName} downloaded`);
            setTimeout(() => {
                setPanels(currentPanels =>
                    currentPanels.map(panel => ({
                        ...panel,
                        active: panel.id === PanelId.DownloadImage ? false : panel.active
                    }))
                );
            }, 1000);
        } catch (error) {
            showToast("Error downloading image");
            console.error("Error downloading image", error);
        }
    };

    const showToast = (message: string, toastDisplayTime?: number) => {
        toast({
            title: message,
            variant: "default",
            duration: toastDisplayTime
        });
    };

    const fetchUpscaleImage = async () => {
        if (!selectedVersionHistory || !taskQueueId) {
            showToast("Error upscaling image");
            return;
        }

        const payload: UpscaleRequest = {
            generative_output_id: selectedVersionHistory.generative_output_id,
            file_id: selectedVersionHistory.file_id,
            seed: generativeData?.seed,
            parent_task_id: generativeData?.task_queue_id
        };

        try {
            setShowLoadingImage(true);
            clearTimers();
            setPanels(currentPanels =>
                currentPanels.map(panel => ({
                    ...panel,
                    active: panel.id === PanelId.Versions ? true : panel.active,
                    isExpanded: panel.id === PanelId.Versions ? true : panel.isExpanded
                }))
            );
            const response = await upscaleImage(payload);
            setPanels(currentPanels =>
                currentPanels.map(panel => ({
                    ...panel,
                    active: panel.id === PanelId.Upscale ? false : panel.active
                }))
            );
            intervalRef.current = setInterval(() => checkStatusMethod(response.id), 1000);
            timeoutRef.current = setTimeout(() => handleTimeout(), 2 * 60 * 1000);
        } catch (error) {
            console.error();
            showToast("Error upscaling image");
        }
    };

    const fetchTextureImage = async () => {
        if (!selectedVersionHistory || !taskQueueId) {
            showToast("Error texturing image");
            setShowLoadingImage(false);
            return;
        }

        const payload: TextureRequest = {
            generative_output_id: selectedVersionHistory.generative_output_id,
            file_id: selectedVersionHistory.file_id,
            seed: generativeData?.seed,
            parent_task_id: generativeData?.task_queue_id
        };

        try {
            setShowLoadingImage(true);
            clearTimers();
            setPanels(currentPanels =>
                currentPanels.map(panel => ({
                    ...panel,
                    active: panel.id === PanelId.Versions ? true : panel.active,
                    isExpanded: panel.id === PanelId.Versions ? true : panel.isExpanded
                }))
            );
            const response = await textureImage(payload);
            setPanels(currentPanels =>
                currentPanels.map(panel => ({
                    ...panel,
                    active: panel.id === PanelId.NaturalLook ? false : panel.active
                }))
            );
            intervalRef.current = setInterval(() => checkStatusMethod(response.id), 1000);
            timeoutRef.current = setTimeout(() => handleTimeout(), 2 * 60 * 1000);
        } catch (error) {
            console.error();
            showToast("Error texturing image");
        }
    };

    const regenerateImage = async (prompt?: string, aspect_ratio?: string, model_weights?: GenerativeModelWeight[], image_file_id?: string, parent_task_id?: string, action?: string) => {
        setShowLoadingImage(true);
        clearTimers();
        setPanels(currentPanels =>
            currentPanels.map(panel => ({
                ...panel,
                active: panel.id === PanelId.Versions ? true : panel.active,
                isExpanded: panel.id === PanelId.Versions ? true : panel.isExpanded
            }))
        );
        try {
            const response = await generateImageMedia({
                prompt: prompt || generativeData?.prompt || "",
                aspect_ratio: aspect_ratio || generativeData?.aspect_ratio || "SQUARE",
                num_images_per_prompt: 1,
                image_file_id: image_file_id || selectedVersionHistory?.file_id || generativeData?.outputs[0].file_id || "",
                model_weights: model_weights || generativeData?.model_weights || [],
                seed: generativeData?.seed,
                parent_task_id: parent_task_id || undefined,
                action: action || "generate_media",
            });

            intervalRef.current = setInterval(() => checkStatusMethod(response.id), 1000);
            timeoutRef.current = setTimeout(() => handleTimeout(), 2 * 60 * 1000);
        } catch (error) {
            setShowLoadingImage(false);
            showToast("Error generating image");
            console.error("Error generating image", error);
        }
    };

    const generateNewImage = async (prompt: string, model_weights: GenerativeModelWeight[]) => {
        setShowLoadingImage(true);
        clearTimers();
        setPanels(currentPanels =>
            currentPanels.map(panel => ({
                ...panel,
                active: panel.id === PanelId.Versions ? true : panel.active,
                isExpanded: panel.id === PanelId.Versions ? true : panel.isExpanded
            }))
        );

        try {
            const response = await generateImageMedia({
                prompt: prompt,
                parent_task_id: selectedVersionHistory?.task_queue_id,
                aspect_ratio: selectedVersionHistory?.aspect_ratio || generativeData?.aspect_ratio || "SQUARE",
                num_images_per_prompt: 1,
                model_weights: model_weights || [],
                quality: fastGeneration ? "low" : "high"
            });
            intervalRef.current = setInterval(() => checkStatusMethod(response.id), 1000);
            timeoutRef.current = setTimeout(() => handleTimeout(), 2 * 60 * 1000);
        } catch (error) {
            setShowLoadingImage(false);
            showToast("Error generating image");
            console.error("Error generating image", error);
        }
    };

    const generateVideo = async (prompt: string, model_weights: GenerativeModelWeight[]) => {
        setShowLoadingImage(true);
        showToast("Video generation started. This could take a few minutes. Once completed, you'll see it in your Video Board.", 5000);
        setPanels(currentPanels =>
            currentPanels.map(panel => ({
                ...panel,
                active: panel.id === PanelId.Versions ? true : panel.active,
                isExpanded: panel.id === PanelId.Versions ? true : panel.isExpanded
            }))
        );

        try {
            const response = await generateImageMedia({
                action: "video",
                prompt: prompt,
                parent_task_id: selectedVersionHistory?.task_queue_id,
                aspect_ratio: selectedVersionHistory?.aspect_ratio || generativeData?.aspect_ratio || "SQUARE",
                num_images_per_prompt: 1,
                model_weights: model_weights || [],
                quality: fastGeneration ? "low" : "high",
                image_file_id: selectedVersionHistory?.file_id || generativeData?.outputs[0].file_id || "",
                seed: generativeData?.seed,
                video_length: 5,
                video_fps: 10
            });
            intervalRef.current = setInterval(() => checkStatusMethod(response.id), 2000);
        } catch (error) {
            setShowLoadingImage(false);
            showToast("Error generating video");
            console.error("Error generating video", error);
        }
    };

    const newFetchGenOutput = async (taskQueueId: string) => {
        try {
            const response = await getGenerativeOutput(taskQueueId);
            const latestVariation = response.outputs.map(output => ({
                generative_output_id: output.generative_output_id,
                file_id: output.file_id,
                permalink: output.permalink,
                asset_type: output.asset_type,
                action: response.action,
                parentTaskId: response.parentTaskId,
                seed: response.seed,
                task_queue_id: response.task_queue_id,
                aspect_ratio: response.aspect_ratio,
                height: response.height,
                width: response.width,
                prompt: response.prompt,
                inpaint_prompt: response.inpaint_prompt,
                created_at: output.created_at,
            }));
            const latestVariationWithImage = {
                ...latestVariation[0],
                media: cachedImageElement(latestVariation[0].permalink)
            };
            versionHistory.push(latestVariationWithImage);
            setSelectedVersionHistory(latestVariationWithImage);
        } catch (error) {
            console.error("Error fetching generative output", error);
        }
    };

    const checkStatusMethod = async (updatedTaskQueueId: string) => {
        try {
            const response = await checkTaskQueueStatus(updatedTaskQueueId);
            let failedTask = null;
            let completedTask = null;
            for (let i = response.length - 1; i >= 0; i--) {
                if (response[i].status === "completed") {
                    completedTask = response[i];
                    break;
                }
                if (response[i].status === "failed") {
                    failedTask = response[i];
                    break;
                }
            }
            setStatusResponse(response);

            if (failedTask) {
                setShowLoadingImage(false);
                clearTimers();
                setStatusResponse([]);
                showToast("Failed to generate image: " + failedTask.progress_message);
                return;
            }
            if (completedTask && completedTask.progress_status === "failed") {
                setShowLoadingImage(false);
                clearTimers();
                setStatusResponse([]);
                showToast("Failed to generate image: " + completedTask.progress_message);
                return;
            }

            if (completedTask && completedTask.progress_status === "completed") {
                setShowLoadingImage(false);
                clearTimers();
                setStatusResponse([]);
                showToast(headerText);
                newFetchGenOutput(updatedTaskQueueId);
                return;
            }
        } catch (error) {
            console.error("Error checking status", error);
        }
    };

    const clearTimers = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    };

    const handleTimeout = () => {
        if (intervalRef.current) {
            clearTimers();
            setStatusResponse([]);
            setShowLoadingImage(false);
            showToast("Oops! It's taking a little too long to create your image. You can retry in a moment!");
        }
    };

    return (
        <div className="px-[60px] pt-[120px] flex flex-row justify-between sm:gap-1 md:gap-4 lg:gap-8">
            <DialogModal
                isOpen={openSaveModal}
                onOpenChange={setOpenSaveModal}
                onClose={closeSavePanel}
            >
                <SaveImageContent
                    generativeOutputId={selectedVersionHistory && selectedVersionHistory.generative_output_id}
                    taskQueueId={taskQueueId} boards={boards} closeModal={closeSavePanel}/>
            </DialogModal>
            <ImageSideNavPanel panels={panels} onPanelClick={handlePanelClick} renderPanelContent={renderPanelContent}/>

            <div id="side-panel-content" className={`transition-all duration-300 w-1/3 space-y-6 h-[80vh] min-w-[18em] flex flex-col
                ${!emptyPanelState ? "opacity-100 max-w-[33%]" : "opacity-0 max-w-0"}`}
            >
                <div className="flex flex-col overflow-y-auto pr-2">
                    {panels.map((panel, index) => (
                        <div className={cn("transition-all duration-300", panel.active ? "mb-4" : "")} key={index}>
                            {panel.active && !panel.isShort && renderPanelContent(panel.id)}
                        </div>
                    ))}
                </div>
            </div>
            <div
                className={"relative select-none overflow-hidden rounded-xl bg-gray-900 flex flex-col items-start justify-center transition-all duration-300 w-full max-h-[78vh] min-h-[40em]"}
            >
                <div className="flex flex-col w-full h-full">
                    {selectedVersionHistory?.asset_type === "video" ? (
                        <video
                            src={selectedVersionHistory.permalink}
                            muted
                            controls
                            loop
                            crossOrigin="anonymous"
                            className="w-full h-full object-contain"
                        />
                    ) : pinturaEditor}
                    <div>
                        {isInpaint &&
                            <div style={{fontSize: "16px"}}
                                 className="mt-[-48px] min-h-2em pl-16 text-white italics">(hold spacebar
                                to pan)
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageEditor;
