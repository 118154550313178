import React, { useRef, useState, useMemo } from "react";
import cn from "clsx";

// react-pintura
import { PinturaEditor } from "@pqina/react-pintura";

// pintura
import "@pqina/pintura/pintura.css";
import "./oai-editor.css";
import {
  setPlugins,
  plugin_crop,
  plugin_annotate,
  PinturaImageState,
  plugin_sticker,
  ImageSource,
  PinturaDefaultImageWriterResult
  ,
} from "@pqina/pintura";
import pinturaConfig, { EditorMode, EditorTool } from "./config";
export { EditorMode, EditorTool } from "./config";
import AR_OPTIONS from "../../../lib/aspect-ratio";

setPlugins(plugin_crop /*, plugin_finetune, plugin_filter */, plugin_sticker, plugin_annotate);

type PinturaEditorProps = {
  src?: ImageSource, // should be HTMLImageElement when possible
  mode: EditorMode,
  util: EditorTool,
  aspectRatio?: number | undefined;
  onProcess?: (files: FileList) => void;
  onUpdate?: (state: PinturaImageState) => void;
  onRevert?: () => void;
};

export const usePinturaEditor = ({ src, mode = EditorMode.Editor, util = EditorTool.PanAndZoom, onProcess, onUpdate, onRevert }: PinturaEditorProps) => {
  const [loadError, setLoadError] = useState<Error | null>(null);
  const [cropAspectRatio, setCropAspectRatio] = useState<number | undefined>(undefined);
  const editorRef = useRef<PinturaEditor>(null);

  const handleEditorProcess = useMemo(() => (imageState: PinturaDefaultImageWriterResult) => {
    if (onProcess) {
      // Create a files list
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(imageState.dest);

      // Assign new files
      onProcess(dataTransfer.files);
    }
  }, [onProcess]);

  const editorElement = (
    <>
      {src && <PinturaEditor
        ref={editorRef}
        src={src}
        className={cn("oai-editor",
          `oai-editor-mode-${mode}`.toLowerCase(),
          `oai-editor-mode-${util}`.toLowerCase())}
        {...pinturaConfig(mode, util)}
        // cropSelectPresetOptions={PINTURA_AR_OPTIONS}
        imageCropAspectRatio={cropAspectRatio}
        onLoad={() => {
          const { editor } = editorRef.current || {};
          if (!editor) return;

          editor.stickerEnableButtonFlipVertical = false;
          editor.enableZoom = true;

        }}
        onLoaderror={(err: any) => {
          setLoadError(err);
          console.error(err);
        }}
        {...(onUpdate && { onUpdate })}
        {...(onRevert && { onRevert })}
        onProcess={handleEditorProcess}
      // onProcess={({ dest }: any) => setRenderedResultURL(URL.createObjectURL(dest))} // for rendered image based on current canvas
      />}
      {/* {!!renderedResultURL.length && (
        <p>
          <img src={renderedResultURL} alt="" />
        </p>
      )} */}
    </>
  );

  const reset = () => {
    const { editor } = editorRef.current || {};
    if (editor) {
      editor.history.revert();
    }
  };

  const undo = () => {
    const { editor } = editorRef.current || {};
    if (editor) {
      editor.history.undo();
    }
  };

  const setAspectRatio = (key?: string) => {
    const { editor } = editorRef.current || {};
    if (editor) {
      const ratio = AR_OPTIONS.find((ar) => ar.key === key)?.ratio || undefined;
      // editor.imageCropAspectRatio = ratio;
      setCropAspectRatio(ratio);
    }
  };

  const process = () => {
    const { editor } = editorRef.current || {};
    if (editor) {
      editor.processImage();
    }
  };

  return { undo, reset, editorElement, ref: editorRef, loadError, setAspectRatio, process };
};

