import React, { useState } from "react";
import bit_image from "../../../../common/components/ui/bit_image.png";
import Heading from "../../../../common/components/heading";
import { Button } from "../../../../common/components/ui/button";
import { useImageBoardAPI } from "../../../../api/image_board_api";
import { BoardDTO } from "../../models/image_generator";
import { DialogModal } from "../../../../common/components/ui/dialog_modal";
import { ErrorIcon } from "../../../../common/icons/icons";

export const BoardSettingsForm = ({
  onClose,
  generativeOutputId,
  board,
  mode = "create",
}: {
  onClose: (board?: BoardDTO) => void;
  generativeOutputId?: string; // the first image to add to the board
  board?: BoardDTO;
  mode?: "create" | "edit";
}) => {
  const [name, setName] = useState(board?.name || "");
  const [description, setDescription] = useState(board?.description || "");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const { saveNewBoard, updateBoard, saveImageToBoard } = useImageBoardAPI();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (name.length === 0) {
      setError("Board name is required");
      return;
    }

    setIsSubmitting(true);

    try {
      let newBoard: BoardDTO;
      if (mode === "create") {
        newBoard = await saveNewBoard({
          name,
          description,
          is_default: false,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        });
        if (!newBoard?.id) throw new Error("Board not created");
        if (generativeOutputId) {
          await saveImageToBoard(newBoard.id, generativeOutputId);
        }
      } else if (board?.id) {
        newBoard = await updateBoard(board.id, {
          ...board,
          name,
          description,
          updated_at: new Date().toISOString(),
        });
      } else {
        throw new Error("Board ID is required for edit mode");
      }
      onClose(newBoard);
    } catch (error) {
      console.error("Error saving board:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col w-full lg:flex-row justify-center items-center gap-0 md:gap-4 lg:gap-8 p-2 md:p-8">
      <Heading id="mobile-heading" as="h3" className="sm:hidden">{mode === "create" ? "Create image board" : "Edit board"}</Heading>
      <img className="rounded-lg w-[50%] lg:w-[40%] h-[120px] lg:h-auto lg:max-h-[100%] object-contain" src={bit_image} />
      <form onSubmit={handleSubmit} className="flex flex-col p-2 md:p-8 gap-4 md:gap-8 w-full lg:min-w-[50%]">
        <Heading id="desktop-heading" as="h3" className="hidden sm:block">{mode === "create" ? "Create image board" : "Edit board"}</Heading>
        <p>Create a new board to organize your images.</p>
            <div className="flex flex-col">
              <label htmlFor="name" className="italic font-extralight">Name of board</label>
              <input
                id="name"
                className={`border-0 border-b-2 ${
                  error ? "border-brand-error" : "border-blackish"
                } pt-2 focus:ring-0 outline-none`}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder=""
                aria-required="true"
              />
              {error && (
                <div className="text-brand-error text-sm flex flex-row items-center gap-2">
                  <ErrorIcon />
                  {error}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="description" className="italic font-extralight">Board description (optional)</label>
              <textarea
                rows={4}
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="bg-gray-200 resize-none rounded-xl p-3 focus:ring-0 outline-none focus:shadow-none"
                placeholder="description..."
              />
            </div>
          <div className="flex gap-2">
            <Button id="cancel-button" type="button" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              id="save-button"
              variant="primary-negative"
              className="flex-grow"
              loading={isSubmitting}
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? "Saving..." : mode === "create" ? "Create board" : "Save changes"}
            </Button>
          </div>
      </form>
    </div>
  );
};

const BoardSettingsDialog = ({
  isOpen,
  onOpenChange,
  onClose,
  generativeOutputId,
  board,
  mode = "create",
}: {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onClose: (board?: BoardDTO) => void;
  generativeOutputId?: string; // the first image to add to the board
  board?: BoardDTO;
  mode?: "create" | "edit";
}) => {
  const children = <BoardSettingsForm onClose={onClose} generativeOutputId={generativeOutputId} board={board} mode={mode} />
  return (
    <DialogModal onOpenChange={onOpenChange} onClose={onClose} isOpen={isOpen}>
      {children}
    </DialogModal>
  );
};

export default BoardSettingsDialog;
