import React, { useState, useEffect } from "react";
import "./components/image_details.css";
import ImageGenerator from "./image_generator";
import LoadingPage from "./loading_page";
import { useGenerativeAPI } from "../../api/generative_ai_api";
import { GenerativeModel } from "./models/image_generator";
import { Button } from "../../common/components/ui/button";
import { RightNoFill } from "../../common/icons/icons";
import { useToast } from "../../common/components/ui/use_toast";

const CreateImage = () => {
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [progress, setProgress] = useState({ percent_complete: 0, progress_message: "Generating image..." });
  const [suggestedPrompts, setSuggestedPrompts] = useState<string[]>([]);
  const [selectedPrompt, setSelectedPrompt] = useState<string>("");
  const [modelList, setModelList] = useState<GenerativeModel[]>([]);
  const { toast } = useToast();

  const { getModels } = useGenerativeAPI();

  useEffect(() => {
    const fetchModelList = async () => {
      try {
        const modelTypes = ["USER_PRODUCT", "BRAND", "LICENSABLE_PROPERTY"] as const;
        const responses = await Promise.all(modelTypes.map((type) => getModels(type)));

        const combinedModels = responses.flatMap((response, index) =>
          response.map((model) => ({
            ...model,
            model_type: modelTypes[index].toLowerCase(),
          }))
        );

        setModelList(combinedModels);
      } catch (error) {
        console.error("Error fetching models", error);
      }
    };

    fetchModelList();
  }, []);

  useEffect(() => {
    if (!modelList.length) return;
    const modelsByType = modelList.reduce<Record<string, any[]>>((acc, model) => {
      if (!acc[model.model_type]) {
        acc[model.model_type] = [];
      }
      acc[model.model_type].push(model);
      return acc;
    }, {});

    const newPrompts = [];

    const prompts: { [key: string]: string[] } = {
      licensable_property: [
        "Professional headshot of [vault] against a neutral background, warm lighting",
        "[Vault] in casual outdoor portrait with natural light and soft smile",
        "[Vault] speaking at podium during conference presentation",
        "[Vault] walking down city street wearing business attire",
        "[Vault] in modern office setting, engaged in conversation",
      ],
      user_product: [
        "Showcase [vault] with dramatic lighting on black background",
        "Lifestyle scene featuring [vault] on desk in modern home office",
        "Close-up of [vault] highlighting texture and craftsmanship",
        "Flatlay composition with [vault] and lifestyle accessories on white surface",
        "[Vault] in use outdoors during golden hour lighting",
      ],
    };

    while (newPrompts.length < 3 && Object.keys(modelsByType).length > 0) {
      const availableTypes = Object.keys(modelsByType);
      const randomType = availableTypes[Math.floor(Math.random() * availableTypes.length)];

      const vaultsForType = modelsByType[randomType];
      const randomVault = vaultsForType[Math.floor(Math.random() * vaultsForType.length)];

      const promptsForType = prompts[randomType] || [];
      const randomPromptTemplate = promptsForType[Math.floor(Math.random() * promptsForType.length)];

      const finalPrompt = randomPromptTemplate.replace(/\[vault\]/gi, `#${randomVault.generative_tag}`);

      newPrompts.push(finalPrompt);
    }

    setSuggestedPrompts(newPrompts);
  }, [modelList]);

  const handleClearSelectedPrompt = () => {
    setSelectedPrompt("");
  };

  const showToast = (message: string) => {
    toast({
      title: message,
      variant: "default"
    });
  };

  return (
    <div>
      {isLoadingPage ? (
        <LoadingPage percentComplete={progress.percent_complete} progressMessage={progress.progress_message} />
      ) : (
        <div className="flex justify-center text-white px-8 pt-[120px] pb-24 space-y-6">
          <div className="w-full max-w-[738px]">
            <h1 className="text-5xl mb-4">Create an image</h1>
            <p className="font-body w-full">
              Start by selecting a vault to work with and then enter a description of the image you would like to
              create.
            </p>
            <ImageGenerator
              modelList={modelList}
              selectedPrompt={selectedPrompt}
              clearSelectedPrompt={handleClearSelectedPrompt}
              showToast={showToast}
              setIsLoadingPage={setIsLoadingPage}
              progress={progress}
              setProgress={setProgress}
            />
            <div className="w-full mt-6">
              <p>Get started with these suggestions</p>
              <div className="flex flex-col gap-2.5 py-6">
                {suggestedPrompts.map((prompt, index) => (
                  <div
                    key={index}
                    className="border px-6 py-3 flex flex-row gap-3 border-gray-700 rounded-[70px] w-full justify-between"
                  >
                    <p className="font-body">{prompt}</p>
                    <Button onClick={() => setSelectedPrompt(prompt)} className="fill-black px-3">
                      <RightNoFill />
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateImage;
