import React, { useEffect, useState, useRef } from "react";
import cachedImageElement from "../../lib/cached-image-element";
import ImageDisplay from "./components/images-display";
import { useNavigate, useParams } from "react-router-dom";
import { useUserProductAPI } from "../../api/user_product_api";
import UploadCard from "./components/upload-card";
import { pluralize } from "../../lib/utils";
import ActionFooter from "../../common/components/ui/action-footer";
import { useFileAPI } from "../../api/file_api";
import DeleteModal from "../../common/components/ui/delete-modal";
import { DialogModal } from "../../common/components/ui/dialog_modal";
import { ThemeProvider } from "../../contexts/theme_context";
import { useToast } from "../../common/components/ui/use_toast";
import { Button } from "../../common/components/ui/button";
import { BackIcon } from "../../common/icons/icons";

const withCachedImage = (file: any /* TrainingSetFile & File */) => ({
  ...file,
  image: file.image || cachedImageElement(file.permalink || file.file_permalink || ""),
});

const SmartAssetEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { fetchFilesByUsage } = useUserProductAPI();
  const [smartAssets, setSmartAssets] = useState<any[]>([]);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [uploading, setUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [statusPercent, setStatusPercent] = useState<number>(0);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { uploadFile, deleteFile } = useFileAPI();
  const { toast } = useToast();

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchFilesByUsage({ id: id, usage: "inpaint_asset" }).then((files) => {
        setSmartAssets(files);
      });
    }
  }, [id]);

  const handleSourceDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      setUploading(true);
      try {
        const uploadedFiles = Array.from(files).map((file) => uploadSmartAsset(file));
        await Promise.all(uploadedFiles);
      } catch (error) {
        console.error("Error uploading files:", error);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setUploading(true);
      try {
        const uploadedFiles = Array.from(files).map((file) => uploadSmartAsset(file));
        await Promise.all(uploadedFiles);
      } catch (error) {
        console.error("Error uploading files:", error);
      } finally {
        setUploading(false);
      }
    }

    e.target.value = "";
  };

  const uploadSmartAsset = async (file: any) => {
    if (!file) return;

    try {
      const uploadRequest = {
        files: [file],
        is_public: true,
        object_id: id,
        object_type: "USER_PRODUCT",
        generate_thumbnail: true,
        resize: "medium",
        usage: "inpaint_asset",
      };

      const uploadedFiles = await uploadFile(uploadRequest, (progress) => {
        const prog = progress?.progress || 0;
        setStatusPercent(prog * 100);
      });

      if (uploadedFiles) {
        const withImageElement = uploadedFiles.map(withCachedImage);
        setSmartAssets((prevFiles) => [...withImageElement, ...(prevFiles || [])]);
      }
    } catch {
      ("Error uploading file");
    }
  };

  const browse = () => {
    fileInputRef.current?.click();
  };

  const handleDelete = async () => {
    try {
      await deleteFile(selectedFile.id);
      setShowModal(false);
      setSmartAssets((prevFiles) => prevFiles.filter((file) => file.id !== selectedFile.id));

      toast({
        title: "Smart asset deleted",
        variant: "default",
      });
    } catch (error) {
      console.error("Error deleting file:");
    }
  };

  return (
    <div
      className="page_content px-4 sm:px-8 lg:px-16 pt-[20px] pb-10 flex flex-col overflow-auto"
      style={{ height: "calc(100vh - 160px)" }}
    >
      <DialogModal
        isOpen={showModal}
        onOpenChange={() => setShowModal(false)}
        variant="default"
        customClass
        removeFooter
        onClose={() => {
          setShowModal(false);
        }}
      >
        <ThemeProvider defaultTheme="light">
          <DeleteModal modalType={"image"} closeModal={() => setShowModal(false)} handleDelete={handleDelete} />
        </ThemeProvider>
      </DialogModal>

      <Button
        className="gap-2 px-6 py-3 my-6  fill-white hover:fill-black self-start"
        variant="outline"
        onClick={() => navigate(-1)}
      >
        <>
          <BackIcon />
          Back
        </>
      </Button>
      <h1 className="text-white text-3xl font-serif">Your smart assets</h1>
      <div className="my-6 sm:my-8 flex flex-row justify-between">
        <p className="font-sans not-italic semibold text-white">Uploaded images</p>
        <span className="text-brand-gray-500 font-regular font-body">
          {`${smartAssets?.length || 0} ${pluralize("image", smartAssets?.length || 0)}`}
        </span>
      </div>
      <div onDragOver={(e) => e.preventDefault()} onDragLeave={(e) => e.preventDefault()} onDrop={handleSourceDrop}>
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          className="hidden"
          onChange={handleFileSelect}
          multiple
        />
        {smartAssets && smartAssets.length > 0 ? (
          <ImageDisplay
            addUpload
            browse={browse}
            title={""}
            route={""}
            images={smartAssets}
            handleDelete={(file) => {
              setShowModal(true);
              setSelectedFile(file);
            }}
          />
        ) : (
          <div className="bg-gray-900 rounded-[20px] w-[90vw] h-[60vh]">
            <UploadCard onBrowse={browse} />
          </div>
        )}
      </div>
      <ActionFooter
        primaryAction={{
          label: "Save",
          onClick: () => {
            navigate(-1);
          },
          loading: uploading,
          loadingText: "Uploading Image",
          disabled: uploading,
        }}
        onCancel={() => navigate(-1)}
      />
    </div>
  );
};

export default SmartAssetEdit;
