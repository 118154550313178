import { useMergeRefs } from "@chakra-ui/react-use-merge-refs";
/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useLayoutEffect, useRef, useState } from "react";

import { cn } from "../../../lib/utils";

export const MIN_HEIGHT_IMAGE_PROMPT = 80;

export type TextareaProps = {
    variant?: "default" | "image_prompt" | "original"; // "original" is prior to refactor for image_prompt
    className?: string;
    autoSizeMinHeight?: number; // auto size the textarea, but start with this minimum height
    insetControlHeight?: number; // for auto sizing
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const DEFAULT_STYLE = "w-full resize-none disabled:cursor-not-allowed disabled:opacity-50 transition duration-200 ease-in-out placeholder:text-gray-500";
const ORIGINAL_STYLE =
    "flex min-h-[80px] rounded-md border border-input bg-background px-3 py-2 ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2";
const IMAGE_PROMPT =
    "bg-gray-900 rounded-lg p-4 text-sm-sans font-normal text-white border-none outline-none w-full resize-none focus-visible:ring-0 focus-visible:shadow-none";


const IMAGE_PROMPT_PLACEHOLDER = "Enter image prompt here" + String.fromCodePoint(0x2026);

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
    ({ className, variant = "original", autoSizeMinHeight, insetControlHeight = 0, value, onChange, placeholder = IMAGE_PROMPT_PLACEHOLDER, ...props }, ref) => {
        const localRef = useRef<HTMLTextAreaElement>(null);
        const mergedRefs = useMergeRefs(ref, localRef);
        const minAutoHeight = (autoSizeMinHeight || 0) + insetControlHeight;
        const handleResize = () => {
            if (localRef.current) {
                if (!value) {
                    localRef.current.style.height = autoSizeMinHeight ? `${autoSizeMinHeight}px` : "auto";
                    localRef.current.style.height = `${minAutoHeight}px`;
                } else {
                    localRef.current.style.height = autoSizeMinHeight ? `${autoSizeMinHeight}px` : "auto";
                    localRef.current.style.height = `${Math.max(localRef.current.scrollHeight + insetControlHeight, minAutoHeight)}px`;
                }
            }
        };

        useEffect(() => {
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }, [value, minAutoHeight, insetControlHeight]);

        const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            // Auto-resize height
            handleResize();
            // Call the original onChange if provided
            onChange?.(e);
        };

        useLayoutEffect(() => {
            handleResize();
        }, [localRef.current, value]);

        return (
            <textarea
                className={cn(
                    DEFAULT_STYLE,
                    variant === "original" ? ORIGINAL_STYLE : "",
                    variant === "image_prompt" ? IMAGE_PROMPT : "",
                    className,
                    (autoSizeMinHeight && !value) ? `min-h-[${minAutoHeight}px]` : "",
                )}
                ref={mergedRefs}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                {...props}
            />
        );
    }
);

Textarea.displayName = "Textarea";

export { Textarea };
