import React, {useEffect, useRef, useState} from "react";

import {Button} from "../../../common/components/ui/button";
import loading from "../../../common/components/ui/loading.gif";
// import { SelectedGenIcon, SliderLeft, SliderRight } from "/model/file";
import {SelectedGenIcon, SliderLeft, SliderRight} from "../../../common/icons/icons";
import {VersionHistoryWithMedia} from "../models/image_generator";
import VideoThumbnail from "./video_thumbnail";

const cardStyle = (isSelected: boolean) => {
  return `bg-gray-400 w-[116px] object-cover h-36 rounded-lg ${isSelected ? "border-4 border-brand-yellow" : ""}`;
};

const VersionHistorySlider = ({
                                versionHistory,
                                selectedVariant,
                                setSelectedVariant,
                                showLoadingImage,
                                statusResponse
}: {
  versionHistory: VersionHistoryWithMedia[];
  selectedVariant?: VersionHistoryWithMedia | null;
  setSelectedVariant: (variant: VersionHistoryWithMedia) => void;
  showLoadingImage: boolean;
  statusResponse?: Array<{
    percent_complete: number;
    progress_message: string;
  }>;
}) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const sliderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showLoadingImage && sliderRef.current) {
      sliderRef.current.scrollTo({
        left: sliderRef.current.scrollWidth,
        behavior: "smooth"
      });
    }
  }, [showLoadingImage]);

  useEffect(() => {
    const checkOverflow = () => {
      if (sliderRef.current) {
        const clientWidth = sliderRef.current.clientWidth;
        const scrollWidth = sliderRef.current.scrollWidth;

        const overflow = scrollWidth > clientWidth;
        setIsOverflow(overflow);

        if (sliderRef.current) {
          setCanScrollLeft(sliderRef.current.scrollLeft > 0);
          setCanScrollRight(sliderRef.current.scrollLeft + clientWidth < scrollWidth);
        }
      }
    };

    checkOverflow();

    window.addEventListener("resize", checkOverflow);

    const currentRef = sliderRef.current;
    const handleScroll = () => checkOverflow();
    currentRef?.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", checkOverflow);
      currentRef?.removeEventListener("scroll", handleScroll);
    };
  }, [versionHistory]);

  const scroll = (direction: "left" | "right") => {
    if (sliderRef.current) {
      const scrollAmount = sliderRef.current.clientWidth * 0.8;
      sliderRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const getLatestProgress = () => {
    if (!statusResponse || statusResponse.length === 0) return null;
    const latestStatus = statusResponse[statusResponse.length - 1];
    return `${latestStatus.percent_complete}%`;
  };

  return (
      <div className="flex flex-row gap-3">
        <div
            className="flex flex-row gap-3 w-11/12 overflow-x-auto"
            ref={sliderRef}
            style={{
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
        >
          {versionHistory.map((variation, index) => (
              <div key={index} className="relative shrink-0">
                {variation.asset_type === "video" ? (
                    <VideoThumbnail
                        variation={variation}
                        isSelected={variation === selectedVariant}
                        onSelect={() => setSelectedVariant(variation)}
                        cardStyle={cardStyle}
                        loop
                    />
                ) : (
                    <img
                        onClick={() => {
                          setSelectedVariant(variation);
                        }}
                        className={cardStyle(variation === selectedVariant)}
                        crossOrigin="anonymous"
                        src={variation.permalink}
                    />
                )}
                {variation === selectedVariant && (
                    <div className="absolute top-2 right-2">
                      <SelectedGenIcon/>
                    </div>
                )}
              </div>
          ))}
          {showLoadingImage && (
              <div className="w-[116px] bg-brand-yellow rounded-lg flex flex-col justify-center items-center shrink-0">
                <img
                    crossOrigin="anonymous"
                    className="h-10"
                    src={loading}
                    alt="Loading..."
                />
                <span className="text-blackish text-sm mt-2">{getLatestProgress() || "Generating.."}</span>
              </div>
          )}
        </div>

        {isOverflow && versionHistory.length > 1 && (
            <div className="flex flex-col gap-3 justify-center">
              <Button
                  variant="outline"
                  disabled={!canScrollRight}
                  className=" px-3 stroke-white border-brand-gray-500 hover:bg-transparent hover:border-white"
                  onClick={() => scroll("right")}
              >
                <SliderRight/>
              </Button>
              <Button
                  variant="outline"
                  disabled={!canScrollLeft}
                  className="px-3 stroke-white border-brand-gray-500 hover:bg-transparent hover:border-white"
                  onClick={() => scroll("left")}
              >
                <SliderLeft/>
              </Button>
            </div>
        )}
      </div>
  );
};

export default VersionHistorySlider;
