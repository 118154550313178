import React from "react";
import {Panel, PanelId} from "../models/image_generator";
import {
    CopyIcon,
    DownloadIcon,
    DragAndDropIcon,
    EditAspectRatioIcon,
    EditDirectlyIcon,
    EditPromptIcon,
    Heart,
    HistoryIcon,
    NaturalTextureIcon,
    SaveIcon,
    UpscaleIcon,
    Video,
} from "../../../common/icons/icons";


export const PANELS: Omit<Panel, "active" | "component" | "isExpanded">[] = [
    {
        id: PanelId.Description,
        name: "Description",
        icon: <EditPromptIcon />,
        description: "Description",
        details: "Fine-tune the text prompt that created this image. For major changes, we recommend generating a new image from scratch."
    },
    {
        id: PanelId.Versions,
        name: "Versions",
        icon: <CopyIcon />,
        description: "Versions",
        details: "Browse different versions of this image to find the perfect match for your needs."
    },
    {
        id: PanelId.EditDirectly,
        name: "Brush",
        icon: <EditDirectlyIcon />,
        description: "Brush",
        details: "Need to tweak something specific? Use our smart tools to adjust particular areas of your image, like swapping backgrounds or modifying objects."
    },
    {
        id: PanelId.DragAndDrop,
        name: "Drag and Drop",
        icon: <DragAndDropIcon />,
        description: "Drag and Drop",
        details: "Drag and drop an asset into the image. Adjust the asset size and orientation using the handles. Press shift while adjusting the image to retain the proportions.",
        addIconStroke: true
    },
  /*   {
        id: PanelId.Zoom,
        name: "Zoom",
        isShort: true,
        icon: <SearchIcon />,
        description: "Zoom",
        details: "Get up close and personal with your image details or zoom out for the big picture."
    },
    {
        id: PanelId.Drag,
        name: "Pan",
        isShort: true,
        icon: <DragIcon />,
        description: "Pan",
        details: "Click and drag to move around the image, especially useful when zoomed in for detailed work."
    },
*/    {
        id: PanelId.AspectRatio,
        name: "Aspect Ratio",
        icon: <EditAspectRatioIcon />,
        description: "Aspect Ratio",
        details: "Quick resize for different platforms - from Instagram squares to Facebook covers. Includes handy presets for all major formats."
    },
    {
        id: PanelId.Upscale,
        name: "Enhance Resolution",
        isShort: true,
        icon: <UpscaleIcon />,
        description: "Enhance Resolution",
        details: "Make your image bigger and sharper - perfect for those times when you need high-resolution output."
    },
    {
        id: PanelId.NaturalLook,
        name: "Natural Look",
        isShort: true,
        icon: <NaturalTextureIcon />,
        description: "Natural Look",
        details: "Gently enhances skin texture for a more natural look. It's great for portraits where the skin appears overly smooth or artificial.",
    },
    {
        id: PanelId.Video,
        name: "Animate",
        icon: <Video/>,
        description: "Animate Image",
        details: "Turn your image into a short video.  You are the director!  Describe what you want to see in the video and we will create it for you. May take up to 10 minutes to complete."
    },
    {
        id: "Actions",
        name: "Actions",
        description: "Actions",
        isDivider: true
    },
    {
        id: PanelId.History,
        name: "History",
        icon: <HistoryIcon />,
        description: "History",
        details: "See every step of your creative journey - from initial generation to final touches. Like having an 'undo' superpower!",
        addIconStroke: true
    },
    {
        id: PanelId.Save,
        name: "Save",
        isShort: true,
        icon: <SaveIcon />,
        description: "Save",
        details: "Store this version in your project board with all its history and info intact.",
        addIconStroke: true
    },
    {
        id: PanelId.FavoriteImage,
        name: "Favorite",
        isShort: true,
        icon: <Heart />,
        description: "Favorite",
        details: "Save this version to your inspiration board for easy access later.",
        addIconStroke: true
    },
    {
        id: PanelId.DownloadImage,
        name: "Download",
        isShort: true,
        icon: <DownloadIcon />,
        description: "Download",
        details: "Save this image to your local device with all rights and authenticity certificates included."
    },
    /* {
        id: PanelId.Feedback,
        name: "Feedback",
        isShort: true,
        icon: <ThumbsUp />,
        description: "Feedback",
        details: "Help us make the magic even better! Rate image quality or let us know if something's not quite right."
    },*/

];
