import React from "react";
import { TrainingSetFile } from "../../../model/vault";
import { Pencil, Trash } from "../../../common/icons/icons";
import { Button } from "../../../common/components/ui/button";


const TrainingFileCard: React.FC<{
  file: TrainingSetFile;
  onDelete: () => void;
  onEdit: () => void;
}> = ({ file, onDelete, onEdit }) => {
  return (
    <div
      className="flex justify-between items-center border-b border-brand-gray-500 last:border-b-0 w-full text-white gap-4 h-20">
      <div className="w-20">
        <img src={file.file_thumbnail} alt={file.description} crossOrigin="anonymous" className="max-w-20 max-h-20 rounded-lg" />
      </div>

      <div id="description" className="flex-1 line-clamp-3 font-body">
        {file.description || "No description"}
      </div>
      <div>
        <Button type={"button"} variant="outline" className="p-3 self-start border-brand-gray-500 hover:bg-transparent hover:border-white" onClick={onEdit}>
          <Pencil />
        </Button>
      </div>
      <button onClick={onDelete} type={"button"}>
        <Trash />
      </button>

    </div>
  );
};

export default TrainingFileCard;
