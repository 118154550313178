import React from "react";
import {BoardDTO} from "../models/image_generator";
import VideoThumbnail from "./video_thumbnail";

const BoardGrid = ({ board, handleBoardSelect }: { board: BoardDTO; handleBoardSelect: (board: BoardDTO) => void }) => {
    const gridItems = Array(4).fill(null);
    const displayOutputs = board.generative_outputs?.length === 4 ? 4 : 3;
    if (board.generative_outputs) {
        board.generative_outputs.slice(0, displayOutputs).forEach((output, index) => {
            gridItems[index] = output;
        });
    }

    return (
        <div
            onClick={() => handleBoardSelect(board)}
            className="bg-gray-900 h-[347px] w-[280px] cursor-pointer rounded-[20px] mb-3 flex flex-col justify-center"
        >
            <div className="grid grid-cols-2 gap-2 mx-6">
                {gridItems.map((output, index) => (
                    <div key={index} className="h-36 w-28">
                        {output ? (
                            output.asset_type === "video" ? (
                                <VideoThumbnail
                                    variation={output}
                                    isSelected={false}
                                    cardStyle={() => "rounded-[8.571px] h-full w-full object-cover"}
                                    hideControls
                                    loop
                                />
                            ) : (
                                <img
                                    crossOrigin="anonymous"
                                    className="rounded-[8.571px] h-full w-full object-cover"
                                    src={output.permalink}
                                    alt={`Output ${index + 1}`}
                                />
                            )
                        ) : (
                            <div
                                className="bg-gray-700 flex justify-center h-full w-full items-center rounded-[8.571px]">
                                {index === 3 && board.generative_outputs && board.generative_outputs.length > 4 && (
                                    <span>+{board.generative_outputs.length - 3}</span>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BoardGrid;
