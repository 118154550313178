import React, { useCallback, useState, useEffect } from "react";
import Panel from "./panel";
import { Button } from "../../../../common/components/ui/button";
import { useGenerativeAPI } from "../../../../api/generative_ai_api";
import VaultDropdown from "../vault_dropdown";
import { GenerativeModel, PanelId } from "../../models/image_generator";
import { Popover, PopoverContent, PopoverTrigger } from "../../../../common/components/ui/popover";
import { InfoIcon } from "../../../../common/icons/icons";
import { Textarea, MIN_HEIGHT_IMAGE_PROMPT } from "../../../../common/components/ui/textarea";
import { PANELS } from "../panelConfig";
import {
    PinturaImageState,
} from "@pqina/pintura";
import {generateMask} from "../../pintura/generate-mask-inpaint";
import RewriteButton from "../../../../common/components/rewrite_button";

const title = PANELS.find((panel) => panel.id === PanelId.EditDirectly)?.description || "Brush";

const EditDirectlyPanel = ({
    modelList,
    generateInpaint,
    expanded,
    handleToggleExpand,
    pinturaImageState,
    closePanel,
    helperText,
    naturalWidth,
    naturalHeight,
}: {
    modelList: GenerativeModel[],
    generateInpaint: (prompt: string, modelWeights: { id: string; weight: number; }[], mask: string | null, inpaintFileId?: string | null) => void,
    closePanel?: () => void,
    expanded: boolean | undefined,
    handleToggleExpand: () => void,
    helperText?: string;
    pinturaImageState?: PinturaImageState;
    naturalWidth: number;
    naturalHeight: number;
}) => {
    const [selectedModel, setSelectedModel] = useState<GenerativeModel | null>(null);
    const [inpaintPrompt, setInpaintPrompt] = useState<string>("");
    const [isPromptLoading, setIsPromptLoading] = useState(false);

    const { enhancePrompt } = useGenerativeAPI();

    const handleSelectModel = (model: any) => {
        setSelectedModel(model);
        const regex = /#\w+/g;
        setInpaintPrompt((prevPrompt) => {
            const promptWithoutOldTag = prevPrompt.replace(regex, "").trim();

            const newGenerativeTag = `#${model.generative_tag}`;
            const updatedPrompt = `${newGenerativeTag} ${promptWithoutOldTag}`.trim();

            return updatedPrompt;
        });
    };


    useEffect(() => {
        const tagMatch = inpaintPrompt.match(/#(\w+)/);
        if (!tagMatch) {
            setSelectedModel(null);
        }
        if (tagMatch) {
            const generativeTag = tagMatch[1];
            const matchingModel = modelList.find(
                model => model.generative_tag === generativeTag
            );

            if (matchingModel) {
                setSelectedModel(matchingModel);
            }
        }
    }, [inpaintPrompt, modelList]);


    const triggerGenerateInpaint = useCallback(() => {
        if (pinturaImageState) {
            const modelWeights = [];
            if (selectedModel?.entity_type === "LICENSABLE_PROPERTY") {
                modelWeights.push({ id: selectedModel.id, weight: 0.8 });
            }
            if (selectedModel?.entity_type === "USER_PRODUCT") {
                modelWeights.push({ id: selectedModel.id, weight: (modelWeights.length === 0 ? 0.8 : 0.2) });
            }
            const mask = generateMask(pinturaImageState, naturalWidth, naturalHeight);

            if (mask) {
                generateInpaint(
                    inpaintPrompt,
                    modelWeights,
                    mask,
                );
            }
        }

    }, [selectedModel, inpaintPrompt, pinturaImageState]);

    const enhanceTextPrompt = async () => {
        setIsPromptLoading(true);
        const response = await enhancePrompt(inpaintPrompt, selectedModel ? [selectedModel.id] : []);
        if (response && response.enhanced) {
            setInpaintPrompt(response.enhanced);
        }
        setIsPromptLoading(false);
    };

    return (
        <Panel toggleExpanded={handleToggleExpand} helperText={helperText} isExpanded={expanded} title={title} closePanel={closePanel}>
            <div className="space-y-6">
                <div>
                    <p className="">Vault</p>
                    <VaultDropdown modelData={modelList} selectedModelId={selectedModel?.id} onSelect={handleSelectModel} />
                </div>
                    <div className="flex flex-col gap-3">
                        <div className="flex gap-2">
                            <p className="">Smart Touch-Up description</p>
                            <Popover>
                                <PopoverTrigger>
                                    <InfoIcon />
                                </PopoverTrigger>
                                <PopoverContent className="text-base bg-brand-yellow border-none">
                                    Any editing you do to this image will only change the inpainted area
                                </PopoverContent>
                            </Popover>
                        </div>
                        <div className="relative">
                            <Textarea
                                variant="image_prompt"
                                className="bg-gray-700"
                                autoSizeMinHeight={MIN_HEIGHT_IMAGE_PROMPT}
                                insetControlHeight={48}
                                value={inpaintPrompt} onChange={(e) => setInpaintPrompt(e.target.value)}
                            />
                            <div className="absolute bottom-5 right-5 z-10">
                                <RewriteButton onClick={enhanceTextPrompt} isLoading={isPromptLoading} />
                            </div>
                        </div>
                    </div>
                <Button onClick={triggerGenerateInpaint} className="w-full border-none" variant="primary">Generate inpainting</Button>


                {/* <Button onClick={() => showInpaint(mask)}>Show inpaint</Button> */}
            </div>
        </Panel>
    );
};

export default EditDirectlyPanel;