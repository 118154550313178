import React from "react";
import bit_image from "../../../common/components/ui/bit_image.png";
import { Button } from "./button";

interface DeleteModalProps {
  modalType: "board" | "image" | "vault";
  closeModal: () => void;
  handleDelete: () => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({ modalType, closeModal, handleDelete }) => {
  return (
    <div>
      <img className="rounded-lg" src={bit_image} />
      <div className="flex flex-col p-4 space-y-7">
        <h1 className="text-2xl">{`Are you sure you want to delete this ${modalType}?`}</h1>
        <div className="flex flex-row gap-4">
          <Button
            className="self-start w-auto text-blackish hover:bg-transparent"
            variant="outline"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button className="self-start w-auto" variant="primary-negative" onClick={handleDelete}>
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
