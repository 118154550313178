import React, { useEffect, useRef, useState } from "react";
import { useGenerativeAPI } from "../../api/generative_ai_api";
import { useImageBoardAPI } from "../../api/image_board_api";
import { BoardDTO, GenerativeOutput } from "./models/image_generator";
import "./components/image_details.css";
import Heading from "../../common/components/heading";
import { DialogModal } from "../../common/components/ui/dialog_modal";
import { Button } from "../../common/components/ui/button";
import { BackIcon, DownCarret, FavoriteIcon, PencilIcon, TrashIcon } from "../../common/icons/icons";
import { useNavigate } from "react-router-dom";
import { calculateAspectRatioLabel } from "./models/constants";
import VideoThumbnail from "./components/video_thumbnail";
import BoardSettingsDialog from "./components/modal-components/board_settings_dialog";
import { pluralize } from "../../lib/utils";

const headingFontSize = (name: string) => {
  if (name.length > 15) {
    return "text-lg md:text-2xl lg:text-3xl xl:text-4xl";
  }

  return "text-2xl md:text-3xl lg:text-4xl";
};

const ImageBoard = ({
  board,
  boardList,
  favoritedIds,
  addToFavorite,
  removeFromBoard,
  moveImageToNewBoard,
  handleBack,
  updateBoard,
  handleDeleteBoard,
}: {
  board: BoardDTO;
  boardList: BoardDTO[];
  favoritedIds: string[];
  moveImageToNewBoard: (destinationBoardId: string, generative_output_id: string, sourceBoardId: string) => void;
  removeFromBoard: (boardId: string, generative_output_id: string) => void;
  addToFavorite: (generativeOutputId: string) => void;
  handleBack: () => void;
  updateBoard: (boardId: string, updatedFields: Partial<BoardDTO>) => void;
  handleDeleteBoard: (boardId: string) => void;
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState<"imageDetails" | "editBoard">("imageDetails");
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [selectedBoard, setSelectedBoard] = useState<BoardDTO>(board);
  const [selectedImage, setSelectedImage] = useState<any | null>(null);
  const [localFavorites, setLocalFavorites] = useState<string[]>(favoritedIds);

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const { getGenerativeOutput, deleteImage } = useGenerativeAPI();
  const { removeImageFromBoard: removeImageFromBoardAPI } = useImageBoardAPI();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDisplayDropdown(false);
      }
    };

    if (displayDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [displayDropdown]);

  // This is to delete actual image
  const deleteMyImage = (taskQueueId: string) => async () => {
    try {
      await deleteImage(taskQueueId);
      setOpenModal(false);
      setSelectedImage(null);
    } catch (error) {
      console.error("Error deleting image", error);
    }
  };

  const removeImageFromBoard = () => {
    const imageId = selectedImage.generative_output_id;
    removeImageFromBoardAPI(board.id || "", imageId);
    setOpenModal(false);
  };

  const openImageDetails = async (image: GenerativeOutput) => {
    if (!image.task_queue_id) return;

    const response = await getGenerativeOutput(image.task_queue_id);
    const aspectRatioLabel = calculateAspectRatioLabel(response.width, response.height);
    // TODO: if the image is a child, we may not have this data
    const updatedImage = {
      ...image,
      prompt: response.prompt,
      aspect_ratio_label: aspectRatioLabel,
    };

    setSelectedImage(updatedImage);
    setModalType("imageDetails");
    setOpenModal(true);
  };

  const openEditModal = () => {
    setModalType("editBoard");
    setOpenModal(true);
  };

  const handleFavoriteClick = (e: React.MouseEvent, boardId: string, generativeOutput: GenerativeOutput) => {
    const generativeOutputId = generativeOutput.generative_output_id;
    e.stopPropagation();
    const isFavorited = localFavorites.includes(generativeOutputId);

    setLocalFavorites((prev) =>
      isFavorited ? prev.filter((id) => id !== generativeOutputId) : [...prev, generativeOutputId]
    );

    addToFavorite(generativeOutputId);
  };

  return (
    <div className="page_content">
      <DialogModal
        isOpen={openModal && modalType === "imageDetails"}
        onOpenChange={setOpenModal}
        onClose={() => {
          setOpenModal(false);
          setSelectedImage(null);
          setSelectedBoard(board);
        }}
        variant="large"
        removeFooter
        className="md:min-w-[960px]"
      >
        {modalType === "imageDetails" && selectedImage && (
          <div className="flex flex-col md:flex-row md:gap-4 lg:gap-8 xl:gap-16 p-0 md:p-5">
            <Heading id="mobile-heading" as="h3" className="md:hidden">
              Image details
            </Heading>
            {selectedImage.asset_type === "video" ? (
              <div className="w-[90vw] md:w-[452px] h-[577px] rounded-lg overflow-hidden">
                <VideoThumbnail
                  variation={selectedImage}
                  isSelected={false}
                  cardStyle={() => "w-full h-full object-cover"}
                  loop
                  cornerOverlay
                />
              </div>
            ) : (
              <img
                crossOrigin="anonymous"
                className="w-[90vw] h-[50vh] md:w-[452px] md:h-[577px] object-contain md:object-cover rounded-lg"
                src={selectedImage.permalink}
              />
            )}
            <div className="flex flex-col gap-4 justify-center">
              <Heading id="desktop-heading" as="h3" className="hidden md:block">
                Image details
              </Heading>
              <p className="max-h-[98px] overflow-y-auto md:max-h-[none] md:block"> {selectedImage.prompt}</p>
              <p className="hidden md:block">
                <span className="font-semibold">Aspect ratio</span>

                {`  ${selectedImage.aspect_ratio_label}`}
              </p>
              {/* {selectedImage.modelData
                ?.filter((it: any) => it != null) // Filter out null or undefined
                .map((model: { entity_type: string; name: string }, index: any) => (
                  <p key={index}>
                    <span className="font-semibold">
                      {model.entity_type === "USER_PRODUCT" ? "Product: "
                        : model.entity_type === "BRAND" ? "Brand: "
                          : model.entity_type === "LICENSABLE_PROPERTY" ? "Talent: "
                            : "IP Vault: "}
                    </span> {model.name}
                  </p>
                ))} */}

              <p>
                <span className="font-semibold">Board</span>
                {`  ${board.name}`}
              </p>
              <div className="relative flex flex-row gap-5">
                <div
                  onClick={() => setDisplayDropdown(true)}
                  className="flex flex-row justify-between items-center px-5 w-full border rounded-[20px] border-gray-300 cursor-pointer"
                >
                  <div>{board === selectedBoard ? "Move to" : selectedBoard.name}</div>
                  <DownCarret />
                </div>
                {displayDropdown && (
                  <div
                    ref={dropdownRef}
                    className="text-white w-full h-[225px] cursor-pointer absolute mt-2 p-7 space-y-3 rounded-2xl z-50 bg-blackish overflow-scroll"
                  >
                    {boardList
                      .filter((b) => b.id !== board.id)
                      .map((board) => (
                        <div
                          key={board.id}
                          onClick={() => {
                            setSelectedBoard(board);
                            setDisplayDropdown(false);
                          }}
                          className="p-1 font-body flex flex-row justify-between"
                        >
                          {board.name}
                          {/* {selectedBoard?.id === board.id && <SelectedIcon />} */}
                        </div>
                      ))}
                  </div>
                )}
                <Button
                  variant="primary-negative"
                  disabled={selectedBoard.id === board.id}
                  // maybe take user to new board instead of returning to the same board?
                  onClick={() => {
                    moveImageToNewBoard(selectedBoard.id || "", selectedImage.generative_output_id, board.id || "");
                    setOpenModal(false);
                  }}
                >
                  Move
                </Button>
              </div>

              {/* TODO Apply for license */}
              {/* <p className="font-semibold">License: <Button className="text-black font-semibold outline-none" variant="link"><>Apply for a license <IconArrowRight /> </></Button></p> */}
              <div className="flex gap-2">
                <Button
                  variant="outline-official"
                  className="hidden md:block rounded-[20px] border-gray-300 hover:fill-white"
                  onClick={() => navigate(`/image/editor/${selectedImage.task_queue_id}`)}
                >
                  Edit image
                </Button>
                <Button
                  variant="outline-official"
                  className="rounded-[20px] border-gray-300 hover:fill-white"
                  href={selectedImage.permalink}
                >
                  Download
                </Button>
                <Button
                  variant="outline-official"
                  className="rounded-[20px] border-gray-300 hover:fill-white"
                  onClick={() => removeImageFromBoard()}
                >
                  Delete from board
                </Button>
              </div>
            </div>
          </div>
        )}
      </DialogModal>
      <BoardSettingsDialog
        key={board.id}
        isOpen={openModal && modalType === "editBoard"}
        onOpenChange={(open) => {
          if (!open) {
            setModalType("imageDetails");
          }
          setOpenModal(open);
        }}
        onClose={(updatedBoard) => {
          if (updatedBoard && board?.id) {
            updateBoard(board.id, updatedBoard);
          }
          setOpenModal(false);
          setModalType("imageDetails");
        }}
        board={board}
        mode="edit"
      />
      <div className="text-white px-8 lg:px-[120px] pb-32">
        <div className="flex flex-col mb-12">
          <div>
            <div className="flex flex-col items-center gap-3">
              <div className="flex flex-row w-full py-4 justify-between">
                <h1 className={`${headingFontSize(board.name)} pr-4 truncate`}>{board.name}</h1>
                <div className="flex gap-2">
                  <Button className="h-11 w-11 border-none" onClick={openEditModal}>
                    <div>
                      <PencilIcon />
                    </div>
                  </Button>
                  {board.is_deletable && (
                    <Button
                      aria-label="Delete board"
                      onClick={() => board.id && handleDeleteBoard(board.id)}
                      className="h-11 w-11 border-none"
                    >
                      <div>
                        <TrashIcon />
                      </div>
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between gap-2">
              <Button className="gap-2 px-6 py-3 fill-white hover:fill-black" variant="outline" onClick={handleBack}>
                <>
                  <BackIcon />
                  Back
                </>
              </Button>
              <div className="text-lg">{`${
                board.generative_outputs && `${board.generative_outputs.length}`
              } ${pluralize("image", board.generative_outputs?.length)}`}</div>
            </div>
          </div>
          <p className="text-gray-500 text-xl pt-3">{board.description}</p>
        </div>
        <div className={"flex flex-row flex-wrap justify-center gap-8 lg:gap-[52px]"}>
          {board?.generative_outputs &&
            board.generative_outputs.length > 0 &&
            board.generative_outputs.map((image) => (
              <div
                key={image.permalink}
                onClick={() => openImageDetails(image)}
                className={"image-card h-[460px] w-[360px] rounded-2xl"}
              >
                <div className="image-card-inner">
                  {image.asset_type === "video" ? (
                    <VideoThumbnail
                      variation={image}
                      isSelected={false}
                      cardStyle={() => "w-full h-full object-cover"}
                      hideControls
                      cornerOverlay
                      loop
                    />
                  ) : (
                    <img
                      crossOrigin="anonymous"
                      className="w-full h-full object-cover"
                      src={image.permalink}
                      alt="Generated image"
                    />
                  )}
                  <div
                    onClick={(e) => {
                      handleFavoriteClick(e, board.id || "", image);
                    }}
                    className="absolute top-5 right-5 fill-white cursor-pointer"
                  >
                    <FavoriteIcon isFavorited={localFavorites.includes(image.generative_output_id)} />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ImageBoard;
