import React from "react";
import Panel from "./panel";
import VersionHistorySlider from "../version_history_slider";
import {Button} from "../../../../common/components/ui/button";
import {VersionHistoryWithMedia} from "../../models/image_generator";
import {useSearchParams} from "react-router-dom";

const VersionHistoryPanel = ({
                                 versionHistory,
                                 selectedVariant,
                                 setSelectedVariant,
                                 showLoadingImage,
                                 helperText,
                                 generateVariation,
                                 closePanel,
                                 handleToggleExpand,
                                 expanded,
                                 statusResponse,
}: {
    versionHistory: VersionHistoryWithMedia[];
    selectedVariant?: VersionHistoryWithMedia | null;
    setSelectedVariant: (variant: VersionHistoryWithMedia) => void;
    showLoadingImage: boolean;
    helperText?: string;
    generateVariation: () => void;
    closePanel?: () => void;
    expanded: boolean | undefined;
    handleToggleExpand: () => void;
    statusResponse?: Array<{
        percent_complete: number;
        progress_message: string;
    }>;
}) => {
    const [searchParams] = useSearchParams();
    // check if the url has the param gv for generate variations
    const showGenerateVariations = searchParams.has("gv");

    return (
        <Panel
            helperText={helperText}
            toggleExpanded={handleToggleExpand}
            isExpanded={expanded}
            title="Versions"
            closePanel={closePanel}
        >
            <div className="space-y-4">
                <VersionHistorySlider
                    versionHistory={versionHistory}
                    selectedVariant={selectedVariant}
                    setSelectedVariant={setSelectedVariant}
                    showLoadingImage={showLoadingImage}
                    statusResponse={statusResponse}
                />
                {showGenerateVariations && (
                    <Button onClick={generateVariation} className="w-full" variant="outline">
                        Generate variations
                    </Button>
                )}
            </div>
        </Panel>
    );
};

export default VersionHistoryPanel;
