import { CropPresetOption } from "@pqina/pintura";

const AR_OPTIONS = [
  {
    key: "SQUARE",
    value: "SQUARE",
    label: "1:1",
    description: "Square",
    default: true,
    ratio: 1
  },
  {
    key: "VERTICAL",
    value: "VERTICAL",
    label: "9:16",
    description: "Vertical",
    ratio: 9 / 16
  },
  {
    key: "FREE",
    value: "FREE",
    label: "Free",
    description: "Custom",
    ratio: undefined
  }
];

export const PINTURA_AR_OPTIONS: CropPresetOption[] = AR_OPTIONS.map((option) => [option.ratio, option.key]);

export default AR_OPTIONS;