import React, { useEffect } from "react";
import { TrainingSet, TrainingSetFile } from "../../model/vault";
import { File } from "../../model/file";
import { useNavigate, useParams } from "react-router-dom";
import { useTrainingSetAPI } from "../../api/training_set_api";
import { Button } from "../../common/components/ui/button";
import { useForm } from "react-hook-form";
import { useFileAPI } from "../../api/file_api";
import { useToast } from "../../common/components/ui/use_toast";
import SelectableImageCard from "./components/selectable-image-card";
import UploadCard from "./components/upload-card";
import TrainingFileCard from "./components/training-file-card";
import CaptionAndCropModal from "./components/caption_and_crop_modal";
import { DialogModal } from "../../common/components/ui/dialog_modal";
import { useGenerativeModelAPI } from "../../api/generative_model_api";
import cachedImageElement from "../../lib/cached-image-element";
import ProgressBar from "../../common/components/ui/progress_bar";
import DeleteModal from "../../common/components/ui/delete-modal";
import { ThemeProvider } from "../../contexts/theme_context";
import { cn } from "../../lib/utils";
import { pluralize } from "../../lib/utils";
import ActionFooter from "../../common/components/ui/action-footer";
import { BackIcon } from "../../common/icons/icons";

const withCachedImage = (file: any /* TrainingSetFile & File */) => ({
  ...file,
  image: file.image || cachedImageElement(file.permalink || file.file_permalink || ""),
});

const TrainingSetEdit: React.FC = () => {
  const { register, handleSubmit, setValue, reset, formState, watch, control } = useForm<TrainingSet>();
  const [trainingSet, setTrainingSet] = React.useState<TrainingSet>({ files: [] });
  const [entityFiles, setEntityFiles] = React.useState<File[]>();
  const [isLoadingFiles, setIsLoadingFiles] = React.useState<boolean>(true);
  const { id, generativeModelId } = useParams<{ id: string; generativeModelId: string }>();
  const trainingSetAPI = useTrainingSetAPI();
  const generativeModelAPI = useGenerativeModelAPI();
  const [saving, setSaving] = React.useState<boolean>(false);
  const [uploading, setUploading] = React.useState<boolean>(false);
  const { uploadFile, deleteFile } = useFileAPI();
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const { toast } = useToast();
  const [isSourceDragOver, setIsSourceDragOver] = React.useState<boolean>(false);
  const [statusText, setStatusText] = React.useState<string>("");
  const [statusPercent, setStatusPercent] = React.useState<number>(0);
  const [modalType, setModalType] = React.useState<"crop" | "delete" | null>(null);
  const [selectedTrainingSetFile, setSelectedTrainingSetFile] = React.useState<TrainingSetFile | null>(null);
  const globalCaption = watch("description") || "";
  const [prevGlobalCaption, setPrevGlobalCaption] = React.useState<string | null>("");

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setIsLoadingFiles(true);
      trainingSetAPI.getTrainingSet(id).then((response) => {
        const responseWithImageElements = {
          ...response,
          files: response.files.map(withCachedImage),
        };
        setTrainingSet(responseWithImageElements);
        reset(responseWithImageElements);
        trainingSetAPI
          .fetchTrainingFilesForEntity(
            response.entity_type as "LICENSABLE_PROPERTY" | "USER_PRODUCT" | "BRAND",
            response.entity_id || ""
          )
          .then((files) => {
            const sortedFiles = files
              .map(withCachedImage)
              .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
            setEntityFiles(sortedFiles);
            setIsLoadingFiles(false);
          });
      });
    }
  }, [id]);

  // is this used?
  useEffect(() => {
    if (generativeModelId) {
      generativeModelAPI.getModelById(generativeModelId).then((response) => {
        setValue("generative_model_id", generativeModelId);
        setValue("entity_id", response.entity_id);
        setValue("entity_type", response.entity_type);
        trainingSetAPI.fetchTrainingFilesForEntity(response.entity_type, response.entity_id).then((response) => {
          const sortedFiles = response
            .map(withCachedImage)
            .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
          setEntityFiles(sortedFiles);
          setIsLoadingFiles(false);
        });
        //Save a blank training set so it will generate a valid ID
        trainingSetAPI
          .saveTrainingSet({
            generative_model_id: generativeModelId,
            entity_id: response.entity_id,
            entity_type: response.entity_type,
            files: [],
          })
          .then((response) => {
            setTrainingSet(response);
            reset(response);
          });
      });
    }
  }, [generativeModelId]);

  useEffect(() => {
    if (!trainingSet) return;
    if (modalType === "crop") {
      const saveTrainingSet = async () => {
        try {
          await trainingSetAPI.saveTrainingSet(trainingSet);
        } catch (error) {
          console.error("Error saving training set:", error);
        }
      };

      saveTrainingSet();
    }
  }, [modalType, trainingSet]);

  const showToast = (message: string) => {
    toast({
      title: message,
      variant: "default",
    });
  };

  const onSubmit = async (data: TrainingSet) => {
    setSaving(true);
    data.files = trainingSet?.files || [];
    trainingSetAPI
      .saveTrainingSet(data)
      .then((response) => {
        showToast("Training set saved successfully");
        setSaving(false);
        navigate(-1);
      })
      .catch((error) => {
        showToast("Error saving training set");
        setSaving(false);
      });

  };

  const uploadAndAddFileToEntity = async (file: any) => {
    if (!file) return;

    try {
      const uploadRequest = {
        files: [file],
        is_public: true,
        object_id: watch("entity_id"),
        object_type: watch("entity_type") || "LICENSABLE_PROPERTY",
        generate_thumbnail: true,
        resize: "medium",
        usage: "training",
      };

      setUploading(true);
      setStatusText(`Uploading ${file.name}...`);

      const uploadedFiles = await uploadFile(uploadRequest, (progress) => {
        const prog = progress?.progress || 0;
        setStatusPercent(prog * 100);
      });

      if (uploadedFiles.length > 0) {
        const withImageElement = uploadedFiles.map(withCachedImage);
        setEntityFiles((prevFiles) => [...withImageElement, ...(prevFiles || [])]);
      }

      setStatusText("");
      setUploading(false);
      setStatusPercent(0);
    } catch (error) {
      console.error("File upload failed", error);
      setUploading(false);
    }
  };

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setUploading(true);
      try {
        const uploadedFiles = Array.from(files).map((file) => uploadAndAddFileToEntity(file));
        await Promise.all(uploadedFiles);
      } catch (error) {
        console.error("Error uploading files:", error);
      } finally {
        setUploading(false);
      }
    }

    e.target.value = "";
  };

  const handleSourceDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsSourceDragOver(true);
  };
  const handleSourceDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsSourceDragOver(false);
  };
  const handleSourceDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsSourceDragOver(false);
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      setUploading(true);
      try {
        const uploadedFiles = Array.from(files).map((file) => uploadAndAddFileToEntity(file));
        await Promise.all(uploadedFiles);
      } catch (error) {
        console.error("Error uploading files:", error);
      } finally {
        setUploading(false);
      }
    }
  };
  const toggleTrainingImage = (file: File) => {
    if (trainingSet) {
      if (trainingSet.files.some((it: TrainingSetFile) => it.original_file_id === file.id)) {
        const updatedFiles = trainingSet.files.filter((it: TrainingSetFile) => it.original_file_id !== file.id);
        setTrainingSet({ ...trainingSet, files: updatedFiles });
      } else {
        const trainingSetFile: TrainingSetFile = {
          original_file_id: file.id || "",
          original_file_permalink: file.permalink || "",
          original_file_thumbnail: file.thumbnail || "",
          file_id: file.id,
          file_permalink: file.permalink || "",
          file_thumbnail: file.thumbnail || "",
          description: file.description || "",
          id: file.id || "",
          image: withCachedImage(file).image,
        };
        setTrainingSet({ ...trainingSet, files: [...trainingSet.files, trainingSetFile] });
      }
    }
  };
  const removeTrainingSetFile = (file: TrainingSetFile) => {
    if (trainingSet) {
      const updatedFiles = trainingSet.files.filter(
        (it: TrainingSetFile) => it.original_file_id !== file.original_file_id
      );
      setTrainingSet({ ...trainingSet, files: updatedFiles });
    }
  };
  const editTrainingSetFile = (file: TrainingSetFile) => {
    setSelectedTrainingSetFile(file);
    setModalType("crop");
  };

  const handleOpenDeleteModal = (TrainingFile: TrainingSetFile) => {
    setSelectedTrainingSetFile(TrainingFile);
    setModalType("delete");
  };

  const deleteFileFromEntity = async () => {
    setModalType(null);
    if (!selectedTrainingSetFile) return;
    try {
      await deleteFile(selectedTrainingSetFile.id || "");
      setSelectedTrainingSetFile(null);
      toggleTrainingImage(selectedTrainingSetFile);
      setEntityFiles((prevFiles) => prevFiles?.filter((file) => file.id !== selectedTrainingSetFile.id || "") || []);
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const addGlobalCaption = () => {
    if (!globalCaption) return;

    const updatedFiles = trainingSet?.files.map((file) => {
      if (!file.description) {
        return { ...file, description: globalCaption };
      } else if (!file.description.includes(globalCaption)) {
        return { ...file, description: file.description + " " + globalCaption };
      }
      return file;
    });
    setPrevGlobalCaption(globalCaption);
    setTrainingSet({ ...trainingSet, files: updatedFiles });
  };

  return (
    <div
      className="page_content px-[60px] pt-[20px] pb-10 flex flex-col overflow-auto"
      style={{ height: "calc(100vh - 160px)" }}
    >
      <DialogModal
        isOpen={modalType !== null}
        onOpenChange={() => setModalType(null)}
        variant={modalType === "crop" ? "large" : "default"}
        customClass
        removeFooter
        onClose={() => {
          setModalType(null);
        }}
      >
        <ThemeProvider defaultTheme="light">
          {modalType === "crop" && selectedTrainingSetFile?.image && (
            <CaptionAndCropModal
              trainingSet={trainingSet}
              trainingSetFile={selectedTrainingSetFile}
              close={() => setModalType(null)}
              closeWithError={(message) => {
                setModalType(null);
                showToast(message);
              }}
              handleSave={(updatedTrainingSet: TrainingSet) => {
                const responseWithImageElements = {
                  ...updatedTrainingSet,
                  files: updatedTrainingSet.files.map(withCachedImage),
                };
                setTrainingSet(responseWithImageElements);
                reset(responseWithImageElements);
                setModalType(null);
              }}
            />
          )}

          {modalType === "delete" && (
            <DeleteModal
              modalType={"image"}
              closeModal={() => setModalType(null)}
              handleDelete={deleteFileFromEntity}
            />
          )}
        </ThemeProvider>
      </DialogModal>

      <Button
        className="gap-2 px-6 py-3 my-6  fill-white hover:fill-black self-start"
        variant="outline"
        onClick={() => navigate(-1)}
      >
        <>
          <BackIcon />
          Back
        </>
      </Button>
      <h1 className="text-white text-3xl font-serif">Curate training data</h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col gap-3 w-full mt-4">
            <label className="text-white text-base font-sans not-italic" htmlFor="description">
              Add global captions
            </label>
            <input
              className="bg-transparent w-full italic pb-2 border-b border-b-brand-gray-500 font-serif focus:outline-none focus:ring-0 text-white"
              type="text"
              id="description"
              {...register("description")}
            />
          </div>
          <div>
            <Button type="button" onClick={addGlobalCaption} variant="outline" className="self-start">
              Apply to all images
            </Button>
          </div>
        </div>

        <div className="flex flex-row gap-8 mt-8">
          <div className="flex flex-wrap w-1/2">
            <div className="flex flex-row w-full justify-between pb-6">
              <p className="font-sans not-italic semibold text-white">Uploaded images</p>
              <span className="text-brand-gray-500 font-regular font-body">
                {`${entityFiles?.length || 0} ${pluralize("image", entityFiles?.length || 0)}`}
              </span>
              {statusText && (
                <div className="flex flex-col px-4 text-sm rounded-xl text-white" role="alert">
                  <ProgressBar progress={statusPercent} />
                  <div>{statusText}</div>
                </div>
              )}
            </div>
            <div
              id="available_images"
              className={
                "bg-gray-900 h-full w-full rounded-[20px] p-6 flex flex-col items-center" +
                (isSourceDragOver ? "border-2 border-dashed border-white" : "")
              }
              onDragOver={handleSourceDragOver}
              onDragLeave={handleSourceDragLeave}
              onDrop={handleSourceDrop}
            >
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                className="hidden"
                onChange={handleFileSelect}
                multiple
              />
              {isLoadingFiles ? (
                <div className="@container w-full flex justify-center">
                  <div
                    className={cn(
                      "grid gap-x-5 gap-y-4",
                      "@[0px]:grid-cols-1",
                      "@[400px]:grid-cols-2",
                      "@[600px]:grid-cols-3",
                      "@[1200px]:grid-cols-4",
                      "justify-items-center"
                    )}
                  >
                    {[1, 2, 3, 4, 5, 6].map((i) => (
                      <div key={i} className="animate-pulse rounded-lg bg-gray-700 w-[168px] h-[168px]" />
                    ))}
                  </div>
                </div>
              ) : entityFiles && entityFiles.length > 0 ? (
                <div className="@container w-full flex justify-center">
                  <div
                    className={cn(
                      "grid gap-x-5 gap-y-4",
                      "@[0px]:grid-cols-1",
                      "@[400px]:grid-cols-2",
                      "@[600px]:grid-cols-3",
                      "@[1200px]:grid-cols-4",
                      "justify-items-center"
                    )}
                  >
                    <UploadCard onBrowse={() => fileInputRef.current?.click()} isCompact />
                    {entityFiles.map(
                      (file: TrainingSetFile) =>
                        file.image && (
                          <SelectableImageCard
                            key={file.id}
                            image={file}
                            isSelected={
                              trainingSet?.files.some((it: TrainingSetFile) => it.original_file_id === file.id) || false
                            }
                            onClick={() => toggleTrainingImage(file)}
                            deleteTrainingSetFile={handleOpenDeleteModal}
                          />
                        )
                    )}
                  </div>
                </div>
              ) : (
                <UploadCard onBrowse={() => fileInputRef.current?.click()} />
              )}
            </div>
          </div>
          <div className="flex flex-wrap w-1/2">
            <div className="flex flex-row w-full justify-between pb-6">
              <p className="font-sans not-italic semibold text-white">Selected for training</p>
              <span className="text-brand-gray-500 font-regular font-body">
                {`${trainingSet?.files?.length || 0} ${pluralize("image", trainingSet?.files?.length || 0)}`}
              </span>
            </div>
            <div id="selected_images" className="bg-gray-900 h-full w-full rounded-[20px] p-6">
              {trainingSet?.files && trainingSet?.files.length > 0 && (
                <div className={"flex flex-col items-center p-2 gap-3"}>
                  {trainingSet?.files?.map(
                    (file) =>
                      file.image && (
                        <TrainingFileCard
                          key={file.id}
                          file={file}
                          onDelete={() => removeTrainingSetFile(file)}
                          onEdit={() => editTrainingSetFile(file)}
                        />
                      )
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
      <ActionFooter
        primaryAction={{
          label: "Save",
          onClick: handleSubmit(onSubmit),
          loading: saving || uploading,
          loadingText: saving ? "Saving..." : "Uploading Image",
          disabled: saving || uploading,
        }}
        onCancel={() => navigate(-1)}
      />
    </div>
  );
};

export default TrainingSetEdit;
