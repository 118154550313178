import React from "react";
import { useTheme } from "../../contexts/theme_context";
import { BlackCheck } from "../icons/icons";
import cn from "clsx";

interface Option {
  key: string;
  value: string;
  label: string;
  description: string;
  default?: boolean;
}

interface RadioCardGroupProps {
  options: Option[];
  label?: string;
  selected: string | null;
  onSelect: (value: string) => void;
  className?: string;
}

// this is better than the card-radio-group component

const RadioCardGroup = ({ options, label, selected, onSelect, className }: RadioCardGroupProps) => {
  const { theme } = useTheme();
  const itemDimensions = "w-full min-w-[108px] h-[57px]";

  const borderColor = theme === "dark" ? "border-gray-700" : "border-gray-300";
  const hoverColor = theme === "dark" ? "hover:bg-gray-700" : "hover:border-brand-black";
  const selectedBorderColor = theme === "dark" ? "border-brand-white" : "border-brand-black";

  return (
    <div className={cn("space-y-4", className)}>
      {label && <p className="text-base">{label}</p>}

      {/* Container query based grid layout */}
      <div className="@container">
        <div className={cn(
            "grid gap-4",
            "@[0px]:grid-cols-2",
            "@[350px]:grid-cols-3",
            "@[650px]:grid-cols-6",

        )}>
          {options.map((option) => (
            <div
              key={option.key}
              className={cn(
                "relative cursor-pointer flex items-start justify-start border rounded-[20px] pl-3 pt-1 transition-colors",
                selected === option.value && `bg-white ${selectedBorderColor} text-black`,
                selected !== option.value && `bg-transparent ${borderColor} ${hoverColor}`,
                itemDimensions
              )}
              onClick={() => onSelect(option.value)}
            >
              <div className={cn("flex flex-col w-full px-2")}>
                <span className="font-semibold whitespace-nowrap overflow-hidden text-ellipsis w-full max-w-full">
                  {option.label}
                </span>
                <p className="text-sm whitespace-nowrap overflow-hidden text-ellipsis w-full max-w-full">
                  {option.description}
                </p>
              </div>

              {selected === option.value && (
                <div className="absolute top-4 right-2">
                  <BlackCheck />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RadioCardGroup;
