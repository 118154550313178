import React, { useState, useEffect } from "react";

const BoardPage = () => {
    return (
        <div>
            <h1>board page </h1>
        </div>
    );

}

export default BoardPage;