import React, { useEffect } from "react";
import { File } from "../../../model/file";
import { Button } from "../../../common/components/ui/button";
import { cn, pluralize } from "../../../lib/utils";
import UploadCard from "./upload-card";
import { IconCheckMark, TrashIcon } from "../../../common/icons/icons";

export interface ImageDisplayProps {
  title?: string;
  route: string;
  images: any[];
  addUpload?: boolean;
  browse: () => void;
  forMain?: boolean;
  handleDelete?: (file: File) => void;
}

const ImageDisplay: React.FC<ImageDisplayProps> = ({
  title,
  route,
  images,
  addUpload,
  browse,
  handleDelete,
  forMain,
}) => {
  return (
    <div>
      {title && (
        // <div className={`flex flex-row justify-between items-center pb-6 ${forMain ? "w-[67vw]" : ""}`}>
        <div className="flex flex-row justify-between items-center pb-6">
          <h2 className="text-white text-2xl">{title}</h2>
          <div className="flex flex-row gap-6 items-center">
            <p className="text-brand-gray-500">{`${images?.length || 0} ${pluralize("image", images?.length || 0)}`}</p>
            <Button variant="outline" href={route}>
              Manage images
            </Button>
          </div>
        </div>
      )}

      <div className={`${!forMain ? "bg-gray-900 rounded-[20px] p-6" : ""}`}>
        <div className="@container flex justify-start">
          <div
            className={cn(
              "grid gap-x-5 gap-y-4",
              "@[0px]:grid-cols-1",
              "@[400px]:grid-cols-2",
              "@[600px]:grid-cols-3",
              "@[1200px]:grid-cols-4",
              `justify-items-center ${forMain ? "bg-gray-900 rounded-[20px] p-6" : ""}`
            )}
          >
            {addUpload && <UploadCard onBrowse={() => browse()} isCompact />}
            {images.length > 0 &&
              images.slice(0, 6).map((image, index) => (
                <div
                  key={index}
                  className={`relative rounded-lg bg-gray-700 ${
                    forMain ? "w-[200px] h-[200px]" : "w-[288px] h-[288px]"
                  } bg-contain bg-center bg-no-repeat image-card`}
                  style={{
                    backgroundImage: `url(${image.permalink || image.file_permalink || image.thumbnail || ""})`,
                  }}
                >
                  {/* Delete Button inside relative container */}
                  {handleDelete && (
                    <Button
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        handleDelete(image);
                      }}
                      className="absolute bottom-2 right-2 p-2 border-none fill-white rounded-full hover:bg-transparent"
                      variant="outline"
                      type="button"
                    >
                      <TrashIcon />
                    </Button>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageDisplay;
