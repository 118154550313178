import React from "react";
import { PanelXIcon, InfoIcon } from "../../../../common/icons/icons";
import { Popover, PopoverContent, PopoverTrigger } from "../../../../common/components/ui/popover";

const Panel = ({ children, title, isExpanded, helperText, toggleExpanded, closePanel }: { children: React.ReactNode, title: string, helperText?: string, isExpanded?: boolean, toggleExpanded?: () => void, closePanel?: () => void; }) => {
    return (
        <div className="bg-gray-900 select-none text-white rounded-xl p-5">
            <div
                onClick={toggleExpanded}
                className={`flex justify-between transition-all duration-300 ${isExpanded ? "pb-4" : "pb-0"}`}
            >
                <div className="flex flex-row gap-2">
                    {title}
                    {helperText && (
                        <Popover>
                            <PopoverTrigger onClick={(e) => {
                                e.stopPropagation();
                            }}>
                                <InfoIcon />
                            </PopoverTrigger>
                            <PopoverContent className="text-base bg-brand-yellow border-none">
                                {helperText}
                            </PopoverContent>
                        </Popover>
                    )}
                </div>

                {closePanel && (
                    <div onClick={closePanel}>
                        <PanelXIcon />
                    </div>
                )}
            </div>

            <div
                className={`transition-all duration-300 ${isExpanded ? "opacity-100" : "opacity-0"}`}
                style={{
                    maxHeight: isExpanded ? "none" : "0",
                    overflow: isExpanded ? "visible" : "hidden"
                }}>
                {children}
            </div>
        </div>
    );
};

export default Panel;