import { useAuth0 } from "@auth0/auth0-react";
import { BoardDTO } from "../pages/image-generator/models/image_generator";

export function useImageBoardAPI() {
    const { getAccessTokenSilently } = useAuth0();

    const getImageBoards = async (): Promise<BoardDTO[]> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });
        return new Promise<BoardDTO[]>((resolve, reject) => {
            const url = `${process.env.REACT_APP_API_URL}/board`;
            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data: BoardDTO[]) => {
                        resolve(data);
                    });
                } else {
                    reject(new Error("User is not authenticated"));
                }
            });
        });
    };


    const saveNewBoard = async (saveRequest: BoardDTO): Promise<BoardDTO> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<BoardDTO>((resolve, reject) => {
            const url = `${process.env.REACT_APP_API_URL}/board`;
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(saveRequest),
            })
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.json());
                    } else {
                        reject(new Error("There was an issue saving the board: " + response.statusText));
                    }
                })
                .catch((err) => {
                    console.error(err);
                    reject(new Error("User is not authenticated"));
                });
        });
    }

    const saveImageToBoard = async (boardId: string, generativeOutputId: string): Promise<BoardDTO> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<BoardDTO>((resolve, reject) => {
            const url = `${process.env.REACT_APP_API_URL}/board/${boardId}/generative_output/${generativeOutputId}`
            fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.json());
                    } else {
                        reject(new Error("There was an issue saving the board: " + response.statusText));
                    }
                })
                .catch((err) => {
                    console.error(err);
                    reject(new Error("User is not authenticated"));
                });
        });
    }

    const removeImageFromBoard = async (boardId: string, generativeOutputId: string): Promise<BoardDTO> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<BoardDTO>((resolve, reject) => {
            const url = `${process.env.REACT_APP_API_URL}/board/${boardId}/generative_output/${generativeOutputId}`
            fetch(url, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.json());
                    } else {
                        reject(new Error("There was an issue saving the board: " + response.statusText));
                    }
                })
                .catch((err) => {
                    console.error(err);
                    reject(new Error("User is not authenticated"));
                });
        });
    }

    const addOrRemoveFavorite = async (generativeOutputId: string): Promise<BoardDTO> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<BoardDTO>((resolve, reject) => {
            const url = `${process.env.REACT_APP_API_URL}/board/favorite/${generativeOutputId}`;
            fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.json());
                    } else {
                        reject(new Error("There was an issue saving to favorites " + response.statusText));
                    }
                })
                .catch((err) => {
                    console.error(err);
                    reject(new Error("User is not authenticated"));
                });
        });
    }

    const moveImageToDifferentBoard = async (destinationBoardId: string, generativeOutputId: string, sourceBoardId: string) => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<BoardDTO>((resolve, reject) => {
            const queryParams = new URLSearchParams();
            queryParams.append("sourceBoardId", sourceBoardId);
            // const queryString = queryParams.toString();

            const url = `${process.env.REACT_APP_API_URL}/board/${destinationBoardId}/move_generative_output/${generativeOutputId}?${queryParams}`;
            fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.json());
                    } else {
                        reject(new Error("There was an issue saving to favorites " + response.statusText));
                    }
                })
                .catch((err) => {
                    console.error(err);
                    reject(new Error("User is not authenticated"));
                });
        });
    }


    const updateBoard = async (boardId: string, updateRequest: BoardDTO): Promise<BoardDTO> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<BoardDTO>((resolve, reject) => {
            const url = `${process.env.REACT_APP_API_URL}/board/${boardId}`;
            fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(updateRequest),
            })
                .then((response) => {
                    if (response.status === 200) {
                        resolve(response.json());
                    } else {
                        reject(new Error("There was an issue saving the board: " + response.statusText));
                    }
                })
                .catch((err) => {
                    console.error(err);
                    reject(new Error("User is not authenticated"));
                });
        });
    }

    const deleteBoard = async (boardId: string): Promise<void> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });
        return new Promise<void>((resolve, reject) => {
            const url = `${process.env.REACT_APP_API_URL}/board/${boardId}`;
            fetch(url, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    resolve();
                } else {
                    reject(new Error("There was an issue deleting image: " + response.statusText));
                }
            });
        });
    };


    return {
        getImageBoards,
        saveNewBoard,
        saveImageToBoard,
        removeImageFromBoard,
        moveImageToDifferentBoard,
        addOrRemoveFavorite,
        updateBoard,
        deleteBoard
    };
}
