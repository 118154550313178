import React, { useLayoutEffect } from "react";
import PublicHeader from "../layouts/public_header";
import "./how_it_works.css";
import "./home.css";
import AppFooter from "../layouts/app_footer";
const HowItWorksMarketers = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return <main className="page_container home_page">
        <PublicHeader theme="dark" />
        <div className="how_works_section_1 pixel_yellow text-blackish pt-[100px] text-center">
            <div className="text_block">
                <div className="pill_button text-m-sans">For Brands</div>
                <h1 className="text-xxxxl-serif">AI-powered campaigns with<br />real talent.</h1>
                <p className="text-xl-sans w-[90vw] md:w-[50vw] mx-auto">Create authentic commercial photography and video content in moments instead of months - safely and affordably in the AI-era.</p>
            </div>
            <div className="pixel_block">
                <div className="pixel_sm pixel_transparent" />
                <div className="pixel_sm pixel_transparent" />
                <div className="pixel_sm pixel_transparent" />
                <div className="pixel_sm pixel_transparent" />
                <div className="pixel_sm pixel_blue" />
                <div className="pixel_sm pixel_transparent" />
                <div className="pixel_sm pixel_red" />
                <div className="pixel_sm pixel_pink" />
                <div className="pixel_sm pixel_transparent" />
                <div className="pixel_sm pixel_blue" />
                <div className="pixel_sm pixel_transparent" />
                <div className="pixel_sm pixel_white" />
                <div className="pixel_sm pixel_white" />
                <div className="pixel_sm pixel_black" />
            </div>
        </div>
        <div className="how_works_section_1_and_half">
            <div className="image_block">
                <div className="background_pixel pixel_dark_gray" />
                <img src={require("./images/for_marketers_img_1.2.png")} alt="For talent pricing" width="317" height="688" />
            </div>

            <div className="text_block ml-[32px]">
                <h2 className="text-xxl-serif">It all starts with your brand vault</h2>
                <p className="mt-7">Your secure AI content studio built in minutes</p>
                <ul className="no_padding">
                    <li>Upload your products, brand assets, and style guides to create your brand AI model</li>
                    <li>Your foundation for creating authentic, on-brand content at scale</li>
                    <li>Ready to pair with verified talent vaults</li>
                </ul>
            </div>
            <div className="bullet_number">1</div>
        </div>
        <div className="how_works_section_2">
            <div className="bullet_number">2</div>
            <div className="text_block ml-[32px]">
                <h2 className="text-xxl-serif">Supercharge your content creation</h2>
                <ul>
                    <li>Generate hundreds of on-brand assets in minutes, not months</li>
                    <li>Reduce production costs by up to 90% compared to historical photo and video shoots</li>
                    <li>Create hyper-personalized content for every audience</li>
                </ul>
            </div>
            <div className="image_block">
                <div className="background_pixel pixel_pink" />
                <img src={require("./images/for_talent_img_2.2.png")} alt="For talent pricing" width="317" height="688" />
            </div>
        </div>
        <div className="how_works_section_3">
            <div className="image_block">
                <div className="background_pixel pixel_blue" />
                <img src={require("./images/for_marketers_img_1.png")} alt="For talent pricing" width="317" height="688" />
            </div>

            <div className="text_block ml-[32px]">
                <h2 className="text-xxl-serif">Access elite talent on-demand</h2>
                <ul>
                    <li>Collaborate with a vast library of verified athletes, iconic estates, and celebrity AI vaults</li>
                    <li>Create content featuring top talent 24/7 without scheduling conflicts</li>
                    <li>Ensure all collaborations respect talent rights and brand guidelines</li>
                </ul>
            </div>
            <div className="bullet_number">3</div>
        </div>
        <div className="how_works_section_4">
            <div className="bullet_number">4</div>
            <div className="text_block mr-[32px]">
                <h2 className="text-xxl-serif">Ensure authenticity and compliance</h2>
                <ul>
                    <li>Authenticate all AI-generated assets with our durable media provenance technology</li>
                    <li>Automatically review content against brand guidelines for consistent compliance</li>
                    <li>Seamlessly integrate voice, image, or video content from any AI model of your choice</li>
                </ul>
            </div>
            <div className="image_block">
                <img src={require("./images/for_marketers_img_4.png")} alt="For talent pricing" width="317" height="688" />
                <div className="background_pixel pixel_red" />

            </div>
        </div>
        <div className="how_works_section_5">
            <div className="text_block text-xxl-sans">We&apos;re building an ecosystem for authenticity to ignite the next creative renaissance.</div>
            <div>
                <a href="/register" className="pill_button text-m-sans text-nowrap">Get started</a>
            </div>
        </div>

        <AppFooter />
    </main>
}

export default HowItWorksMarketers;