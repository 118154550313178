import React from "react";
import { Button } from "./button";

interface ActionFooterProps {
  primaryAction?: {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    loading?: boolean;
    loadingText?: string;
  };
  showThirdButton?: {
    label: string;
    onClick: () => void;
    disabled?: boolean;
  };
  onCancel?: () => void;
  className?: string;
}

const ActionFooter: React.FC<ActionFooterProps> = ({ primaryAction, onCancel, className = "", showThirdButton }) => {
  return (
    <div className="fixed inset-x-0 bottom-0 z-[900]">
      <div className="absolute inset-0 bg-blackish/50 backdrop-blur-sm" />
      <div className={`relative  px-4 sm:px-8 lg:px-16 py-4 pb-8 flex justify-between items-center ${className}`}>
        <div className="flex-shrink-0">
          {onCancel && (
            <Button onClick={onCancel} className="outline-none pl-0 bg-transparent text-white hover:bg-transparent">
              Cancel
            </Button>
          )}
        </div>

        <div className="flex justify-end">
          {showThirdButton && (
            <Button
              variant="outline"
              onClick={showThirdButton.onClick}
              className="mr-4"
              disabled={showThirdButton.disabled}
            >
              {showThirdButton.label}
            </Button>
          )}
          {primaryAction && (
            <Button
              variant="primary"
              onClick={primaryAction.onClick}
              disabled={primaryAction.disabled}
              className="border-none"
            >
              {primaryAction.loading ? primaryAction.loadingText || "Loading..." : primaryAction.label}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActionFooter;
