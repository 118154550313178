import React from "react";

const Toggle = ({ darkMode, checked, onToggle, label, labelLeft, labelRight }: { darkMode?: boolean, checked: boolean, onToggle: (checked: boolean) => void; label?: string; labelLeft?: string; labelRight?: string }) => {

    return (
        <div>
            {label && <p className={`${darkMode ? "text-white" : "text-blackish"} text-base font-serif italic mb-4`}>{label}</p>}
            <button
                onClick={() => onToggle(!checked)}
                className={`
        relative w-28 h-12 rounded-full p-1 transition-colors duration-200 border flex items-center
        ${darkMode ? "bg-blackish border-gray-500" : "bg-white border-gray-300"}
     
      `}
                type="button"
                role="switch"
                aria-checked={checked}
            >
                <div
                    className={`
          absolute top-1/2 -translate-y-1/2 text-xs font-medium ${darkMode ? "text-white" : "text-blackish"}
          ${checked ? "left-3 " : "right-4 "}
        `}
                >
                    {checked ? labelLeft : labelRight}
                </div>
                <div
                    className={`
          h-7 w-7 rounded-full bg-white shadow-md transform transition-transform duration-200
          ${checked ? "translate-x-[70px]" : "translate-x-1"}
        `}
                />
            </button>
        </div>
    )
}

export default Toggle;