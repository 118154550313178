import React, { ButtonHTMLAttributes } from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { Loader2 } from "lucide-react";
import { Link, LinkProps } from "react-router-dom";

import cn from "../../../lib/utils";

const buttonVariants = cva(
  "relative inline-flex items-center justify-center font-semibold whitespace-nowrap rounded-full text-base ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 ",
  {
    variants: {
      variant: {
        default: "border border-input bg-brand-black/30 hover:bg-accent text-white hover:text-accent-foreground",
        primary: "bg-primary text-primary-foreground hover:bg-secondary border border-primary hover:border-secondary",
        "primary-negative": "bg-brand-black text-white hover:bg-brand-black border border-black",
        "primary-negative-invert": "bg-white border-white text-brand-black border hover:bg-secondary  hover:border-secondary",
        secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        outline: "border border-gray-600 bg-none hover:bg-accent text-white hover:text-accent-foreground",
        "outline-negative": "border border-gray-400 bg-none hover:bg-gray-100 text-black ",
        "outline-official": "border border-gray-600 text-black bg-none hover:bg-black hover:text-white",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-white font-base font-normal underline-offset-4 hover:underline",
        icon: "bg-primary text-primary-foreground hover:bg-secondary border border-yellow-300",
      },
      size: {
        default: "h-14 px-6 py-2 text-base tracking-wide",
        sm: "rounded-full px-7 py-2.5 text-sm tracking-wide",
        xs: "rounded-full px-3 py-2 text-xs tracking-wide",
        lg: "h-14 rounded-full px-8 text-base tracking-wide",
        icon: "h-14 w-14",
      },
    },
    defaultVariants: {
      variant: "secondary",
      size: "sm", // why not default?
    },
  }
);

interface ButtonBaseProps extends VariantProps<typeof buttonVariants> {
  label?: React.ReactNode;
  children?: React.ReactElement | string | undefined;
  loading?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  className?: string;
}

type ButtonProps = ButtonBaseProps & (
  | (Omit<ButtonHTMLAttributes<HTMLButtonElement>, keyof ButtonBaseProps> & { href?: undefined; target?: undefined })
  | (Omit<LinkProps, keyof ButtonBaseProps | "to"> & { href: string; target?: "_blank" | "_self" })
);

export const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  ({ href, children, variant = "secondary", prefix, suffix, target, className, ...props }, ref) => {
    const { loading, size, ...rest } = props;

    const buttonClassName = cn(buttonVariants({ variant, size }), "gap-1", className || "") + ` variant-${variant}`;

    if (href) {
      const linkProps = rest as Omit<LinkProps, keyof ButtonBaseProps | "to">;
      return (
        <Link
          ref={ref as React.Ref<HTMLAnchorElement>}
          to={href}
          target={target}
          className={buttonClassName}
          {...linkProps}
        >
          {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          {prefix ? <span className="mr-2">{prefix}</span> : null}
          {children}
          {suffix ? <span className="ml-2">{suffix}</span> : null}
        </Link>
      );
    }

    const buttonProps = rest as Omit<ButtonHTMLAttributes<HTMLButtonElement>, keyof ButtonBaseProps>;
    return (
      <button
        ref={ref as React.Ref<HTMLButtonElement>}
        type="button"
        className={buttonClassName}
        {...buttonProps}
      >
        {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
        {prefix ? <span className="mr-2">{prefix}</span> : null}
        {children}
        {suffix ? <span className="ml-2">{suffix}</span> : null}
      </button>
    );
  }
);

Button.displayName = "Button";

export { buttonVariants };