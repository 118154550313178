import React from "react";
import loadinganimation from "../../common/components/ui/loadinganimation.gif";

const LoadingPage = ({ progressMessage, percentComplete }: { progressMessage?: string; percentComplete?: number; }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${loadinganimation})`,
        backgroundSize: "cover",
        paddingTop: "450px",
        backgroundPosition: "center top",
        height: "100vh",
        width: "100vw",
        position: "fixed",
        zIndex: 1000,
      }}
    >
      <div className="flex flex-col justify-center items-center pt-11">
        {percentComplete !== undefined && percentComplete !== null && (
          <h1 className="text-xxxxxl-sans"> {percentComplete}% </h1>
        )}
        <p className="text-xl-serif">{progressMessage}</p>
      </div>
    </div>
  );
};

export default LoadingPage;
