import {
  // editor
  locale_en_gb,
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  // plugins
  plugin_crop_locale_en_gb,
  //plugin_finetune_locale_en_gb,
  plugin_filter_locale_en_gb,
  plugin_annotate_locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb,
  createMarkupEditorToolbar,
} from "@pqina/pintura";


const defaults = {
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter(),
  shapePreprocessor: createDefaultShapePreprocessor(),
  //...plugin_finetune_defaults,
  //...plugin_filter_defaults,
  ...markup_editor_defaults,
  locale: {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    // ...plugin_finetune_locale_en_gb,
    ...plugin_filter_locale_en_gb,
    ...plugin_annotate_locale_en_gb,
    ...markup_editor_locale_en_gb,
  },
  enableDropImage: false,
  previewUpscale: false,
  enableUtils: false,
  enableCanvasAlpha: true,
  enableButtonExport: false,
  previewPad: false,
  cropEnableRotationInput: false,
  cropEnableButtonFlipHorizontal: false,
  cropEnableButtonFlipVertical: false,
  cropEnableButtonRotateLeft: false,
  cropEnableButtonRotateRight: false,
  imageCropAspectRatio: undefined,

  // layoutVerticalToolbarPreference: "bottom",
  // markupEditorShapeStyleControls: false,
  utils: ["crop" /*, "finetune", "filter" */, "annotate"],
};
defaults.markupEditorToolStyles.sharpie[0].strokeColor = [1, 1, 1];
defaults.markupEditorToolStyles.sharpie[0].strokeWidth = "2.5%"; // "medium large"


export enum EditorMode {
  Editor = "editor",
  CropModal = "crop-modal",
}

export enum EditorTool {
  PanAndZoom = "pan-and-zoom",
  Brush = "brush",
  DragAndDrop = "drag-and-drop"
}


const pinturaConfig = (mode: EditorMode, util: EditorTool) => {
  switch (mode) {
    case EditorMode.CropModal:
      return {
        ...defaults,
        cropEnableImageSelection: true,
        util: "crop",
      };
    default:
      return {
        ...defaults,
        cropEnableImageSelection: false,
        markupEditorToolbar: util === EditorTool.Brush ? createMarkupEditorToolbar(["sharpie"]) : [],
        util: "annotate"

      };
  }
};

export default pinturaConfig;