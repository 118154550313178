import React from "react";
import {Panel} from "../models/image_generator";

const Divider = () => {
    return (
        <div className="w-[40px] h-[2px] bg-gray-700" />
    );
}

const ImageSideNavPanel = ({ panels, onPanelClick, renderPanelContent }: { panels: Panel[], onPanelClick: (panelId: string) => void, renderPanelContent: (panelId: string) => React.ReactNode }) => {
    return (
        <div className="bg-gray-900 w-24 h-full rounded-xl flex flex-col gap-2 items-center py-7">
            {panels.map((panel, index) => panel.isDivider ?
                <Divider key={panel.id} /> :
                (<div
                    key={index}
                    className={`relative w-10 h-10 ${panel.active ? "bg-white" : ""} rounded-xl group`}
                >
                    <button
                        onClick={() => onPanelClick(panel.id)}
                        className={`w-full h-full flex items-center justify-center
                        ${panel.addIconStroke ? panel.active ? "stroke-blackish" : "stroke-white" : ""} 
                        ${panel.active ? "fill-blackish" : "fill-white"}
                        ${panel.isDisabled ? "opacity-50" : ""}
                        hover:cursor-pointer`}
                    >
                        {panel.icon}
                    </button>

                    {!(panel.active && panel.isShort) && <div
                        className="absolute top-0 left-full ml-3 bg-white text-blackish rounded-lg shadow-lg w-40 p-2 z-50 
                       invisible group-hover:visible opacity-0 group-hover:opacity-100 
                       transition-all duration-200"
                    >
                        {panel.isDisabled ? "Not available for video" : panel.description}
                    </div>
                    }
                    {panel.active && panel.isShort && (
                        <div>
                            {renderPanelContent(panel.id)}
                        </div>
                    )}
                </div>)
            )}
        </div>
    )
}

export default ImageSideNavPanel;

