import React, { useEffect } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from "react-redux";
import { store } from "./hooks/store";
import PageRouter from "./routes/page_router";
import { useLocation } from "react-router-dom";
import "./index.css";
import ReactGA from "react-ga";

function App() {

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
      authorizationParams={{
        redirect_uri: window.location.origin + "/dashboard",
        audience: process.env.REACT_APP_AUTH0_AUD || "",
        scope: "read:current_user update:current_user_metadata",
      }}
      cacheLocation="localstorage"
    >
      <Provider store={store}>
        <PageRouter />
      </Provider>
    </Auth0Provider>
  );
}

export default App;
