import { clsx, type ClassValue } from "clsx";
import { format } from "date-fns";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const numberWithCommas = (x: string | number) => {
  const _x = x.toString().replace(",", "").replace(/\D/g, "");
  return _x.toLocaleString();
};

export function numberToHuman(val: number): string {
  if (val < 1000) {
    return val.toString();
  } else if (val < 1000000) {
    return (val / 1000).toFixed(0) + "K";
  } else if (val < 1000000000) {
    return (val / 1000000).toFixed(0) + "M";
  } else {
    return (val / 1000000000).toFixed(0) + "B";
  }
}

export const formatCurrency = (amount: number): string => {
  return `$${(amount / 100).toLocaleString(undefined, { minimumFractionDigits: (amount % 100 === 0 ? 0 : 2)})}`;
};

export const replaceNonAlphaNumeric = (input: string, replacement = "") => {
  const regex = /[^a-zA-Z0-9]/g;
  return input.trim().replace(regex, replacement);
};

export const formatDateString = (date?: string): string | null => {
  if (!date) return null;
  const f = "P";
  const d = format(date, f);
  return `${d}`;
};

export const formatTimeString = (date?: string): string | null => {
  if (!date) return null;
  const f = "p";
  const t = format(date, f);
  return `${t}`;
};
export const humanizeName = (name: string) => {
  if(!name) return "";
  let working = name.replaceAll("_", " ").replaceAll("-", " ");
  const extensionPos = working.lastIndexOf(".");
  if(extensionPos > 0){
    working = working.substring(0, extensionPos);
  }
  return working
}

/**
 * Pluralizes a word based on the count
 * @param word The word to pluralize
 * @param count The count to determine if pluralization is needed
 * @returns The pluralized word if count is not 1, otherwise the original word
 */
export const pluralize = (word: string, count?: number | null): string => {
  if (count === 1) return word;
  
  // Handle words ending in "y"
  if (word.endsWith("y")) {
      const precedingChar = word[word.length - 2];
      if (precedingChar && "aeiou".includes(precedingChar.toLowerCase())) {
          return word + "s";
      } else {
          return word.slice(0, -1) + "ies";
      }
  }
  
  // Handle words ending in "s", "x", "z", "ch", "sh"
  if (word.endsWith("s") || word.endsWith("x") || word.endsWith("z") || 
      word.endsWith("ch") || word.endsWith("sh")) {
      return word + "es";
  }
  
  // Default case: just add "s"
  return word + "s";
}; 


export default cn;
