// This is a simple utility function that creates an image element and sets the src attribute.
// The image element is then cached in the browser.
// Also avoids CORS errors and canvas taint using crossOrigin="anonymous".
const cachedImageElement = (src: string) => {
    const img = new Image();
    img.crossOrigin = "anonymous"; // for CORS
    img.src = src || "";
    // if (!src) throw Error("no image source");
    if (!src) console.debug("no iamge source");
    return img;
};

export default cachedImageElement;