import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useAppSelector } from "../../hooks";
import { PROJECT_STATUS, USER_TYPES } from "../../lib/constants";
import { useProjectAPI } from "../../api/project_api";
import { useDispatch } from "react-redux";
import { updateProjects, updateProjectsSubmittedForApprovalCount } from "../project/projectsSlice";
import { projectWizardSlice } from "../../pages/project/projectWizardSlice";
import "./header.css";
import useClickOutside from "../../hooks/click_outside";
import { useNavigate } from "react-router-dom";
import { Button } from "../../common/components/ui/button";

const PublicHeader = ({
  minimalHeader,
  theme,
  setOpacity,
}: {
  minimalHeader?: boolean;
  theme?: "dark" | "lite";
  setOpacity?: boolean;
}) => {
  const { user, isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();
  const { profile } = useAppSelector((state: any) => state.user);
  const [scrollTop, setScrollTop] = useState(0);
  const [navOpen, setNavOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeLink, setActiveLink] = useState<string | null>(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { resetProjectWizard } = projectWizardSlice.actions;
  const { fetchProjects } = useProjectAPI();
  const menuRef = useRef<HTMLDivElement>(null);
  const createMenuRef = useRef<HTMLUListElement>(null);
  useClickOutside(menuRef, () => {
    setNavOpen(false);
  });
  useClickOutside(createMenuRef, () => {
    setIsDropdownOpen(false);
  });

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: "https://www.theofficial.ai/",
      },
    });
  };

  useEffect(() => {
    const handleScroll = (event: any) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchProjects().then((projects) => {
        dispatch(updateProjects(projects));
        const count = projects.filter(
          (p) =>
            p.status &&
            [PROJECT_STATUS.SUBMITTED_FOR_APPROVAL.key, PROJECT_STATUS.CHANGES_REQUESTED.key].includes(p.status)
        )?.length;
        dispatch(updateProjectsSubmittedForApprovalCount(count));
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("/dashboard")) {
      if (profile.user_type === USER_TYPES.CREATOR.key) setActiveLink("create");
      else setActiveLink("my-talent");
    } else if (path.includes("/vault") || path.includes("/training-set")) {
      setActiveLink("vaults");
    } else if (path.includes("/create")) {
      setActiveLink("create");
    } else if (path.includes("/boards")) {
      setActiveLink("boards");
    } else if (path.includes("/import")) {
      setActiveLink("import");
    } else if (path.includes("/my-talent")) {
      setActiveLink("my-talent");
    } else if (path.includes("/create-talent")) {
      setActiveLink("create-talent");
    } else if (path.includes("/check-license")) {
      setActiveLink("protect");
    } else {
      setActiveLink(null);
    }
  }, [window.location.pathname, profile.user_type]);

  const colorTheme = useMemo(() => {
    return scrollTop > 10 ? "lite" : theme || "lite";
  }, [scrollTop, theme]);

  const isMarketingPage =
    window.location.pathname.includes("/for-brands") ||
    window.location.pathname.includes("/for-talent") ||
    window.location.pathname == "/";

  const isImageCreatorRole = profile.roles.includes("AI_IMAGE_CREATOR");
  if (isLoading) {
    return null;
  }

  return (
    <div>
      <header
        className={
          "nav_header " +
          colorTheme +
          " " +
          (scrollTop > 10 || setOpacity ? "scroll_background" : "") +
          (minimalHeader ? " minimal_header" : "")
        }
      >
        <button className="menu_button" onClick={() => setNavOpen((old) => !old)}>
          {navOpen ? <XMarkIcon width={32} /> : <Bars3Icon width={32} />}
        </button>
        {navOpen && (
          <div className="mobile_nav" ref={menuRef}>
            <ul className="mobile_nav_links">
              {isAuthenticated && profile.user_type === USER_TYPES.RIGHTS_HOLDER.key && (
                <>
                  <li>
                    <Button
                      variant="link"
                      href="/my-talent"
                      onClick={() => setNavOpen(false)}
                      className="w-full text-left"
                    >
                      My talent
                    </Button>
                  </li>
                  <li>
                    <Button
                      variant="link"
                      href="/add-talent"
                      onClick={() => setNavOpen(false)}
                      className="w-full text-left"
                    >
                      Add talent
                    </Button>
                  </li>
                </>
              )}
              {(!isAuthenticated || isMarketingPage) && (
                <li>
                  <Button
                    variant="link"
                    href="/check-license"
                    onClick={() => setNavOpen(false)}
                    className="w-full text-left"
                  >
                    Verify license
                  </Button>
                </li>
              )}
              {(!isAuthenticated || isMarketingPage) && (
                <li>
                  <Button
                    variant="link"
                    href="/for-talent"
                    onClick={() => setNavOpen(false)}
                    className="w-full text-left"
                  >
                    For Talent
                  </Button>
                </li>
              )}
              {(!isAuthenticated || isMarketingPage) && (
                <li>
                  <Button
                    variant="link"
                    href="/for-brands"
                    onClick={() => setNavOpen(false)}
                    className="w-full text-left"
                  >
                    For Brands
                  </Button>
                </li>
              )}
              {(!isAuthenticated || isMarketingPage) && (
                <li>
                  <Button variant="link" href="/blog" onClick={() => setNavOpen(false)} className="w-full text-left">
                    Blog
                  </Button>
                </li>
              )}
              {isAuthenticated && isImageCreatorRole && (
                <li>
                  <Button variant="link" href="/boards" onClick={() => setNavOpen(false)} className="w-full text-left">
                    Boards
                  </Button>
                </li>
              )}{" "}
              {isAuthenticated && isImageCreatorRole && (
                <li>
                  <Button
                    variant="link"
                    href="/create"
                    onClick={() => setNavOpen(false)}
                    className="w-full text-left"
                  >
                    Create image
                  </Button>
                </li>
              )}
              {isAuthenticated && isImageCreatorRole && (
                <li>
                  <Button variant="link" href="/import" onClick={() => setNavOpen(false)} className="w-full text-left">
                    Import
                  </Button>
                </li>
              )}
              {isAuthenticated && isImageCreatorRole && (
                <li>
                  <Button variant="link" href="/vaults" onClick={() => setNavOpen(false)} className="w-full text-left">
                    Vaults
                  </Button>
                </li>
              )}
              {!isAuthenticated && (
                <li>
                  <Button
                    variant="link"
                    href="https://www.theofficial.ai/get-started"
                    onClick={() => setNavOpen(false)}
                    className="w-full text-left"
                  >
                    Get started
                  </Button>
                </li>
              )}
              {!isAuthenticated && (
                <li>
                  <Button variant="link" onClick={() => loginWithRedirect()} className="w-full text-left">
                    Log in
                  </Button>
                </li>
              )}
              {isAuthenticated && (
                <li>
                  <Button variant="secondary" onClick={handleLogout} className="bg-white w-full text-left">
                    Log out
                  </Button>
                </li>
              )}
            </ul>
          </div>
        )}
        <a href={isMarketingPage ? "/" : "/dashboard"} className="logo min-w-[80px]">
          <img src={colorTheme === "dark" ? "/officialai_logo_black.svg" : "/officialai_logo_white.svg"} alt="logo" />
        </a>
        {!minimalHeader && (
          <>
            {(!isAuthenticated || isMarketingPage) && (
              <ul className="nav_links">
                <li>
                  <Button variant="link" href="/check-license" className="p-0">
                    Verify license
                  </Button>
                </li>
                <li>
                  <Button variant="link" href="https://www.theofficial.ai/talent" className="p-0">
                    For Talent
                  </Button>
                </li>
                <li>
                  <Button variant="link" href="https://www.theofficial.ai/brands" className="p-0">
                    For Brands
                  </Button>
                </li>
                <li>
                  <Button variant="link" href="https://www.theofficial.ai/blog" className="p-0">
                    Blog
                  </Button>
                </li>
                {isAuthenticated && (
                  <li>
                    <Button variant="link" href="/dashboard" className="p-0">
                      Dashboard
                    </Button>
                  </li>
                )}
              </ul>
            )}
            {!isAuthenticated && (
              <ul className="nav_actions">
                <li>
                  <Button href="https://www.theofficial.ai/get-started" className={"pill_button " + theme}>
                    Get started
                  </Button>
                </li>
                <li>
                  <Button variant="link" onClick={() => loginWithRedirect()} className="p-0">
                    Log in
                  </Button>
                </li>
              </ul>
            )}
            {isAuthenticated && (
              <ul className="nav_actions">
                {profile.user_type === USER_TYPES.RIGHTS_HOLDER.key && (
                  <li className={`${activeLink === "my-talent" ? "active" : ""}`}>
                    <Button variant="link" href="/my-talent" className="p-0">
                      My talent
                    </Button>
                  </li>
                )}
                {profile.user_type === USER_TYPES.RIGHTS_HOLDER.key && (
                  <li className={`${activeLink === "add-talent" ? "active" : ""}`}>
                    <Button variant="link" href="/add-talent" className="p-0">
                      Add talent
                    </Button>
                  </li>
                )}
                {isImageCreatorRole && (
                  <>
                    <li className={`${activeLink === "boards" ? "active" : ""}`}>
                      <Button variant="link" href="/boards" className="p-0">
                        Boards
                      </Button>
                    </li>
                    <li className={`${activeLink === "create" ? "active" : ""}`}>
                      <Button variant="link" href="/create" className="p-0">
                        Create
                      </Button>
                    </li>
                    <li className={`${activeLink === "import" ? "active" : ""}`}>
                      <Button variant="link" href="/import" className="p-0">
                        Import
                      </Button>
                    </li>
                    <li className={`${activeLink === "vaults" ? "active" : ""}`}>
                      <Button variant="link" href="/vaults" className="p-0">
                        Vaults
                      </Button>
                    </li>
                    <li className={`${activeLink === "protect" ? "active" : ""}`}>
                      <Button variant="link" href="/check-license" className="p-0">
                        Protect
                      </Button>
                    </li>
                  </>
                )}
                {!isImageCreatorRole && profile.user_type === USER_TYPES.CREATOR.key && (
                  <li className={`${activeLink === "project" ? "active" : ""}`}>
                    <Button
                      variant="link"
                      onClick={() => {
                        dispatch(resetProjectWizard());
                        navigate("/project/new");
                      }}
                      className="p-0"
                    >
                      Create project
                    </Button>
                  </li>
                )}
                <li>
                  <a href="#" className="border border-white rounded-full px-3 py-2" onClick={handleLogout}>
                    Log out
                  </a>
                </li>
              </ul>
            )}
          </>
        )}
      </header>
    </div>
  );
};

export default PublicHeader;
