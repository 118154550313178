import React from "react";
import { IconCheckMark, TrashIcon } from "../../../common/icons/icons";
import cachedImageElement from "../../../lib/cached-image-element";
import { Button } from "../../../common/components/ui/button";
import { TrainingSetFile } from "../../../model/vault";

const SelectableImageCard: React.FC<{
  image: any;
  isSelected: boolean;
  onClick: () => void;
  deleteTrainingSetFile: (file: TrainingSetFile) => void;
}> = ({ image, isSelected, onClick, deleteTrainingSetFile }) => {
  // TODO: Bug where file is showing original file, not updated file id
  // useEffect(() => {
  //     console.log(image);
  // }, [image]);

  // cache in memory prior to using as background-image in case a canvas needs it
  cachedImageElement(image.permalink || image.thumbnail);
  return (
    <div
      className={`relative rounded-lg bg-gray-700 bg-contain bg-center bg-no-repeat image-card ${
        isSelected ? "selected" : ""
      } @container w-[168px] h-[168px] @[400px]:w-[200px] @[400px]:h-[200px] @[800px]:w-[288px] @[800px]:h-[288px]`}
      onClick={onClick}
      style={{ backgroundImage: `url(${image.permalink || image.thumbnail})` }}
    >
      <div
        className={`border-2 rounded-full w-10 h-10 flex items-center justify-center ${
          isSelected ? "bg-primary border-brand-yellow" : "bg-transparent border-white"
        } absolute top-2 right-2 `}
      >
        {isSelected && <IconCheckMark />}
      </div>
      <Button
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          deleteTrainingSetFile(image);
        }}
        className="p-2 self-start border-none hover:bg-transparent fill-white absolute bottom-2 right-2"
        variant="outline"
        type="button"
      >
        <TrashIcon />
      </Button>
    </div>
  );
};
export default SelectableImageCard;
