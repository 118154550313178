import React from "react";
import { Button } from "./ui/button";
import { Sparkles } from "../icons/icons";
import loading_light from "./ui/loading_light.gif";

interface RewriteButtonProps {
  onClick: () => void;
  isLoading?: boolean;
  className?: string;
}

const RewriteButton = ({ onClick, isLoading = false, className = "" }: RewriteButtonProps) => {
  if (isLoading) {
    return (
      <Button
        className={`flex w-[96px] bg-black gap-1 border-gray-600 ] p-2 hover:bg-black hover:text-white fill-white ${className}`}
        type="button"
        variant="outline"
        disabled
      >
        <img className="h-4" src={loading_light} alt="Loading..." />
      </Button>
    );
  }

  return (
    <Button
      onClick={onClick}
      type="button"
      variant="outline"
      className={`flex gap-1 bg-black w-[96px] border-gray-600 p-2 hover:bg-black hover:text-white fill-white ${className}`}
    >
      <>
        <Sparkles />
        Rewrite
      </>
    </Button>
  );
};

export default RewriteButton; 