import React, { useEffect, useState } from "react";
import ProgressBar from "../../../common/components/ui/progress_bar";
import { useGenerativeModelAPI } from "../../../api/generative_model_api";

// Not a very elegeant way to refresh for both the index card and the edit screen.
// There can also be a delay between the progress bar reaching 100% and the status changing to active
// which may cause multiple repeating refreshes, so 25s delay.
const defaultRefreshOnCompleteFn = () => {
  setTimeout(() => window.location.reload(), 25000);
};

const TrainingProgressBar = ({ taskQueueId, status, refresh = defaultRefreshOnCompleteFn }: { taskQueueId: string; status: string; refresh?: () => void; }) => {
  const generativeModelAPI = useGenerativeModelAPI();
  const [percent, setPercent] = useState(0);
  const [lastTimeout, setLastTimeout] = React.useState<any | undefined>(undefined);
  const [failed, isFailed] = React.useState<boolean>(false);

  const taskId = taskQueueId;
  let content =
    status !== "TRAINING" || !taskId ? null : <ProgressBar progress={percent} />;
  if (failed)
    content = <div className="text-red-500">Training failed</div>;

  const refreshProgress = (taskId: string) => {
    generativeModelAPI.checkTaskQueueStatus(taskId).then((response) => {
      // we're getting an array of statuses here, we want the last one
      const lastProgressMessage = response?.[response.length - 1];
      setPercent(lastProgressMessage?.percent_complete);
      if (lastProgressMessage?.percent_complete === 100 && status === "TRAINING") {
        setLastTimeout(undefined);
        if (refresh) {
          refresh();
        }
      }
      if (response?.[response.length - 1]?.status === "failed") {
        isFailed(true);
      }
    });
  };

  useEffect(() => {
    if (taskId && status === "TRAINING" && (percent < 100) && !failed) {
      if (!lastTimeout) {
        refreshProgress(taskId);
      }

      const timeout = setTimeout(() => {
        refreshProgress(taskId);
        setLastTimeout(timeout);
      }, 5000);
    }
    return () => {
      if (lastTimeout) {
        clearTimeout(lastTimeout);
      }
    };
  }, [taskId, status, lastTimeout]);

  return <div>{content}</div>;
};

// pass in (model) id or taskQueueId, but not both
const TrainingProgressBarWithTaskLoader = ({ id, taskQueueId, status, refresh }: { id?: string; taskQueueId?: string; status: string; refresh?: () => void; }) => {
  const generativeModelAPI = useGenerativeModelAPI();
  const [resolvedTaskQueueId, setResolvedTaskQueueId] = useState<string | undefined>(taskQueueId);

  useEffect(() => {
    if (status === "TRAINING" && id) {
      generativeModelAPI.getModelById(id).then((vault) => {
        setResolvedTaskQueueId(vault.task_queue_id);
      });
    }
  }, [id]);

  return resolvedTaskQueueId ? <TrainingProgressBar taskQueueId={resolvedTaskQueueId} status={status} refresh={refresh} /> : null;
};

export default TrainingProgressBarWithTaskLoader;
