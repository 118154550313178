import React from "react";
import "./home.css";
import AppFooter from "../layouts/app_footer";
import { Button } from "../../common/components/ui/button";
import PublicHeader from "../layouts/public_header";

const HomePage = () => {
    return (
        <main className="page_container home_page" >
            <PublicHeader theme="dark" />
            <div className="home_container">
                <div className="protect_block">
                    <div className="inner_protect_block">
                        <h1 className="text-xxxxxl-serif">
                            Create with confidence
                        </h1>
                        <h3 className="text-xl-sans mb-10">Authentic AI Content for Brands &amp; Talent.</h3>
                        <Button href="/register" variant="primary-negative" className="home_get_started md:px-[102px] md:py-[38px] text-lg">
                            Get started
                        </Button>
                    </div>
                </div>
                <div className="block_2">
                    {/* <div className="pixel_lg pixel_blue"> */}
                    {/* <div className="primary_image rounded-[40px]"> */}
                    {/* <div className="pixel_lg pixel_blue" /> */}
                    <div className="flex justify-center">
                        <div className="background_pixel_1 pixel_blue mt-96 md:mt-0"></div>
                        {/*     position: relative;
    min-width: 443px;
    display: flex;
    justify-content: center; */}
                        <video autoPlay loop muted
                            style={{
                                boxShadow: "0px 0px 9px 9px rgba(0, 0, 0, 0.04)"
                            }}
                            className="sm:h-[517px] md:h-[769px] rounded-[40px] relative flex justify-center ">
                            <source src="https://oai-app-public.s3.us-west-2.amazonaws.com/www/images/home_1.mp4" type="video/mp4" />
                        </video>
                    </div>
                    {/* </div> */}
                    {/* <div className="pixel_sm pixel_red pixel_1" />
                        <div className="pixel_sm pixel_pink pixel_2" />
                        <div className="pixel_sm pixel_yellow pixel_3" />
                        <div className="pixel_sm pixel_black pixel_4" />
                        <div className="pixel_md pixel_blue pixel_5" />
                        <div className="pixel_md pixel_pink pixel_6" />
                        <img className="primary_image" src={require("./images/block_2.png")} alt="Sample Image 1" />
                        <div className="pixel_lg pixel_black pixel_7">
                            <img src={"/officialai_logo_white_badge.svg"} alt="OfficialAI Logo Badge" />
                        </div> */}
                    {/* </div> */}
                </div>
                <div className="block_3">
                    <div className="block_3_header_left">
                        {/* <img className="block_3_header_left_img_1" src={require("./images/block_3_img_1.png")}
                            width="320" height="320" alt="Sample Image 1" /> */}
                        <div className="block_3_pixel_group">
                            <div className="pixel_md pixel_transparent" />
                            <div className="pixel_md pixel_blue block_3_pixel_1" />
                            <div className="pixel_md pixel_blue block_3_pixel_1" />
                            <div className="pixel_md pixel_blue block_3_pixel_1" />
                            <div className="pixel_md pixel_yellow block_3_pixel_1" />
                        </div>
                    </div>
                    <div className="block_3_header_right">
                        <div className="block_3_pixel_group_2">
                            <div className="pixel_sm pixel_transparent" />
                            <div className="pixel_sm pixel_white" />
                            <div className="pixel_sm pixel_yellow" />
                            <div className="pixel_sm pixel_transparent" />
                            <div className="pixel_sm pixel_yellow" />
                        </div>
                    </div>
                </div>
                <div className="block_4">
                    <div className="block_4_left">
                        <div className="block_4_pixel_group">
                            <div className="pixel_md pixel_transparent" />
                            <div className="pixel_md pixel_pink" />
                            <div className="pixel_md pixel_red" />
                            <div className="pixel_md pixel_transparent" />
                            <div className="pixel_md pixel_red" />
                        </div>
                    </div>
                    <div className="block_3_content text-white">
                        {/* TODO fix */}
                        <span className="italic text-3xl">Powered by our vault technology</span><br />
                        <span className="text-xxxxl-sans text-normal">Our patented vault technology enables brands and talent to create photorealistic AI content together - securely and safely.</span>
                    </div>
                    <div className="block_4_right">
                        <div className="block_4_image_group">
                            {/* <img src={require("./images/block_3_img_2.png")} alt="Sample Image 2" className="block_4_image_main" />
                            <img src="/officialai_logo_white_badge.svg" alt="OfficialAI Logo Badge" width="50" className="badge_overlay" /> */}
                            <div className="pixel_sm pixel_blue block_4_pixel_1" />
                        </div>
                    </div>
                </div>
                <div className="block_5">
                    <div className="block_5_left">
                        <div className="block_5_image_group_1">
                            {/* <img src={require("./images/block_5_img_1.png")} alt="Sample Image 3" width="242" height="242" className="block_5_image_main" />
                            <img src="/officialai_logo_white_badge.svg" alt="OfficialAI Logo Badge" width="50" className="block_5_image_1_badge_overlay" /> */}
                            <div className="pixel_sm pixel_yellow block_5_image_group_1_pixel_1" />
                            <div className="pixel_sm pixel_white block_5_image_group_1_pixel_2" />
                        </div>
                    </div>
                    <div className="block_5_right">
                        <div className="block_5_image_group_2">
                            {/* <img src={require("./images/block_5_img_2.1.png")} alt="Sample Image 3" className="block_5_image_2_main" />
                            <img src="/officialai_logo_white_badge.svg" alt="OfficialAI Logo Badge" width="50" className="block_5_image_2_badge_overlay" /> */}
                            <div className="pixel_md pixel_white block_5_image_group_2_pixel_1" />
                        </div>
                        <div className="pixel_md pixel_pink block_5_right_pixel_1" />
                    </div>
                </div>
                <div className="block_6">
                    <div className="subblock pixel_blue">
                        <h3 className="text-xxxxl-sans text-white block_title">Create.</h3>
                        <ul className="text-white text-md-sans">
                            <li>Create photorealistic images with real talent and your products on-demand.</li>
                            <li>Turn single photos into unlimited brand content.</li>
                            <li>Modify content instantly without reshoots.</li>
                            <li>Unlock content that was previously cost-prohibitive.</li>
                        </ul>
                    </div>
                    <div className="subblock pixel_red">
                        <h3 className="text-xxl-sans text-white block_title">Protect.</h3>
                        <ul className="text-white text-md-sans">
                            <li>Secure your IP with our patented vault technology.</li>
                            <li>Set clear usage parameters with provenance technology.</li>
                            <li>Approve or reject all requests to maintain brand integrity.</li>
                            <li>Ensure authenticity with built-in rights management.</li>
                        </ul>
                    </div>
                    <div className="subblock subblock_p0">
                        <div className="block_6_image_group_1">
                            {/* <img src={require("./images/block_6_img_1.png")} alt="Sample Image 4" width="200"
                                height="200" className="block_6_image_main" /> */}
                            <div className="pixel_sm pixel_yellow block_6_image_group_1_pixel_1" />
                            <div className="pixel_sm pixel_yellow block_6_image_group_1_pixel_2" />
                        </div>
                    </div>
                    <div className="subblock pixel_pink">
                        <h3 className="text-xxl-sans text-blackish block_title">Collaborate.</h3>
                        <ul className="text-blackish text-md-sans">
                            <li>Connect brands with authorized talent seamlessly.</li>
                            <li>Streamline approvals and creative workflows.</li>
                            <li>Create content 24/7 without scheduling conflicts.</li>
                            <li>Turn one-off shoots into ongoing creative partnerships.</li>
                        </ul>
                    </div>
                    <div className="subblock pixel_white subblock_2">
                        <div className="block_6_image_group_2">
                            <div className="pixel_md pixel_yellow" />
                            <div className="pixel_md pixel_transparent" />
                            <div className="pixel_md pixel_transparent" />
                            <div className="pixel_md pixel_transparent" />
                            <div className="pixel_md pixel_blue" />
                            <div className="pixel_md pixel_blue" />
                        </div>
                    </div>


                </div>
                <div className="block_7">
                    <div className="block_7_left pixel_white">
                        <div className="block_7_left_content">
                            {/* TODO p font */}
                            <h3 className="text-xl-sans">Talent</h3>
                            <p className="text-md-sans py-5">Protect and monetize your digital likeness. Create authentic content on your terms through your secure vault library.</p>
                            <Button href="/register" variant="primary-negative">Get started</Button>
                            <h3 className="text-xl-sans mt-[2em]">Brands</h3>
                            <p className="text-md-sans py-5">Create authentic campaigns with real talent instantly. Transform single photos into unlimited brand content - safely and securely.</p>
                            <Button href="/register" variant="primary-negative">Get started</Button>
                        </div>
                    </div>

                    <div className="block_7_right pixel_white">
                        <div className="block_7_image_group_2">
                            <img src={require("./images/block_7_img_2.2.png")} alt="Sample Image 5"
                                className="block_7_image_2_main" />
                            <div className="pixel_sm pixel_pink block_7_image_group_2_pixel_1" />
                            <div className="pixel_md pixel_red block_7_image_group_2_pixel_2" />
                        </div>
                    </div>
                </div>
                <div className="block_8 pixel_white pt-[2em] pl-[2em]">
                    <h1 className="text-xxl-sans text-black">In the news.</h1>
                    <div className="news_block text-blackish">
                        <div className="news_article">
                            <div className="news_publication">
                                GeekWire
                            </div>
                            <div className="news_title">
                                <a href="https://www.geekwire.com/2024/column-to-navigate-the-promise-and-peril-of-ai-resist-the-urge-to-fall-into-extreme-narratives/"
                                    target={"_blank"} rel="noreferrer">
                                    Column: To navigate the promise and peril of AI, resist the urge to fall into extreme narratives</a>
                            </div>
                        </div>
                        <div className="news_article">
                            <div className="news_publication">
                                GeekWire
                            </div>
                            <div className="news_title">
                                <a href="https://www.geekwire.com/2024/seattle-startups-tackle-deepfake-and-digital-likeness-issues-spurred-by-generative-ai/"
                                    target={"_blank"} rel="noreferrer">
                                    These startups are tackling deepfake and digital likeness issues spurred by
                                    generative AI</a>
                            </div>
                        </div>
                        <div className="news_article">
                            <div className="news_publication">
                                Adweek
                            </div>
                            <div className="news_title">
                                <a href="https://www.adweek.com/media/12-hot-ai-startups-that-brands-are-working-with/"
                                    target={"_blank"} rel="noreferrer">
                                    12 Hot AI Startups That Brands Are Working With</a>
                            </div>
                        </div>
                        <div className="news_article">
                            <div className="news_publication">Variety</div>
                            <div className="news_title">
                                <a href="https://variety.com/2024/music/news/billie-eilish-nicki-minaj-ai-respect-artists-rights-1235957451/"
                                    target={"_blank"} rel="noreferrer">Billie Eilish, Nicki Minaj, Stevie Wonder, Dozens
                                    More Call on AI Developers to Respect Artists’ Rights</a>
                            </div>
                        </div>
                        <div className="news_article">
                            <div className="news_publication">Music Business Worldwide</div>
                            <div className="news_title">
                                <a href="https://www.musicbusinessworldwide.com/elvis-act-signed-into-law-in-tennessee-to-protect-artists-voice-and-likeness-from-the-misuse-of-ai/"
                                    target={"_blank"} rel="noreferrer">ELVIS Act signed into law in TN</a>
                            </div>
                        </div>
                        <div className="news_article">
                            <div className="news_publication">Geekwire</div>
                            <div className="news_title">
                                <a href="https://www.geekwire.com/2024/the-impact-of-ai-and-deepfakes-on-politics-is-worrying-misinformation-researchers/"
                                    target={"_blank"} rel="noreferrer">Impact of AI on politics</a>
                            </div>
                        </div>
                        <div className="news_article">
                            <div className="news_publication">Bloomberg</div>
                            <div className="news_title">
                                <a href="https://news.bloomberglaw.com/ip-law/george-carlin-estate-files-publicity-rights-suit-over-ai-routine"
                                    target={"_blank"} rel="noreferrer">George Carlin Estate Files Publicity Rights Suit Over AI Routine</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block_9 pixel_yellow p-[4em]">
                    <h1 className="text-xxxl-sans text-black text-center">
                        We&rsquo;re building an ecosystem for authenticity to ignite the next creative renaissance.
                    </h1>
                </div>

            </div>
            <AppFooter />

            <script
                src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6541f3a0f6480d2cb5573c56"
                type="text/javascript" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
                crossOrigin="anonymous"></script>

            <script src="https://assets-global.website-files.com/6541f3a0f6480d2cb5573c56/js/webflow.9b9a1d58f.js"
                type="text/javascript"></script>
        </main>

    )
}
export default HomePage;