import React, { useState, useRef, useEffect } from "react";
import { File } from "../../../model/file";
import { SliderLeft, SliderRight, SelectedGenIcon } from "../../../common/icons/icons";
import loading from "../../../common/components/ui/loading.gif";
import { Button } from "../../../common/components/ui/button";

// TODO: combine this with the version history slider
const ImageSlider = ({
    files,
    selectedFile,
    setSelectedFile,
    showLoadingImage,
    handleDragStart
}: {
    files: File[],
    selectedFile?: File | null,
    setSelectedFile: (variant: File) => void,
    showLoadingImage?: boolean,
    handleDragStart?: (file: File, e: React.DragEvent<HTMLImageElement>, img: { id: string, src: string }) => void
}) => {
    // const [currentVariant, setCurrentVariant] = useState(selectedVariant);
    const [isOverflow, setIsOverflow] = useState(false);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const sliderRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const checkOverflow = () => {
            if (sliderRef.current) {
                const clientWidth = sliderRef.current.clientWidth;
                const scrollWidth = sliderRef.current.scrollWidth;

                const overflow = scrollWidth > clientWidth;
                setIsOverflow(overflow);

                if (sliderRef.current) {
                    setCanScrollLeft(sliderRef.current.scrollLeft > 0);
                    setCanScrollRight(
                        sliderRef.current.scrollLeft + clientWidth < scrollWidth
                    );
                }
            }
        };

        checkOverflow();

        window.addEventListener("resize", checkOverflow);

        const currentRef = sliderRef.current;
        const handleScroll = () => checkOverflow();
        currentRef?.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("resize", checkOverflow);
            currentRef?.removeEventListener("scroll", handleScroll);
        };
    }, [files]);

    const scroll = (direction: "left" | "right") => {
        if (sliderRef.current) {
            const scrollAmount = sliderRef.current.clientWidth * 0.8;
            sliderRef.current.scrollBy({
                left: direction === "left" ? -scrollAmount : scrollAmount,
                behavior: "smooth"
            });
        }
    };

    const startDrag = (file: File, e: React.DragEvent<HTMLImageElement>, img: { id: string, src: string }) => {
        if (handleDragStart) {
            handleDragStart(file, e, img);
        }
    }

    return (
        <div className="flex flex-row gap-3">
            <div className="flex flex-row gap-3 w-11/12 overflow-x-auto" ref={sliderRef}
                style={{
                    scrollbarWidth: "none",
                    msOverflowStyle: "none"
                }}>
                {files.map((file, index) => (
                    <div key={index} className="relative shrink-0">
                        <img
                            crossOrigin="anonymous" onClick={
                                () => {
                                    setSelectedFile(file);
                                }} className={`bg-gray-400 w-[116px] object-cover h-36 rounded-lg ${file === selectedFile ? "border-4 border-brand-yellow" : ""}`}
                            src={file.permalink}
                            onDragStart={(e) => startDrag(file, e, { id: file.id || "", src: file.permalink || "" })} />

                        {file === selectedFile &&
                            <div className="absolute top-2 right-2"> <SelectedGenIcon /> </div>}
                    </div>
                ))}
                {!isOverflow && showLoadingImage && (
                    <div className="w-[116px] bg-brand-yellow rounded-lg flex justify-center items-center">
                        <img
                            className="h-10"
                            src={loading}
                            alt="Loading..."
                        />
                    </div>
                )}
            </div>
            {isOverflow && showLoadingImage && (
                <div className="w-[116px] bg-brand-yellow rounded-lg flex flex-shrink-0 justify-center items-center">
                    <img
                        className="h-10"
                        src={loading}
                        alt="Loading..."
                    />
                </div>
            )}

            {isOverflow && files.length > 1 && (
                <div className="flex flex-col gap-3 justify-center">
                    <Button variant="outline" disabled={!canScrollRight} className=" px-3 stroke-white border-brand-gray-500 hover:bg-transparent hover:border-white" onClick={() => scroll("right")}>
                        <SliderRight />
                    </Button>
                    <Button variant="outline" disabled={!canScrollLeft} className="px-3 stroke-white border-brand-gray-500 hover:bg-transparent hover:border-white" onClick={() => scroll("left")}>
                        <SliderLeft />
                    </Button>
                </div>

            )}
        </div>
    )
}

export default ImageSlider;