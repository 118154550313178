import React from "react";
import Panel from "./panel";
import RadioCardGroup from "../../../../common/components/radio-card-group";
import { aspectRatioOptions } from "../../models/constants";
import { GenerateMediaResult } from "../../models/image_generator";
import { PANELS } from "../panelConfig";
import { PanelId } from "../../models/image_generator";

const title = PANELS.find((panel) => panel.id === PanelId.AspectRatio)?.description || "Aspect Ratio";

const AspectRatioPanel = ({ generativeData, handleAspectRatioChange, closePanel, expanded, helperText, handleToggleExpand }: {
    expanded: boolean | undefined,
    helperText?: string,
    handleToggleExpand: () => void,
    generativeData: GenerateMediaResult | null, handleAspectRatioChange: (aspectRatio: string) => void, closePanel: () => void;
}) => {
    return (
        <Panel helperText={helperText} toggleExpanded={handleToggleExpand} isExpanded={expanded} title={title} closePanel={closePanel}>
            <RadioCardGroup options={aspectRatioOptions} selected={generativeData?.aspect_ratio ?? "SQUARE"}
                onSelect={handleAspectRatioChange} />
        </Panel>
    );
};

export default AspectRatioPanel;