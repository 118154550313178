export interface GenerativeModel {
    id: string;
    group_name: string;
    name: string;
    description: string;
    thumbnail_image: string;
    entity_type: "LICENSABLE_PROPERTY" | "USER_PRODUCT" | "BRAND";
    entity_id: string;
    status: string;
    generative_tag: string;
    model_type: string; //lora type
    base_model: string;
    stat_access_count?: number;
    stat_usage_count?: number;
    is_public?: boolean;
    quality_score?: number;
    created_at?: string;
    training_set_id?: string;
    task_queue_id?: string;
}
export interface GenerateMediaResult {
    id?: string;
    prompt: string;
    inpaint_prompt?: string;
    height: number;
    width: number;
    aspect_ratio: AspectRatioOptions["value"];
    base_model: string;
    model_weights: GenerativeModelWeight[];
    outputs: GenerativeOutput[];
    seed?: number;
    task_queue_id?: string;
    action?: string;
    parentTaskId?: string;
}
export interface GenerativeOutput {
    generative_output_id: string;
    asset_type: string;
    status?: string;
    file_id: string;
    permalink: string;
    created_at: string;
    thumbnail_image?: string;
    task_queue_id?: string;
    // seed?: number
}

export interface VersionHistory extends GenerativeOutput {
    action?: string;
    parentTaskId?: string;
    seed?: number;
    task_queue_id?: string;
    aspect_ratio?: string;
    height?: number;
    width?: number;
    prompt?: string;
    inpaint_prompt?: string;
}

export interface VersionHistoryWithMedia extends VersionHistory {
    media?: HTMLImageElement | HTMLVideoElement;
}

export interface AspectRatioOptions {
    key: string,
    value: string,
    label: string,
    description: string,
    default: boolean,
}

export interface GenerativeModelWeight {
    id: string;
    file_path?: string;
    weight: number;
}
export interface GenerateImageRequest {
    aspect_ratio: AspectRatioOptions["value"];
    prompt: string;
    image_file_id?: string;
    model_weights: GenerativeModelWeight[];
    seed?: number;
    mask?: string;
    num_images_per_prompt?: number;
    parent_task_id?: string;
    action?: string;
    inpaint_prompt?: string;
    inpaint_image_file_id?: string | null;
    quality?: "low" | "medium" | "high" | undefined;
    video_length?: number;
    video_fps?: number;
}

export interface TaskQueueResponse {
    id: string;
    parentId?: string;
    name?: string;
    status: "queued" | "completed" | "processing" | "failed";
    result: string;
    payload?: string;
    children?: TaskQueueResponse[];
    output?: GenerativeOutput[];
}

export interface EnhancedPrompt {
    original: string;
    enhanced: string;
}

export interface UpscaleRequest {
    generative_output_id: string;
    file_id: string;
    seed?: number;
    parent_task_id?: string;
}

export interface TextureRequest {
    generative_output_id: string;
    file_id: string;
    intensity?: number;
    seed?: number;
    parent_task_id?: string;
}

export interface SelectedModels {
    product: GenerativeModel | null;
    brand: GenerativeModel | null;
    talent: GenerativeModel | null;
}

export interface InitImageRequest {
    file_id: string;
    parent_task_id?: string;
}

export enum PanelId {
    Description = "Description",
    Versions = "Versions",
    EditDirectly = "EditDirectly",
    DragAndDrop = "DragAndDrop",
    Zoom = "Zoom",
    Drag = "Drag",
    AspectRatio = "AspectRatio",
    Upscale = "Upscale",
    NaturalLook = "NaturalLook",
    Actions = "Actions",
    History = "History",
    Save = "Save",
    FavoriteImage = "FavoriteImage",
    DownloadImage = "DownloadImage",
    Feedback = "Feedback",
    Video = "Video",
}


export interface Panel {
    id: PanelId | string;
    name: string;
    icon?: React.ReactNode; // not required for divider
    description: string;
    isShort?: boolean;
    active?: boolean;
    isExpanded?: boolean;
    details?: string;
    addIconStroke?: boolean;
    isDivider?: boolean;
    isDisabled?: boolean;
}

export interface ImageTask {
    type?: string;
    value?: any;
}
export interface BoardDTO {
    id?: string;
    name: string;
    description?: string;
    created_at: string;
    updated_at?: string;
    is_default?: boolean;
    is_favorite?: boolean;
    is_deletable?: boolean;
    generative_outputs?: GenerativeOutput[];
}
export interface UserFeedback {
    // id: string;
    rating: number;
    feedback?: string;
    is_public?: boolean;
    entity_type?: string;
    entity_id?: string;
    created_at: string;
}

export interface DroppedImage {
    id: number;
    src: string;
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
}
