export const aspectRatioOptions = [
    {
        key: "SQUARE",
        value: "SQUARE",
        label: "1:1",
        description: "Square",
        default: true,
    },
    {
        key: "WIDESCREEN",
        value: "WIDESCREEN",
        label: "16:9",
        description: "Widescreen",
    },
    {
        key: "ULTRA_WIDESCREEN",
        value: "ULTRA_WIDESCREEN",
        label: "21:9",
        description: "Ultrawide",
    },
    {
        key: "MEDIUM",
        value: "MEDIUM",
        label: "5:4",
        description: "Medium format",
    },
    {
        key: "VERTICAL",
        value: "VERTICAL",
        label: "9:16",
        description: "Vertical",
    },
    {
        key: "TALL",
        value: "TALL",
        label: "9:21",
        description: "Tall",
    },
];

export const calculateAspectRatioLabel = (width: number, height: number): string => {
    const actualAspectRatio = width / height;
    const aspectRatioDetail = aspectRatioOptions.find((option) => {
        // Convert option label to ratio (e.g., "1:1" -> 1, "16:9" -> 16/9)
        const [w, h] = option.label.split(":").map(Number);
        const optionRatio = w / h;
        // Allow for small floating point differences
        return Math.abs(actualAspectRatio - optionRatio) < 0.01;
    });

    return aspectRatioDetail ? `${aspectRatioDetail.label} ${aspectRatioDetail.description}` : "Unknown";
};

export default aspectRatioOptions;
