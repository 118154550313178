import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useTrainingSetAPI } from "../../api/training_set_api";
import { useGenerativeModelAPI } from "../../api/generative_model_api";
import { useFileAPI } from "../../api/file_api";
import { Button } from "../../common/components/ui/button";
import { GenerativeModel } from "../image-generator/models/image_generator";
import { File as FileModel } from "../../model/file";
import { LockIcon, UnlockIcon, TrashIcon, Plus, PencilIcon } from "../../common/icons/icons";
import { DialogModal } from "../../common/components/ui/dialog_modal";
import bit_image from "../../common/components/ui/bit_image.png";
import ImageDisplay from "./components/images-display";
import { TrainingSet } from "../../model/vault";
import { useUserProductAPI } from "../../api/user_product_api";
import DeleteModal from "../../common/components/ui/delete-modal";
import { ThemeProvider } from "../../contexts/theme_context";
import ActionFooter from "../../common/components/ui/action-footer";
import Toggle from "../../common/components/toggle";
import { useToast } from "../../common/components/ui/use_toast";
import TrainingProgressBar from "./components/training_progress_bar";

const VaultPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const { id } = params;
  const navigate = useNavigate();
  const { toast } = useToast();
  const [vault, setVault] = useState<GenerativeModel>();
  const [smartAssets, setSmartAssets] = useState<FileModel[]>([]);
  const [trainingSet, setTrainingSet] = useState<TrainingSet>({ files: [] });
  const [modalType, setModalType] = React.useState<"edit" | "delete" | "confirm" | null>(null);
  const generativeModelAPI = useGenerativeModelAPI();
  const [taskQueueId, setTaskQueueId] = useState<string | undefined>(undefined);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [showThirdButton, setShowThirdButton] = useState(false);

  const { register, handleSubmit, setValue, reset, watch, control } = useForm<GenerativeModel>();

  const [previewImage, setPreviewImage] = React.useState<string | null>(null);
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [saving, setSaving] = React.useState<boolean>(false);
  const { uploadFile } = useFileAPI();
  const { fetchFilesByUsage } = useUserProductAPI();
  const { getTrainingSet } = useTrainingSetAPI();
  const status = watch("status");

  useEffect(() => {
    if (id) {
      generativeModelAPI.getModelById(id).then((vault) => {
        setVault(vault);
        reset(vault);
        setTaskQueueId(vault.task_queue_id);
        if (vault.thumbnail_image) {
          setPreviewImage(vault.thumbnail_image);
        }
        fetchFilesByUsage({ id: vault.entity_id, usage: "inpaint_asset" }).then((files) => {
          setSmartAssets(files);
        });
      });
    }
  }, [id]);

  useEffect(() => {
    if (!vault?.training_set_id) return;
    getTrainingSet(vault.training_set_id).then((response) => {
      setTrainingSet(response);
    });
  }, [vault]);

  useEffect(() => {
    setIsEmpty(trainingSet.files.length === 0 && smartAssets.length === 0);
    // Show third button if there are training files
    setShowThirdButton(trainingSet.files && trainingSet.files.length > 0);
  }, [trainingSet.files, smartAssets]);

  const handleVaultDelete = async () => {
    if (id) {
      try {
        await generativeModelAPI.deleteModel(id);
        setModalType(null);
        navigate("/vaults");
        showToast("Vault deleted successfully");
      } catch (error) {
        console.error("Failed to delete vault:", error);
        showToast("Failed to delete vault. Please try again.");
      }
    }
  };

  const showToast = (message: string) => {
    toast({
      title: message,
      variant: "default",
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setPreviewImage(url);
      setSelectedFile(file);
      setValue("thumbnail_image", file.name);
    }
  };

  const onSubmit = async (data: GenerativeModel) => {
    if (!vault) return;
    if (!data.name) {
      showToast("Please enter a name for your vault");
      return;
    }

    if (selectedFile) {
      try {
        const uploadRequest = {
          files: [selectedFile],
          is_public: true,
          object_id: vault.entity_id,
          object_type: vault.entity_type,
          generate_thumbnail: true,
          resize: "medium",
        };
        setUploading(true);
        const uploadedFiles = await uploadFile(uploadRequest, (progress) => {
          console.log("Upload progress:", progress);
        });

        // Assuming the API returns an array of UploadedFile objects,
        // use the thumbnail URL from the first uploaded file.
        if (uploadedFiles.length > 0) {
          data.thumbnail_image = uploadedFiles[0].thumbnail || uploadedFiles[0].permalink || "";
        }
        setUploading(false);
      } catch (error) {
        console.error("File upload failed", error);
      }
    }

    setSaving(true);

    await generativeModelAPI.saveModel(data).then((updatedData) => {
      showToast("Vault saved successfully");
      setModalType(null);
      reset(updatedData);
    });
    setSaving(false);
  };

  const startTraining = async () => {
    if (!id) return;
    try {
      await generativeModelAPI.trainModel(id);
      setModalType(null);
      showToast("Training has started. Please allow up to 1 hour for it to complete.");
      setValue("status", "TRAINING");
      setTaskQueueId(undefined);

      // Wait 3 seconds before fetching the updated model
      setTimeout(async () => {
        const vault = await generativeModelAPI.getModelById(id);
        setTaskQueueId(vault.task_queue_id);
      }, 3000);
    } catch (error) {
      showToast("Failed to start training. Please try again.");
      console.error("Error starting training:", error);
    }
  };

  return (
    <div className="page_content px-[60px] pt-[20px] pb-24 space-y-10">
      <DialogModal
        isOpen={modalType !== null}
        onOpenChange={() => setModalType(null)}
        variant="default"
        customClass
        removeFooter
        onClose={() => {
          setModalType(null);
        }}
      >
        <ThemeProvider defaultTheme="light">
          {modalType === "edit" && (
            <div className="flex flex-row w-full justify-between items-center">
              <img className="rounded-lg w-1/2" src={bit_image} />
              <div className="flex flex-col gap-8 p-8 w-full">
                <div className="flex justify-between items-center">
                  <h1 className="text-3xl font-medium">Edit Vault</h1>
                  <Toggle
                    darkMode={false}
                    onToggle={(value) => setValue("is_public", value)}
                    checked={watch("is_public") ?? false}
                    labelLeft="Unlocked"
                    labelRight="Locked"
                  />
                </div>

                <div className="space-y-6">
                  <div className="flex flex-col gap-2">
                    <label className="text-base font-serif italic" htmlFor="name">
                      Name
                    </label>
                    <input
                      className="bg-transparent pb-2 border-b-2 border-b-gray-300 font-sans 
                              focus:outline-none focus:ring-0"
                      type="text"
                      placeholder="Enter vault name..."
                      id="name"
                      {...register("name")}
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <label className="text-base font-serif italic" htmlFor="description">
                      Description
                    </label>
                    <textarea
                      className="bg-gray-100 rounded-md font-body h-32 border-none p-4 w-full 
                              resize-none overflow-y-auto focus:ring-0 outline-none focus:shadow-none"
                      placeholder="Enter vault description..."
                      id="description"
                      {...register("description")}
                    />
                  </div>
                </div>
                <div className="flex gap-3 mt-6">
                  <Button
                    className="text-blackish border-gray-300"
                    variant="outline"
                    onClick={() => setModalType(null)}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary-negative" onClick={handleSubmit(onSubmit)}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          )}
          {modalType === "delete" && (
            <DeleteModal modalType={"vault"} closeModal={() => setModalType(null)} handleDelete={handleVaultDelete} />
          )}
          {modalType === "confirm" && (
            <div>
              <div>
                <img className="rounded-lg" src={bit_image} />
                <div className="flex flex-col p-4 space-y-7">
                  <h1 className="text-2xl">Are you sure you want to start training?</h1>
                  <p>This process may take up to several hours and your vault won&apos;t be usable during this time</p>
                  <Button className="self-start w-auto" variant="primary-negative" onClick={startTraining}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          )}
        </ThemeProvider>
      </DialogModal>
      <div className="flex flex-row justify-between items-center flex-wrap">
        <div className="space-y-4">
          <h1 className="text-5xl text-white">{vault?.name ? vault.name : "Edit vault"}</h1>
          <div className="flex flex-row items-center gap-2 text-white text-base font-serif italic">
            <span>Status:</span>
            {!status && <span>Not saved</span>}
            {status && <span>{status}</span>}
            {taskQueueId && <TrainingProgressBar taskQueueId={taskQueueId} status={status} />}
          </div>
          <div className="flex h-6 items-center gap-1 py-1 rounded-full text-[10px] tracking-[1.2px] font-semibold">
            {vault?.is_public ? (
              <div className="bg-brand-yellow border border-brand-yellow text-black rounded-full px-3 py-1.5 flex items-center gap-1">
                <UnlockIcon />
                <span>UNLOCKED</span>
              </div>
            ) : (
              <div className="text-white border border-white rounded-full px-3 py-1.5 flex items-center gap-1">
                <LockIcon />
                <span>LOCKED</span>
              </div>
            )}
          </div>
        </div>
        <div className="flex gap-2">
          <Button aria-label="Edit" className="h-11 w-11 border-none" onClick={() => setModalType("edit")}>
            <div>
              <PencilIcon />
            </div>
          </Button>

          <Button aria-label="Delete" onClick={() => setModalType("delete")} className="h-11 w-11 border-none">
            <div>
              <TrashIcon />
            </div>
          </Button>
        </div>
      </div>

      {vault?.description && (
        <div className="flex flex-row gap-2">
          <div className="flex flex-col gap-6 w-3/4">
            <div className="text-white flex flex-col gap-4 w-full">
              <h2 className="text-white text-xl">Vault Description</h2>
              <p className="font-body">{vault?.description}</p>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col gap-4" id="thumbnail_image_container">
        <h2 className="text-white text-xl">Vault Cover Image</h2>
        <div className="flex flex-row gap-10 items-center">
          {previewImage && (
            <div
              className="relative rounded-[20px] cursor-pointer h-40 w-40 flex items-center justify-center"
              style={{
                backgroundImage: `url(${previewImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <Controller
                name="thumbnail_image"
                control={control}
                render={({ field }) => (
                  <>
                    <input
                      type="file"
                      id="thumbnail_image"
                      accept="image/*"
                      onChange={(val) => {
                        field.onChange(val?.target?.files == null ? "" : val?.target?.files[0]?.name);
                        handleFileChange(val);
                      }}
                      ref={fileInputRef}
                      className="hidden"
                    />
                    <div
                      className="absolute top-2 right-2 bg-white/50 rounded-full p-2 hover:bg-white/75 transition-all"
                      onClick={() => fileInputRef.current?.click()}
                    >
                      <PencilIcon />
                    </div>
                  </>
                )}
              />
            </div>
          )}
          {!previewImage && (
            <Controller
              name="thumbnail_image"
              control={control}
              render={({ field }) => (
                <div>
                  <input
                    type="file"
                    id="thumbnail_image"
                    accept="image/*"
                    onChange={(val) => {
                      field.onChange(val?.target?.files == null ? "" : val?.target?.files[0]?.name);
                      handleFileChange(val);
                    }}
                    ref={fileInputRef}
                    className="hidden"
                  />
                  <Button
                    type="button"
                    className="self-start w-auto h-12 flex items-center"
                    onClick={() => fileInputRef.current?.click()}
                  >
                    Select
                  </Button>
                </div>
              )}
            />
          )}
        </div>
      </div>

      <div className={`flex ${isEmpty ? "flex-col md:flex-row w-3/4 gap-8 items-center" : "flex-col"}`}>
        {trainingSet.files && trainingSet.files.length > 0 ? (
          <ImageDisplay
            browse={() => console.log("browse")}
            title="Training Data"
            route={vault?.training_set_id ? `/training-set/${vault.training_set_id}` : `/training-set/add/${vault?.id}`}
            images={trainingSet.files}
            forMain
          />
        ) : (
          <div>
            <div className="flex flex-row justify-between items-center pb-6">
              <h2 className="text-white text-xl">Training Data</h2>
            </div>
            <div className="text-white bg-gray-900 h-[347px] w-[280px] rounded-[20px] flex flex-col gap-3 justify-center items-center">
              <p>Add training data</p>
              <Button
                className="h-12 w-12 flex-shrink-0 border-none"
                onClick={() => {
                  setShowThirdButton(true);
                  navigate(
                    vault?.training_set_id ? `/training-set/${vault.training_set_id}` : `/training-set/add/${vault?.id}`
                  );
                }}
              >
                <div>
                  <Plus />
                </div>
              </Button>
            </div>
          </div>
        )}
        {vault && vault.entity_type === "USER_PRODUCT" && (
          <div className="py-10">
            {smartAssets && smartAssets.length > 0 ? (
              <ImageDisplay
                browse={() => console.log("browse")}
                title="Smart Assets"
                route={`/smart-asset/${vault?.entity_id}`}
                images={smartAssets}
                forMain
              />
            ) : (
              <div>
                <div className="flex flex-row justify-between items-center pb-6">
                  <h2 className="text-white text-xl">Smart Assets</h2>
                </div>
                <div className="text-white bg-gray-900 h-[347px] w-[280px] rounded-[20px] flex flex-col gap-3 justify-center items-center">
                  <p>Add smart assets</p>
                  <Button className="h-12 w-12 flex-shrink-0 border-none" href={`/smart-asset/${vault?.entity_id}`}>
                    <div>
                      <Plus />
                    </div>
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <ActionFooter
        primaryAction={{
          label: "Save",
          onClick: handleSubmit(onSubmit),
          loading: saving || uploading,
          loadingText: saving ? "Saving..." : "Uploading Image",
          disabled: saving || uploading,
        }}
        showThirdButton={
          showThirdButton
            ? {
                label: "Start training",
                onClick: () => setModalType("confirm"),
                disabled: status === "TRAINING",
              }
            : undefined
        }
        onCancel={() => navigate(-1)}
      />
    </div>
  );
};

export default VaultPage;
