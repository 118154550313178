import React, { useCallback, useState, useEffect } from "react";
import Panel from "./panel";
import { Button } from "../../../../common/components/ui/button";
import { useUserProductAPI } from "../../../../api/user_product_api";
import VaultDropdown from "../vault_dropdown";
import { GenerativeModel, PanelId } from "../../models/image_generator";
import { File } from "../../../../model/file";
import ImageSlider from "../image_slider";
import { PANELS } from "../panelConfig";
import {
    PinturaImageState,
} from "@pqina/pintura";
import { generateSmartAssetMask } from "../../pintura/generate-mask-inpaint";

const title = PANELS.find((panel) => panel.id === PanelId.DragAndDrop)?.description || "Drag and Drop";

const DragAndDropPanel = ({
    modelList,
    generateInpaint,
    isSmartAsset,
    setIsSmartAsset,
    expanded,
    handleToggleExpand,
    pinturaImageState,
    closePanel,
    helperText,
    naturalWidth,
    naturalHeight,
}: {
    modelList: GenerativeModel[],
    generateInpaint: (prompt: string, modelWeights: { id: string; weight: number; }[], mask: string | null, inpaintFileId?: string | null) => void,
    isSmartAsset: boolean,
    setIsSmartAsset: (isSmartAsset: boolean) => void,
    closePanel?: () => void,
    expanded: boolean | undefined,
    handleToggleExpand: () => void,
    helperText?: string;
    pinturaImageState?: PinturaImageState;
    naturalWidth: number;
    naturalHeight: number;
}) => {
    const [selectedModel, setSelectedModel] = useState<GenerativeModel | null>(null); ([]);
    const [inpaintPrompt, setInpaintPrompt] = useState<string>("");
    const [inpaintFiles, setInpaintFiles] = useState<File[]>([]);
    const [selectedInpaintFile, setSelectedInpaintFile] = useState<File | null>(null);
    const { fetchFilesByUsage } = useUserProductAPI();

    const startDrag = (file: File) => {
        setSelectedInpaintFile(file);
    };

    const handleSelectModel = (model: any) => {
        setSelectedModel(model);
        const regex = /#\w+/g;
        setInpaintPrompt((prevPrompt) => {
            const promptWithoutOldTag = prevPrompt.replace(regex, "").trim();

            const newGenerativeTag = `#${model.generative_tag}`;
            const updatedPrompt = `${newGenerativeTag} ${promptWithoutOldTag}`.trim();

            return updatedPrompt;
        });
    };

    useEffect(() => {
        if (inpaintFiles && inpaintFiles.length > 0) {
            setIsSmartAsset(true);
        } else {
            setIsSmartAsset(false);
        }
    }, [inpaintFiles]);

    useEffect(() => {
        if (selectedModel) {
            if (selectedModel.entity_type === "USER_PRODUCT") {
                fetchFilesByUsage({ id: selectedModel.entity_id, usage: "inpaint_asset" }).then((files) => {
                    setInpaintFiles(files);
                    setSelectedInpaintFile(null);
                });
            }
        }
    }, [selectedModel]);

    const selectInpaintFile = (file: File) => {
        setSelectedInpaintFile(file);
    };

    const triggerGenerateInpaint = useCallback(() => {
        if (pinturaImageState) {
            const modelWeights = [];
            if (selectedModel?.entity_type === "LICENSABLE_PROPERTY") {
                modelWeights.push({ id: selectedModel.id, weight: 0.8 });
            }
            if (selectedModel?.entity_type === "USER_PRODUCT") {
                modelWeights.push({ id: selectedModel.id, weight: (modelWeights.length === 0 ? 0.8 : 0.2) });
            }
            const mask = generateSmartAssetMask(pinturaImageState, naturalWidth, naturalHeight);

            if (mask) {
                generateInpaint(
                    inpaintPrompt,
                    modelWeights,
                    mask,
                    selectedInpaintFile?.id || null
                );
            }
        }
    }, [selectedModel, inpaintPrompt, pinturaImageState]);



    return (
        <Panel toggleExpanded={handleToggleExpand} helperText={helperText} isExpanded={expanded} title={title} closePanel={closePanel}>
            <div className="space-y-6">
                <div>
                    <p className="">Vault</p>
                    <VaultDropdown modelData={modelList} selectedModelId={selectedModel?.id} onSelect={handleSelectModel} />
                </div>
                {isSmartAsset ?
                    <div className="flex flex-col gap-3">
                        <div className="flex gap-2">
                            <p>Your Assets</p>
                        </div>
                        <ImageSlider files={inpaintFiles} selectedFile={selectedInpaintFile} setSelectedFile={selectInpaintFile} handleDragStart={startDrag} />
                    </div> : <div>
                        <p>No assets associated with this vault</p>
                    </div>

                }
                {isSmartAsset && <Button onClick={triggerGenerateInpaint} className="w-full border-none" variant="primary">Generate image</Button>}
            </div>

        </Panel>
    );
};

export default DragAndDropPanel;