import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import "./image_details.css";
import { Button } from "../../../common/components/ui/button";
import { CircleX } from "../../../common/icons/icons";
import IpVaultModal from "./modal-components/IP_vault_modal";
import { useGenerativeAPI } from "../../../api/generative_ai_api";
import { GenerativeModel } from "../models/image_generator";
import { MIN_HEIGHT_IMAGE_PROMPT, Textarea } from "../../../common/components/ui/textarea";
import Toggle from "../../../common/components/toggle";
import AspectRatioCardGroup from "../../../common/components/radio-card-group";
import { aspectRatioOptions } from "../models/constants";
import RewriteButton from "../../../common/components/rewrite_button";

const ImageDetails = ({
  form,
  errors,
  selectedVault,
  handleVaultSelect,
  modelList,
}: {
  form: any;
  errors: any;
  modelList: GenerativeModel[];
  selectedVault: GenerativeModel | null;
  handleVaultSelect: (vault: GenerativeModel | null) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isIpVaultOpen, setIsIpVaultOpen] = useState(false);
  const [fastGeneration, setFastGeneration] = useState(false);
  const { enhancePrompt } = useGenerativeAPI();
  const { setValue } = useFormContext();

  useEffect(() => {
    fastGeneration ? setValue("quality", "low") : setValue("quality", "high");
  }, [fastGeneration]);

  const enhanceTextPrompt = async () => {
    setIsLoading(true);
    const models = [];
    if (selectedVault) {
      models.push(selectedVault.id);
    }
    const response = await enhancePrompt(form.getValues("prompt"), models);
    if (response && response.enhanced) {
      setValue("prompt", response.enhanced);
    }
    setIsLoading(false);
  };

  const handleAspectRatioChange = (newAspectRatio: string) => {
    setValue("aspect_ratio", newAspectRatio);
  };

  const handleToggleSpeed = () => {
    setFastGeneration(!fastGeneration);
  };

  return (
    <div className="pt-5 space-y-5">
      <div className="flex flex-col space-y-5 mb-8">
        <Button
          onClick={() => setIsIpVaultOpen(true)}
          variant="primary-negative-invert"
          type="button"
          className="self-start text-base font-semibold"
        >
          {selectedVault ? "1 vault selected" : "Select vault"}
        </Button>
        <IpVaultModal
          isIpModalOpen={isIpVaultOpen}
          setIsIpModalOpen={setIsIpVaultOpen}
          selectedVault={selectedVault}
          modelList={modelList}
          confirmIpSelect={handleVaultSelect}
        />
        {selectedVault && (
          <div className="bg-gray-100 text-black w-[284px] p-4 rounded-xl flex flex-row gap-3 items-center justify-between">
            <div className="flex flex-row items-center gap-3">
              <img className="object-cover h-[70px] w-[70px] rounded-[20px]" src={selectedVault.thumbnail_image} />
              <div className="flex flex-col">
                <p className="uppercase text-sm font-semibold">
                  {selectedVault.entity_type === "USER_PRODUCT"
                    ? "Product"
                    : selectedVault.entity_type === "BRAND"
                    ? "Brand"
                    : selectedVault.entity_type === "LICENSABLE_PROPERTY"
                    ? "Talent"
                    : "Vault"}
                </p>
                <p className="">{selectedVault.name}</p>
              </div>
            </div>
            <div onClick={() => handleVaultSelect(null)}>
              <CircleX />
            </div>
          </div>
        )}
      </div>

      <div className="flex w-full gap-14 ">
        <div className="flex flex-col gap-12 w-full">
          <AspectRatioCardGroup
            options={aspectRatioOptions}
            selected={form.watch("aspect_ratio") ?? "SQUARE"}
            onSelect={handleAspectRatioChange}
          />
          <div>
            <div className="relative">
              <p>Description</p>
              <Textarea
                variant="image_prompt"
                className="mt-5"
                autoSizeMinHeight={MIN_HEIGHT_IMAGE_PROMPT}
                insetControlHeight={48}
                value={form.watch("prompt")}
                onChange={(e) => {
                  setValue("prompt", e.target.value);
                }}
              />
              <div className="absolute flex align-center justify-between bottom-1 px-2 pb-2 left-0 w-full z-10">
                <RewriteButton onClick={enhanceTextPrompt} isLoading={isLoading} />
                <div className="flex gap-4">
                  <Toggle
                    darkMode
                    checked={fastGeneration}
                    labelLeft="Fast"
                    labelRight="Quality"
                    onToggle={handleToggleSpeed}
                  />
                  <Button variant="primary" type="submit" className="hidden sm:block h-[48px]">
                    Generate image
                  </Button>
                </div>
              </div>
            </div>
            <Button variant="primary" type="submit" className="mt-4 mb-8 visible w-full sm:hidden h-[48px]">
              Generate image
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageDetails;
