import React, { useMemo } from "react";
import { Button } from "../../common/components/ui/button";
import { GenerativeModel } from "../image-generator/models/image_generator";
import { useGenerativeModelAPI } from "../../api/generative_model_api";
import VaultCard from "./components/vault-card";
import { useNavigate } from "react-router-dom";

const VaultIndex: React.FC = () => {
  const [myVaults, setMyVaults] = React.useState<GenerativeModel[]>([]);
  const [searchText, setSearchText] = React.useState<string>("");
  const [sortBy] = React.useState<"name" | "created_at">("created_at");
  const generativeModelAPI = useGenerativeModelAPI();
  const navigate = useNavigate();

  React.useEffect(() => {
    // sort always seems to be name asc on server
    generativeModelAPI.fetchMyModels(sortBy, "desc").then((vaults) => {
      setMyVaults(vaults.sort((a, b) => (b.created_at || "").localeCompare(a.created_at || "")));
    });
  }, [sortBy]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const filteredVaults = useMemo(() => {
    return myVaults.filter((vault) => {
      return searchText === "" || vault.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [myVaults, searchText]);

  return (
    <div className="page_content px-[60px] py-[20px] pb-[40px]">
      <div className="flex justify-between items-center">
        <h1 className="text-5xl text-white">Vaults</h1>
        <Button
          variant="primary"
          size="sm"
          className="border-none"
          label="Create Vault"
          onClick={() => navigate("/vault/create")}
        >
          Add new vault
        </Button>
      </div>
      <div className="flex justify-between items-center mt-4">
        <input
          className={"wizard-text-input focus:border-b-brand-yellow text-white"}
          type="text"
          placeholder="Search by keyword"
          aria-label="Search by keyword"
          value={searchText}
          onChange={handleSearchChange}
        />
      </div>
      <div className="flex flex-wrap mt-4 gap-x-6 gap-y-10">
        {filteredVaults.map((vault) => (
          <VaultCard key={`${vault.id}-${vault.status}`} model={vault} onClick={() => navigate("/vault/" + vault.id)} />
        ))}
      </div>
    </div>
  );
};
export default VaultIndex;
