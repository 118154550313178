import React, { useState, useEffect } from "react";
import Panel from "./panel";
import { useGenerativeAPI } from "../../../../api/generative_ai_api";
import { GenerativeModel, GenerateMediaResult, GenerativeModelWeight } from "../../models/image_generator";
import VaultDropdown from "../vault_dropdown";
import { Button } from "../../../../common/components/ui/button";
import { MIN_HEIGHT_IMAGE_PROMPT, Textarea } from "../../../../common/components/ui/textarea";
import Toggle from "../../../../common/components/toggle";
import RewriteButton from "../../../../common/components/rewrite_button";

const DescriptionPanel = ({ generativeData, modelList, expanded, helperText, fastGeneration, setFastGeneration, updatePrompt, handleToggleExpand, closePanel }: { expanded: boolean | undefined, generativeData?: GenerateMediaResult | null, modelList: GenerativeModel[], helperText?: string, fastGeneration: boolean, setFastGeneration: (fastGen: boolean) => void, updatePrompt: (newPrompt: string, modelWeights: GenerativeModelWeight[]) => void, handleToggleExpand: () => void, closePanel: () => void }) => {
    const [selectedModel, setSelectedModel] = useState<GenerativeModel | null>(null);
    const [prompt, setPrompt] = useState<string>(generativeData?.prompt || "");
    const [isLoading, setIsLoading] = useState(false);

    const { enhancePrompt } = useGenerativeAPI();

    useEffect(() => {
        const tagMatch = prompt.match(/#(\w+)/);
        if (!tagMatch) {
            setSelectedModel(null);
        }
        if (tagMatch) {
            const generativeTag = tagMatch[1];
            const matchingModel = modelList.find(
                model => model.generative_tag === generativeTag
            );

            if (matchingModel) {
                setSelectedModel(matchingModel);
            }
        }
    }, [prompt, modelList]);

    const handleSelectModel = (model: any) => {
        setSelectedModel(model);
        const regex = /#\w+/g;
        setPrompt((prevPrompt) => {
            const promptWithoutOldTag = prevPrompt.replace(regex, "").trim();

            const newGenerativeTag = `#${model.generative_tag}`;
            const updatedPrompt = `${newGenerativeTag} ${promptWithoutOldTag}`.trim();

            return updatedPrompt;
        });
    }

    const enhanceTextPrompt = async () => {
        setIsLoading(true);
        const response = await enhancePrompt(prompt, selectedModel ? [selectedModel.id] : []);
        if (response && response.enhanced) {
            setPrompt(response.enhanced);
        }
        setIsLoading(false);
    }

    const handleSave = () => {
        const modelWeights = [];
        if (selectedModel) {
            if (selectedModel.model_type === "licensable_property") {
                modelWeights.push({ id: selectedModel.id, weight: 0.8 });
            }
            if (selectedModel.model_type === "user_product") {
                // Lower the weight of the product model if there is a talent model
                modelWeights.push({ id: selectedModel.id, weight: (modelWeights.length === 0 ? 0.8 : 0.2) });
            }
        }
        updatePrompt(prompt, modelWeights)
    };

    return (
        <Panel isExpanded={expanded} toggleExpanded={handleToggleExpand} helperText={helperText} title="Description" closePanel={closePanel}>
            <div className="h-full space-y-6">
                <div className="relative">
                    <Textarea
                        variant="image_prompt"
                        className="bg-gray-700"
                        autoSizeMinHeight={MIN_HEIGHT_IMAGE_PROMPT}
                        insetControlHeight={48}
                        value={prompt} onChange={(e) => setPrompt(e.target.value)}
                    />
                    <div className="absolute bottom-5 right-5 z-10">
                        <RewriteButton onClick={enhanceTextPrompt} isLoading={isLoading} />
                    </div>
                </div>
                <div>
                    <p className="font-semibold">Vault</p>
                    <VaultDropdown modelData={modelList} selectedModelId={selectedModel?.id} onSelect={handleSelectModel} />
                </div>
                <Button onClick={handleSave} className="w-full border-none" variant="primary">Generate</Button>
                <Toggle darkMode checked={fastGeneration} labelLeft="Fast" labelRight="Quality" onToggle={() => setFastGeneration(!fastGeneration)} />
            </div>
        </Panel>
    )
}

export default DescriptionPanel;