import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Button } from "../../common/components/ui/button";
import { useImageBoardAPI } from "../../api/image_board_api";
import { BoardDTO } from "./models/image_generator";
import ImageBoard from "./my_generated_images";
import { Plus } from "../../common/icons/icons";
import BoardGrid from "./components/board_grid";
import BoardSettingsDialog from "./components/modal-components/board_settings_dialog";
import { useToast } from "../../common/components/ui/use_toast";

const ImageGallery = () => {
  const navigate = useNavigate();
  const location = useLocation(); // <-- fixes browser's back button
  const { boardId: boardIdParam } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [boards, setBoards] = useState<BoardDTO[]>([]);
  const [favoritedBoardIds, setFavoritedBoardIds] = useState<string[]>([]);
  const [selectedBoard, setSelectedBoard] = useState<BoardDTO | null>();
  const { toast } = useToast();

  const {
    getImageBoards,
    removeImageFromBoard,
    deleteBoard,
    moveImageToDifferentBoard,
    addOrRemoveFavorite,
    updateBoard,
  } = useImageBoardAPI();

  // Fetch boards only once when component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const myBoards = await getImageBoards();
        const favorites = myBoards.find((board) => board.is_favorite);
        const favoriteIds = favorites?.generative_outputs?.map((item) => item.generative_output_id) || [];
        setFavoritedBoardIds(favoriteIds);

        myBoards.sort((a, b) => {
          if (a.is_favorite && !b.is_favorite) return -1;
          if (!a.is_favorite && b.is_favorite) return 1;
          if (a.is_default && !b.is_default) return -1;
          if (!a.is_default && b.is_default) return 1;
          return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        });

        setBoards(myBoards);
      } catch (error) {
        console.error("Error fetching generative output", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this runs once on mount

  // Handle board selection based on URL changes
  useEffect(() => {
    if (boardIdParam && boards.length > 0) {
      const board = boards.find((b) => b.id === boardIdParam);
      if (board) {
        setSelectedBoard(board);
      }
    } else {
      setSelectedBoard(undefined);
    }
  }, [location.pathname, boards]); // Use location.pathname instead of boardIdParam

  const handleSelectBoard = (board: BoardDTO) => {
    navigate(`/boards/${board.id}`, { replace: false });
  };

  const handleBack = () => {
    navigate("/boards", { replace: false });
  };


  const removeGenOutputFromBoard = async (boardId: string, generative_output_id: string) => {
    if (!boardId || !generative_output_id) {
      showToast("Error removing image to board");
      return;
    }

    const response = await removeImageFromBoard(boardId, generative_output_id);

    setBoards((prevBoards) =>
      prevBoards.map((board) => {
        if (board.id === boardId) {
          return {
            ...board,
            generative_outputs: board.generative_outputs?.filter(
              (output) => output.generative_output_id !== generative_output_id
            ),
          };
        }
        return board;
      })
    );
    setSelectedBoard(response);
  };

  const moveImageToNewBoard = async (
    destinationBoardId: string,
    generative_output_id: string,
    sourceBoardId: string
  ) => {
    if (!destinationBoardId || !generative_output_id || !sourceBoardId) {
      showToast("Error moving image to board");
      return;
    }

    const response = await moveImageToDifferentBoard(destinationBoardId, generative_output_id, sourceBoardId);
    showToast("Image moved to new board");

    setBoards((prevBoards) =>
      prevBoards.map((board) =>
        board.id === destinationBoardId
          ? response
          : board.id === sourceBoardId
          ? {
              ...board,
              generative_outputs: board?.generative_outputs?.filter(
                (output) => output.generative_output_id !== generative_output_id
              ),
            }
          : board
      )
    );

    if (selectedBoard?.id === destinationBoardId) {
      setSelectedBoard(response);
    } else if (selectedBoard?.id === sourceBoardId) {
      setSelectedBoard((prevSelectedBoard) =>
        prevSelectedBoard
          ? {
              ...prevSelectedBoard,
              generative_outputs:
                prevSelectedBoard.generative_outputs?.filter(
                  (output) => output.generative_output_id !== generative_output_id
                ) ?? [],
            }
          : null
      );
    }
  };

  const favoriteImage = async (generative_output_id: string) => {
    const updatedBoard = await addOrRemoveFavorite(generative_output_id);
    setFavoritedBoardIds((prevIds) =>
      prevIds.includes(generative_output_id)
        ? prevIds.filter((id) => id !== generative_output_id)
        : [...prevIds, generative_output_id]
    );

    setBoards((prevBoards) => prevBoards.map((board) => (board.is_favorite ? updatedBoard : board)));

    if (selectedBoard?.is_favorite) {
      setSelectedBoard(updatedBoard);
    }
  };

  const deleteImageBoard = async (boardId: string) => {
    deleteBoard(boardId);
    setBoards(boards.filter((board) => board.id !== boardId));
    setSelectedBoard(null);
    showToast("Board deleted");
  };

  const updateImageBoard = async (boardId: string, updatedFields: Partial<BoardDTO>) => {
    const existingBoard = boards.find((board) => board.id === boardId);
    if (!existingBoard) return;

    const updatedBoard: BoardDTO = {
      ...existingBoard,
      ...updatedFields,
      updated_at: new Date().toISOString(),
    };

    try {
      const response = await updateBoard(boardId, updatedBoard);
      setBoards((prevBoards) => prevBoards.map((b) => (b.id === boardId ? response : b)));
      setSelectedBoard(response);
    } catch (error) {
      console.error("Error updating board", error);
    }
  };

  const showToast = (message: string) => {
    toast({
      title: message,
      variant: "default"
    });
  };

  return (
    <div>
      {selectedBoard ? (
        <ImageBoard
          addToFavorite={favoriteImage}
          removeFromBoard={removeGenOutputFromBoard}
          moveImageToNewBoard={moveImageToNewBoard}
          favoritedIds={favoritedBoardIds}
          handleBack={handleBack}
          updateBoard={updateImageBoard}
          handleDeleteBoard={deleteImageBoard}
          board={selectedBoard}
          boardList={boards}
        />
      ) : (
        <div>
          <BoardSettingsDialog
            isOpen={openModal}
            onOpenChange={setOpenModal}
            onClose={(board: BoardDTO | undefined) => {
              if (board) {
                setBoards((prevBoards) => {
                  const updatedBoards = [...prevBoards, board];
                  updatedBoards.sort((a, b) => {
                    if (a.is_favorite && !b.is_favorite) return -1;
                    if (!a.is_favorite && b.is_favorite) return 1;
                    if (a.is_default && !b.is_default) return -1;
                    if (!a.is_default && b.is_default) return 1;
                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                  });
                  return updatedBoards;
                });
                showToast(`${board.name} created!`);
              }
              setOpenModal(false);
            }}
          />
          <div className="text-white px-8 pt-28 pb-24">
            <h1 className="text-5xl pb-9">Boards</h1>
            <div className="flex flex-row justify-center md:justify-start flex-wrap gap-10">
              <div className="bg-gray-900 h-[347px] w-[280px] rounded-[20px] flex flex-col gap-3 justify-center items-center">
                <p>Create board</p>
                <Button
                  className="h-12 w-12 flex-shrink-0 border-none"
                  onClick={() => setOpenModal(true)}
                  variant="primary"
                >
                  <div>
                    <Plus />
                  </div>
                </Button>
              </div>
              {boards.map((board) => (
                <div key={board.id}>
                  <BoardGrid board={board} handleBoardSelect={handleSelectBoard} />
                  {board.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
