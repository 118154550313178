import React, { useRef, useState } from "react";
import { Button } from "../../common/components/ui/button";
import { useFileAPI } from "../../api/file_api";
import { useGenerativeAPI } from "../../api/generative_ai_api";
import { useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../common/components/ui/progress_bar";

const UploadImage = () => {
  const { uploadFile } = useFileAPI();
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { profile } = useAppSelector((state: any) => state.user);
  const { initStarterImage } = useGenerativeAPI();
  const navigate = useNavigate();

  const browseForStarterImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = (files: File[]) => {
    if (files.length > 0) {
      setUploading(true);
      uploadFile(
        {
          files: files,
          is_public: false,
          generate_thumbnail: false,
          object_id: profile.id,
          object_type: "user",
          usage: "starter_image",
        },
        (progressEvent) => {
          setUploadProgress((progressEvent.progress || 0) * 100);
        }
      ).then((response) => {
        setUploading(false);
        if (response.length > 0 && response[0].id) {
          initStarterImage({ file_id: response[0].id })
            .then((response) => {
              navigate("/image/editor/" + response.id);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });
    }
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files);
    if (files.length > 0) {
      handleFileUpload(files);
    }
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    handleFileUpload(files);
  };

  return (
    <div className="flex flex-col px-8 pt-28 pb-24 h-screen text-white">
      <h1 className="text-5xl pb-9">Import</h1>
      <div className="flex justify-center w-full">
        <div
          id="dropzone"
          className={`bg-gray-900 rounded-[20px] gap-9 max-w-[100vw] w-[684px] h-[503px] flex flex-col justify-center items-center cursor-pointer transition-colors duration-200 ${
            isDragging ? "bg-gray-800 border-2 border-dashed border-white" : ""
          }`}
          onClick={browseForStarterImage}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <img src={require("../../common/components/ui/upload.png")} alt="Upload" />
          <div className="flex flex-col text-center">
            <div className="min-h-[5em]">
              {uploading && <ProgressBar progress={uploadProgress} />}
              {!uploading && <p className="text-3xl">Click to upload image</p>}
            </div>
            <p>
              Rearrange objects in your scene, blend objects from multiple
              <br /> images, place characters or expand edges
            </p>
          </div>
          <Button onClick={browseForStarterImage} disabled={uploading} className="pointer-events-none">
            {uploading ? "Uploading file ..." : "Upload image"}
          </Button>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
