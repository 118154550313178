import React, { useLayoutEffect } from "react";
import PublicHeader from "../layouts/public_header";
import "./how_it_works.css";
import "./home.css";
import AppFooter from "../layouts/app_footer";
const HowItWorks = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return <main className="page_container home_page">
        <PublicHeader theme="dark" />
        <div className="how_works_section_1 pixel_yellow text-blackish pt-[100px] text-center">
            <div className="text_block">
                <div className="pill_button text-m-sans">For Talent</div>
                <h1 className="text-xxxxl-serif">Your Likeness. Your Rules.<br />Your Success.</h1>
                <p className="text-xl-sans w-[50vw] mx-auto">Double your brand partnerships with zero additional photoshoot days while maintaining complete control of your authentic image in the AI-era.</p>
            </div>
            <div className="pixel_block">
                <div className="pixel_sm pixel_transparent" />
                <div className="pixel_sm pixel_transparent" />
                <div className="pixel_sm pixel_transparent" />
                <div className="pixel_sm pixel_transparent" />
                <div className="pixel_sm pixel_blue" />
                <div className="pixel_sm pixel_transparent" />
                <div className="pixel_sm pixel_red" />
                <div className="pixel_sm pixel_pink" />
                <div className="pixel_sm pixel_transparent" />
                <div className="pixel_sm pixel_blue" />
                <div className="pixel_sm pixel_transparent" />
                <div className="pixel_sm pixel_white" />
                <div className="pixel_sm pixel_white" />
                <div className="pixel_sm pixel_black" />
            </div>
        </div>
        <div className="how_works_section_1_and_half">
            <div className="image_block">
                <div className="background_pixel pixel_dark_gray" />
                <img src={require("./images/for_talent_img_1.5.png")} alt="For talent pricing" width="317" height="688" />
            </div>

            <div className="text_block ml-[32px]">
                <h2 className="text-xxl-serif">It all starts with your vault</h2>
                <p className="mt-7">Create your secure AI content studio in minutes</p>
                <ul className="no_padding">
                    <li>Upload a few photos to create your personalized AI model</li>
                    <li>Simple setup with guided assistance - no technical expertise needed</li>
                    <li>Ready to create with approved brands and collaborators instantly</li>
                </ul>
            </div>
            <div className="bullet_number">1</div>
        </div>
        <div className="how_works_section_2">
            <div className="bullet_number">2</div>
            <div className="text_block ml-[32px]">
                <h2 className="text-xxl-serif">Create and collaborate on your terms</h2>
                <ul>
                    <li>Your vault empowers you to generate your own content effortlessly</li>
                    <li>Collaborate with brands on innovative AI-powered campaigns</li>
                    <li>Expand your creative output without geographical or time constraints</li>
                </ul>
            </div>
            <div className="image_block">
                <div className="background_pixel pixel_pink" />
                <img src={require("./images/for_talent_img_2.2.png")} alt="For talent pricing" width="317" height="688" />
            </div>
        </div>
        <div className="how_works_section_3">
            <div className="image_block">
                <div className="background_pixel pixel_blue" />
                <img src={require("./images/for_talent_img_2.png")} alt="For talent pricing" width="317" height="688" />
            </div>

            <div className="text_block ml-[32px]">
                <h2 className="text-xxl-serif">Maintain full control of your AI likeness</h2>
                <ul>
                    <li>Set usage guidelines to protect your personal brand</li>
                    <li>Approve or reject all collaboration requests with ease</li>
                    <li>Ensure authenticity with our provenance technology</li>
                </ul>
            </div>
            <div className="bullet_number">3</div>
        </div>
        <div className="how_works_section_4">
            <div className="bullet_number">4</div>
            <div className="text_block mr-[32px]">
                <h2 className="text-xxl-serif">Maximize your earning potential</h2>
                <ul>
                    <li>Explore new revenue streams through AI-powered collaborations</li>
                    <li>Set your own pricing for each unique brand proposal</li>
                    <li>Capitalize on opportunities that align with your brand and values</li>
                </ul>
            </div>
            <div className="image_block">
                <img src={require("./images/for_talent_img_1.png")} alt="For talent pricing" width="317" height="688" />
                <div className="background_pixel pixel_red" />

            </div>
        </div>
        <div className="how_works_section_5">
            <div className="text_block text-xxl-sans">We&apos;re building an ecosystem for authenticity to ignite the next creative renaissance</div>
            <div>
                <a href="/register" className="pill_button text-m-sans text-nowrap">Get started</a>
            </div>
        </div>

        <AppFooter />
    </main>
}

export default HowItWorks;