import React from "react";
import Panel from "./panel";
import { Button } from "../../../../common/components/ui/button";
import { VersionHistory } from "../../models/image_generator";
import { format } from "date-fns";
import cn from "clsx";

type HistoryItemData = VersionHistory & { formattedDate: string; formattedTime: string; cliffnoteAction: string; };
const HistoryItem = ({ versionHistory, isSelected }: { versionHistory: HistoryItemData; isSelected: boolean; }) => {
    const navigate = () => {
        window.location.href = `/image/editor/${versionHistory.task_queue_id}`;
    };

    const viewBtn = (
        <Button
            variant="outline"
            // why no href?
            onClick={(e) => {
                navigate();
                e.stopPropagation();
            }}
            className="hidden lg:inline text-white border-gray-300 bg-black text-white"
        >
            View
        </Button>
    );
    return (
        <div className="w-full relative group" onClick={navigate}>
            <div
                className={cn(
                    "flex justify-start items-center py-1 px-1 mb-2 hover:bg-gray-700 rounded-sm",
                    isSelected && "bg-gray-700"
                )}
            >
                <div className="flex flex-row items-center w-full gap-3">
                    <img
                        className="max-w-[72px] min-w-[72px] h-[72px] object-cover rounded-full"
                        src={versionHistory.thumbnail_image ? versionHistory.thumbnail_image : versionHistory.permalink}
                    />
                    <div className="flex w-[50px] flex-col text-sm font-sans text-gray-300 lowercase">
                        <p>{versionHistory.formattedDate}</p>
                        <p>{versionHistory.formattedTime}</p>
                    </div>
                    <div className="flex flex-grow truncate">
                        <p className="text-white font-regular">{versionHistory.cliffnoteAction}</p>
                    </div>
                    <div className="hidden xl:inline-block pr-2">{viewBtn}</div>
                </div>
                {!isSelected && (
                    <div
                        style={{ right: "8px", top: "20px" }}
                        className="absolute group-hover:opacity-100 opacity-0 rounded-full xl:hidden"
                    >
                        {viewBtn}
                    </div>
                )}
            </div>
        </div>
    );
};

const VersionHistoryListPanel = ({
    versionHistory,
    closePanel,
    helperText,
    expanded,
    handleToggleExpand,
    selectedVersionHistory,
}: {
    helperText?: string;
    expanded: boolean | undefined;
    handleToggleExpand: () => void;
    versionHistory: VersionHistory[];
    closePanel?: () => void;
    selectedVersionHistory?: VersionHistory | null,
}) => {

    const compareVersions = (currentItem: VersionHistory) => {
        if (currentItem.action === "upscale") {
            return "Upscale image";
        } else if (currentItem.action === "generate_media") {
            return "Generate variation";
        } else if (currentItem.action === "text2image") {
            return `Edit image: ${currentItem.inpaint_prompt ? currentItem.inpaint_prompt : currentItem.prompt}`;
        } else if (currentItem.action === "outpainting") {
            return "Update aspect ratio";

            // } else if (currentItem.prompt !== prevItem.prompt) {
            //     return `"${currentItem.prompt}"`;
            // } else if (currentItem.aspect_ratio !== prevItem.aspect_ratio) {
            //     return "Update aspect ratio";
            // } else if (previousItem.toString() === currentItem.toString()) {
            //     return "Variation of inital prompt";
        } else {
            return `"${currentItem.prompt}"`;
        }
    };

    const formattedVersionHistory = [...versionHistory]
        .reverse()
        .map((item, index, array) => {
            const previousItem = array[index - 1];
            const isDuplicateTime =
                previousItem &&
                format(new Date(previousItem.created_at), "M/d/yy h:mma") ===
                format(new Date(item.created_at), "M/d/yy h:mma");

            return {
                ...item,
                formattedDate: format(new Date(item.created_at), "M/d/yy"),
                formattedTime: isDuplicateTime ? "" : format(new Date(item.created_at), "h:mma"),
                cliffnoteAction: item.action ? compareVersions(item) : `Generate image: "${item.prompt}"`,
            };
        }) as HistoryItemData[];

    return (
        <Panel
            toggleExpanded={handleToggleExpand}
            helperText={helperText}
            isExpanded={expanded}
            title="History"
            closePanel={closePanel}
        >
            <div className="max-h-[476px] w-full overflow-x-hidden overflow-y-scroll">
                {formattedVersionHistory.map((versionHistory, index) => (
                    <HistoryItem
                        key={versionHistory.generative_output_id}
                        isSelected={selectedVersionHistory?.generative_output_id === versionHistory.generative_output_id}
                        versionHistory={versionHistory}
                    />
                ))}
            </div>
        </Panel>
    );
};

export default VersionHistoryListPanel;
