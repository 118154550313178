import React from "react";
import { GenerativeModel } from "../../image-generator/models/image_generator";
import { LockIcon, UnlockIcon, UserIcon, RequestIcon, RatingIcon } from "../../../common/icons/icons";
import TrainingProgressBar from "./training_progress_bar";

// Function to format the creation date
const getCreatedDate = (createdAt?: Date) => {
  // This is a placeholder - implement actual date logic based on your data
  return "Created " + new Date(createdAt || "").toLocaleDateString();
};

interface VaultCardProps {
  model: GenerativeModel;
  onClick?: () => void;
}

const VaultCard = ({ model, onClick }: VaultCardProps) => {
  return (
    <div
      className="bg-gray-900 rounded-lg p-8 w-[435px] relative overflow-hidden hover:shadow-xl hover:bg-gray-700 hover:cursor-pointer transition duration-300 ease-in-out"
      onClick={onClick}
    >
      {/* Header Section */}
      <div className="flex justify-between items-center mb-1">
        <h3 className="text-white font-sans not-italic font-semibold text-[24px]">{model.name}</h3>
        <div className="flex h-6 items-center gap-1 py-1 rounded-full text-[10px] tracking-[1.2px] font-semibold">
          {model.is_public ? (
            <div className="bg-brand-yellow border border-brand-yellow text-black rounded-full px-3 py-1.5 flex items-center gap-1">
              <UnlockIcon />
              <span>UNLOCKED</span>
            </div>
          ) : (
            <div className="text-white border border-white rounded-full px-3 py-1.5 flex items-center gap-1">
              <LockIcon />
              <span>LOCKED</span>
            </div>
          )}
        </div>
      </div>

      {/* Subheader */}
      <div className="flex justify-between items-center mt-3 mb-5">
        <span className="text-brand-gray-500 text-base">{getCreatedDate(new Date(model.created_at || ""))}</span>
        <span className="text-brand-gray-500 text-base">
          {model.model_type === "OBJECT" ? "Product" : model.model_type === "PERSON" ? "Talent" : "Brand"}
        </span>
      </div>
      {model.status === "TRAINING" ? (
        <div className="h-[268px] rounded-md mb-4">
          <span className="flex flex-row items-center gap-2 text-white text-base font-serif italic uppercase">
            Training
          </span>
          <TrainingProgressBar id={model.id} status={model.status} />
        </div>
      ) : (
        <>
          {/* Image Container */}
          <div
            className="h-[268px] rounded-md mb-4"
            style={{
              backgroundImage: `url(${model.thumbnail_image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </>
      )}

      {/* Stats Footer */}
      <div className="flex justify-between items-center text-sm text-white">
        <div className="flex items-center gap-2">
          <UserIcon />
          <span>{model.stat_access_count} users</span>
        </div>
        <div className="flex items-center gap-2">
          <RequestIcon />
          <span>{model.stat_usage_count} requests</span>
        </div>
        <div className="flex items-center gap-2">
          <RatingIcon />
          <span>{model.quality_score === 0 ? "unrated" : model.quality_score + "%"}</span>
        </div>
      </div>
    </div>
  );
};

export default VaultCard;
