import {useAuth0} from "@auth0/auth0-react";
import {LicensableProperty, ModelEntity, NameValuePair} from "../model/licensable_property";

export function useLicensablePropertyAPI() {
  const { getAccessTokenSilently } = useAuth0();

  const search = async (
    query: string,
    criteria?: NameValuePair[],
    limit = 10,
    offset = 0
  ): Promise<LicensableProperty[]> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
      },
    });

    return new Promise<LicensableProperty[]>((resolve, reject) => {
      const params = new URLSearchParams();
      params.append("q", query);
      params.append("limit", limit.toString());
      params.append("offset", offset.toString());
      if (criteria) {
        params.append("criteria", JSON.stringify(criteria));
      }
      fetch(`${process.env.REACT_APP_API_URL}/lp/search?${params.toString()}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data: LicensableProperty[]) => {
            resolve(data);
          });
        } else {
          reject(new Error("User is not authenticated"));
        }
      });
    });
  };

  const getById = async (id: string): Promise<LicensableProperty> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
      },
    });

    return new Promise<LicensableProperty>((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/lp/${id}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data: LicensableProperty) => {
            resolve(data);
          });
        } else {
          reject(new Error("User is not authenticated"));
        }
      });
    });
  };
  const getPublicById = async (id: string): Promise<LicensableProperty> => {
    return new Promise<LicensableProperty>((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/lp/public/${id}`, {
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data: LicensableProperty) => {
            resolve(data);
          });
        } else {
          reject(new Error("Talent not found"));
        }
      });
    });
  };

  const fetchMyLicensableProperties = async (): Promise<LicensableProperty[]> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
      },
    });

    return new Promise<LicensableProperty[]>((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/lp/my`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data: LicensableProperty[]) => {
            resolve(data);
          });
        } else {
          reject(new Error("User is not authenticated"));
        }
      });
    });
  };
  const fetchMyLicensablePropertiesForSelect = async (): Promise<ModelEntity[]> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
      },
    });

    return new Promise<ModelEntity[]>((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/lp/my-for-select`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data: ModelEntity[]) => {
            resolve(data);
          });
        } else {
          reject(new Error("User is not authenticated"));
        }
      });
    });
  };

  const createLicensableProperty = async (lp: LicensableProperty): Promise<LicensableProperty> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
      },
    });

    return new Promise<LicensableProperty>((resolve, reject) => {
      const url = `${process.env.REACT_APP_API_URL}/lp`;
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(lp),
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data: LicensableProperty) => {
              resolve(data);
            });
          }
        })
        .catch((err) => {
          console.error(err);
          reject(new Error("User is not authenticated"));
        });
    });
  };

  const updateLicensableProperty = async (lp: LicensableProperty): Promise<LicensableProperty> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
      },
    });

    return new Promise<LicensableProperty>((resolve, reject) => {
      const url = `${process.env.REACT_APP_API_URL}/lp/${lp.id}`;
      fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(lp),
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data: LicensableProperty) => {
              resolve(data);
            });
          }
        })
        .catch((err) => {
          console.error(err);
          reject(new Error("User is not authenticated"));
        });
    });
  };

  const deleteLicensableProperty = async (lp: LicensableProperty): Promise<boolean> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
      },
    });

    return new Promise<boolean>((resolve, reject) => {
      const url = `${process.env.REACT_APP_API_URL}/lp/${lp.id}`;
      fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${accessToken}`,
        },
      })
        .then(async (response) => {
          if (response.status === 200) {
            resolve(true);
          }
          resolve(false);
        })
        .catch((err) => {
          console.error(err);
          reject(new Error("Failed to delete LP"));
        });
    });
  };

  return {
    search,
    getById,
    getPublicById,
    fetchMyLicensableProperties,
    createLicensableProperty,
    updateLicensableProperty,
    deleteLicensableProperty,
    fetchMyLicensablePropertiesForSelect,
  };
}
