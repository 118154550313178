import { CheckCircle2 } from "lucide-react";
import React, { HTMLAttributes } from "react";
import { Label } from "./ui/label";
import { RadioGroup, RadioGroupItem } from "./ui/radio_group";

interface CardRadioGroupProps extends HTMLAttributes<HTMLHeadingElement> { // <--- huh, type?
  children?: React.ReactNode;
  options: any[];
  field: any;
  activeOption?: string | unknown;
  theme?: string;
}
const CardRadioGroup = ({ children, ...props }: CardRadioGroupProps) => {
  const { options, field, activeOption, theme } = props;
  const defaultValue = options.find((o) => o.default === true)?.key;
  const item_class = (theme === "lite" ?
    "p-6 w-full flex flex-col items-left flex-grow justify-between rounded-3xl border-0 border-muted bg-popover text-gray-900 bg-transparent border border-gray-50 peer-data-[state=checked]:border-none hover:cursor-pointer  peer-data-[state=checked]:bg-brand-yellow [&:has([data-state=checked])]:bg-brand-yellow peer-data-[state=checked]:text-black" :
    "p-6 w-full flex flex-col items-left flex-grow justify-between rounded-3xl border-0 border-muted bg-popover text-white bg-primary-dark border border-gray-50 peer-data-[state=checked]:border-none hover:cursor-pointer  peer-data-[state=checked]:bg-brand-yellow [&:has([data-state=checked])]:bg-brand-yellow peer-data-[state=checked]:text-black");
  return (
    <RadioGroup
      onValueChange={field.onChange}
      defaultValue={activeOption ?? defaultValue}
      className={`grid md:grid-cols-3 gap-x-5 gap-y-1 ${props.className || "radiogroup"}`}
    >
      {options.map((option, idx) => {
        return (
          <div key={option.value} className="flex radio_group_item">
            <RadioGroupItem
              value={option.value}
              id={`card-select-${idx}-${option.value}`}
              className="peer sr-only"
              checked={option.value === field.value}
            />
            <Label
              htmlFor={`card-select-${idx}-${option.value}`}
              className={item_class}
            >
              <div className="flex gap-8">
                <div className="flex gap-[10px] flex-col flex-grow">
                  <div className="text-m-sans">{option.label}</div>
                  <p className="text-m-sans text-normal">{option.description}</p>
                </div>
                {activeOption === option.key &&
                  <div style={{ width: "40px" }} className={"flex items-center visible"}>
                    <CheckCircle2 />
                  </div>}
                {activeOption !== option.key && <div style={{ width: "40px" }}>&nbsp;</div>}

              </div>
            </Label>
          </div>
        );
      })}
    </RadioGroup>
  );
};
export default CardRadioGroup;
