import { useAuth0 } from "@auth0/auth0-react";
import { LicensableProperty, NameValuePair } from "../model/licensable_property";

type ContentToValidate = {
  url?: string;
  files?: File[];
  licenseId?: string;
};

export type ValidationInfo = {
  status?: "NOT_FOUND" | "LICENSED" | "EXPIRED" | "REJECTED" | "INVALID" | "C2PA_UNKNOWN" | "OAI_PROVENANCE" | undefined;
  license_type?: "commercial" | "personal" | "expanded" | undefined;
  license_to?: string;
  description?: string;
  validity_not_before?: Date;
  validity_not_after?: Date;
  has_c2pa?: boolean;
  has_asset_signature?: boolean;
  c2pa?: any;
  asset_signature?: any;
  hash?: string;
  samples?: string[];
  provenance?: Provenance;
};

export type Provenance = {
  generative_task_id: string;
  prompt?: string;
  user_id?: string;
  ip_vault_id_1?: string | null;
  ip_vault_id_2?: string | null;
  ip_vault_id_3?: string | null;
  ip_vault_name_1?: string;
  ip_vault_name_2?: string;
  ip_vault_name_3?: string;
  created_by: string;
  created_at: string;
}

export function useValidateContentApi() {
  const validateContent = async (content: ContentToValidate): Promise<ValidationInfo> => {
    return new Promise<ValidationInfo>((resolve, reject) => {
      const url = `${process.env.REACT_APP_API_URL}/validate`;
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(content),
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data: any) => {
              resolve(data);
            });
          }
        })
        .catch((err) => {
          console.error(err);
          reject(new Error("Could not validate"));
        });
    });
  };

  return {
    validateContent: validateContent,
  };
}
