import {
  PinturaImageState,
} from "@pqina/pintura";

const toRadius = (strokeWidth: string, width: number) => {
  const isPercent = strokeWidth.includes("%");
  if (isPercent) {
    const percent = Number(strokeWidth.replace("%", ""));
    return percent * width / 100;
  }
  return Number(strokeWidth) / 2;
};

const toAbsoluteX = (strokeWidth: string, width: number) => {
  const isPercent = strokeWidth.includes("%");
  if (isPercent) {
    const percent = Number(strokeWidth.replace("%", ""));
    return percent * width / 100;
  }
  return Number(strokeWidth);
};

const toAbsoluteY = (strokeWidth: string, height: number) => {
  const isPercent = strokeWidth.includes("%");
  if (isPercent) {
    const percent = Number(strokeWidth.replace("%", ""));
    return percent * height / 100;
  }
  return Number(strokeWidth);
};

export const generateMask = (editorState: PinturaImageState, naturalWidth: number, naturalHeight: number) => {
  if (editorState === undefined) return null;

  const { annotation: strokes } = editorState;
  const hasDrawn = !!strokes?.length;
  if (!hasDrawn) return null;

  const maskCanvas = document.createElement("canvas");
  const maskContext = maskCanvas.getContext("2d");

  if (!maskContext) return null;
  maskCanvas.width = naturalWidth || 0;
  maskCanvas.height = naturalHeight || 0;

  maskContext.fillStyle = "black";
  maskContext.fillRect(0, 0, maskCanvas.width, maskCanvas.height);

  strokes.forEach(({ points, strokeWidth }) => {
    const radius = toRadius(`${strokeWidth}`, maskCanvas.width);
    let prev = null as { x: number, y: number; } | null;
    points?.forEach(({ x, y }: any, ix: number) => {
      const absX = toAbsoluteX(x, maskCanvas.width);
      const absY = toAbsoluteY(y, maskCanvas.height);
      if (prev) {
        maskContext.beginPath();
        maskContext.moveTo(prev.x, prev.y);
        maskContext.lineTo(absX, absY);
        maskContext.strokeStyle = "white";
        maskContext.lineWidth = radius * 2;
        maskContext.stroke();
      }
      maskContext.beginPath();
      maskContext.arc(absX, absY, radius, 0, 2 * Math.PI);
      maskContext.fillStyle = "white";
      maskContext.fill();

      prev = { x: absX, y: absY };
    });
  }
  );

  // const tmp = document.getElementById("debug-mask-canvas");
  // if (tmp) {
  //   tmp.innerHTML = "";
  //   tmp.appendChild(maskCanvas);
  // }

  const maskBase64 = maskCanvas.toDataURL("image/png");
  return maskBase64;
};

export const generateSmartAssetMask = (editorState: PinturaImageState, naturalWidth: number, naturalHeight: number) => {
  if (!editorState?.annotation?.length) return null;


  const maskCanvas = document.createElement("canvas");
  const maskContext = maskCanvas.getContext("2d");


  if (!maskContext) return;


  maskCanvas.width = naturalWidth;
  maskCanvas.height = naturalHeight;


  maskContext.fillStyle = "black";
  maskContext.fillRect(0, 0, maskCanvas.width, maskCanvas.height);
  maskContext.fillStyle = "white";


  maskContext.save();

  editorState.annotation.forEach((annotation) => {
    const x = Number(annotation.x);
    const y = Number(annotation.y);
    const width = Number(annotation.width);
    const height = Number(annotation.height);
    const rotation = Number(annotation.rotation);

    maskContext.translate(x + width / 2, y + height / 2);
    maskContext.rotate(rotation);

    maskContext.fillRect(-width / 2, -height / 2, width, height);

    maskContext.restore();
  });


  const maskBase64 = maskCanvas.toDataURL("image/png");

  //   const tmp = document.getElementById("debug-mask-canvas");
  // if (tmp) {
  //   tmp.innerHTML = "";
  //   tmp.appendChild(maskCanvas);
  // }


  return maskBase64;
}
